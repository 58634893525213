import { Box, Input, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { match } from "ts-pattern";
import { EntitySelectItemShimmer } from "./EntitySelect";
import { useDebounce } from "../hooks/useDebounce";
import { loadable } from "../utils/loadable";
import { useSearchCaregiversQuery } from "../../modules/caregiver/hooks/useSearchCaregiversQuery";
import EntityPreview from "./DialerEntityPreview";

interface Props {
  onClickDial: (E164number: string) => void;
}

const DialerCaregiversSearch = (props: Props) => {
  const [caregiverSearchText, setCaregiverSearchText] = useState("");
  const debouncedCaregiverSearchText = useDebounce(caregiverSearchText, 200);
  const caregivers = useSearchCaregiversQuery({ term: debouncedCaregiverSearchText });
  return (
    <>
      <Input
        type="text"
        value={caregiverSearchText}
        onChange={(e) => setCaregiverSearchText(e.currentTarget.value)}
        placeholder={"Search caregivers..."}
      />
      <Box>
        {debouncedCaregiverSearchText &&
          match(loadable.fromQuery(caregivers))
            .with({ type: "Loading" }, () => <EntitySelectItemShimmer />)
            .with({ type: "Resolved" }, ({ value }) =>
              value.length === 0 ? (
                <Text>Not Found</Text>
              ) : (
                value.map((caregiver) => (
                  <EntityPreview
                    key={`${caregiver.id}`}
                    entity={{
                      type: "Caregiver",
                      ...caregiver,
                    }}
                    onClickDial={props.onClickDial}
                  />
                ))
              )
            )
            .with({ type: "Rejected" }, () => <Text>Error loading</Text>)
            .exhaustive()}
      </Box>
    </>
  );
};

export default DialerCaregiversSearch;
