import { FlexProps, Flex } from "@chakra-ui/react";
import React from "react";

const CallTicketColumn = (props: FlexProps) => {
  return (
    <Flex direction="column" width={"full"} gap={2} {...props}>
      {props.children}
    </Flex>
  );
};

export default CallTicketColumn;
