import { SocketEvent, SocketPayloadMap } from "../../types/socket";
import { useWebappChanneDispatchEvent } from "./useWebappChannelEvent";

const useSocketEvent = <$SocketEventKey extends SocketEvent["type"]>(params: {
  key: $SocketEventKey;
  onEvent: (event: SocketPayloadMap[$SocketEventKey]) => void;
}) => {
  useWebappChanneDispatchEvent({
    eventName: "SOCKET_EVENT_RECEIVED",
    onEvent: (value) => {
      if (value.type === params.key) {
        params.onEvent(value.payload as SocketPayloadMap[$SocketEventKey]);
      }
    },
  });
};

export default useSocketEvent;
