import { LocalDate, DateTimeFormatter } from "js-joda";

/* @ngInject */
export function createNoteModalCtrl(
  $scope,
  $rootScope,
  $uibModalInstance,
  DatabaseApi,
  generalUtils,
  SweetAlert,
  noteService,
  toaster,
  $timeout,
  noteConsts,
) {
  $scope.followUpPickerOpen = false;
  $scope.altInputFormats = ['MM/dd/yyyy'];
  $scope.datepickerOptions = {
    minDate: new Date(),
    startingDay: $rootScope.visitSettings.calendarStartDayOfTheWeek
  }
  const allSubjects = DatabaseApi.activeAgencyNoteSubjects();
  $scope.subjects = [];
  if ($scope.viewOnlyMode) {
    $scope.subjects = $scope.subjects.map((subject) => ({
      ...subject,
      disabled: true,
    }));
  }
  $scope.statuses = [
    { id: "NONE", text: "" },
    { id: "VIEWED", text: "Viewed" },
    { id: "IN_PROGRESS", text: "In progress" },
    { id: "DONE", text: "Done" },
  ];

  $scope.caregiversMap = DatabaseApi.caregivers();
  $scope.patientsMap = DatabaseApi.patients();
  $scope.agencyMembers = DatabaseApi.getAgencyMembersArr();
  if ($scope.viewOnlyMode) {
    $scope.agencyMembers = $scope.agencyMembers.map((agencyMember) => ({
      ...agencyMember,
      disabled: true,
    }));
  }
  $scope.filteredAgencyMembers = [];
  $scope.agencyMembersNames = DatabaseApi.getAllAgencyMembersNames();

  const updateFilteredAgencyMembers = () => {
    if ($scope.note.patientId) {
      const patient = $scope.patientsMap[$scope.note.patientId];
      $scope.filteredAgencyMembers = $scope.agencyMembers.filter(agencyMember => {
        return agencyMember.officeIds.includes(patient.currentOfficeId);
      }).sort(generalUtils.sortByDisplayName);
    } else {
      $scope.filteredAgencyMembers = $scope.agencyMembers.filter(agencyMember => {
        return agencyMember.officeIds.findIndex(agencyMemberOfficeId => $rootScope.user.agencyMember.officeIds.includes(agencyMemberOfficeId)) !== -1;
      }).sort(generalUtils.sortByDisplayName);
    }
  };

  if (!$scope.editMode) {
    $scope.note = {
      noteRichText: "",
      subject: '',
      followUp: undefined,
      agencyMembers: [],
      status: "NONE",
      noteType: $scope.isBulk? noteConsts.NoteTypes.BULK : noteConsts.NoteTypes.PROFILE_NOTE,
    };

    $scope.isProfileNoteType = true;

    if ($scope.preSelected) {
      $scope.note = {
        ...$scope.note,
        caregiver: $scope.preSelected.caregiverId ? $scope.caregiversMap[$scope.preSelected.caregiverId] : undefined,
        caregiverId: $scope.preSelected.caregiverId ? $scope.preSelected.caregiverId : undefined,
        patient: $scope.preSelected.patientId ? $scope.patientsMap[$scope.preSelected.patientId] : undefined,
        patientId: $scope.preSelected.patientId ? $scope.preSelected.patientId : undefined
      }
      if ($scope.note.caregiver) {
        $scope.note.caregiver.photo = $scope.note.caregiver.photoUrl || 'admin/images/blank-profile.jpg';
      }
    }

    if($scope.isBulk){
      $scope.note.caregiverIds = [];
      $scope.note.patientIds = [];
    }

    updateFilteredAgencyMembers();
  } else {
    if ($scope.note.followUp !== undefined && $scope.note.followUp !== null) {
      if (typeof $scope.note.followUp === "string") {
        $scope.note.followUp = new Date($scope.note.followUp);
        // Add time zone offset to new date
        const dateWithTimezoneOffset = $scope.note.followUp.getMinutes() + $scope.note.followUp.getTimezoneOffset();
        $scope.note.followUp.setMinutes(dateWithTimezoneOffset);
      }
      // Convert status obj to value
      if (typeof $scope.note.status !== "string") {
        $scope.note.status = $scope.note.status.value;
      }
    }
    if ($scope.note.agencyMembers.length > 0 && $scope.note.agencyMembers[0].id === undefined) {
      $scope.note.agencyMembers = $scope.note.agencyMembers.map(memberId => ({id: memberId}))
    }

    $scope.isProfileNoteType = $scope.note.noteType === noteConsts.NoteTypes.BULK || $scope.note.noteType === noteConsts.NoteTypes.PROFILE_NOTE;
    if($scope.isProfileNoteType){
      $scope.subjectName = $scope.note.subject.name;
    }else{
      $scope.subjectName = noteConsts.NoteTypesTranslations[$scope.note.noteType];
    }

  }
  
  $scope.preSelectedCaregiver = $scope.isBulk? $scope.note.caregiverIds : $scope.note.caregiver;
  $scope.preSelectedPatient = $scope.isBulk? $scope.note.patientIds : $scope.note.patient;
  
  $scope.filterSubjects = ()=>{
    const isPatient = ($scope.note.patientIds && $scope.note.patientIds.length>0) || !!$scope.note.patientId;
    const isCaregiver = ($scope.note.caregiverIds && $scope.note.caregiverIds.length>0) || !!$scope.note.caregiverId;
    $scope.subjects = allSubjects.filter(
      subject=>(subject.showOnCaregiverProfile && isCaregiver) || (subject.showOnPatientProfile && isPatient)
    );
  }
  $scope.filterSubjects();

  $scope.handleCaregiverSelection = (caregiver) => {
    if($scope.isBulk === true){
      $scope.note.caregiverIds = caregiver.map(c=>c.id);
    }else{
      $scope.note.caregiverId = caregiver.id;
    }
    $scope.filterSubjects();
  }

  $scope.handlePatientSelection = (patient) => {
    if($scope.isBulk === true){
      $scope.note.patientIds = patient.map(p=>p.id);
    }else{
      $scope.note.patientId = patient.id;
      updateFilteredAgencyMembers();
    }
    $scope.filterSubjects();
  }
  $scope.handleSubjectSelection = (subject) => {
    $scope.note.subject = subject.id;
  }

  $scope.handleCaregiverDeselect = () => {
    delete $scope.note.caregiverId;
    $scope.filterSubjects();
  }
  $scope.handlePatientDeselect = () => {
    delete $scope.note.patientId;
    updateFilteredAgencyMembers();
    $scope.filterSubjects();
  }
  $scope.handleSubjectDeselect = () => {
    delete $scope.note.subject
  }

  $scope.agencyMembersSelectionSettings = {
    closeOnBlur: true,
    displayProp: "displayName",
    enableSearch: true,
    scrollable: true,
    scrollableHeight: "400px",
    showCheckAll: false,
    styleActive: true,
    smartButtonMaxItems: 3,
    smartButtonTextConverter: function (itemText) {
      return itemText;
    },
  };

  $scope.caregiverSelectionSettings = {
    closeOnBlur: true,
    displayProp: "displayName",
    enableSearch: true,
    scrollable: true,
    scrollableHeight: "400px",
    showCheckAll: false,
    styleActive: true,
    smartButtonMaxItems: 3,
    smartButtonTextConverter: function (itemText) {
      return itemText;
    },
  };

  $scope.subjectSelectionSettings = {
    closeOnBlur: true,
    closeOnSelect: true,
    displayProp: "text",
    enableSearch: true,
    scrollable: true,
    scrollableHeight: "400px",
    selectionLimit: 1,
    showCheckAll: false,
    showUncheckAll: false,
    smartButtonMaxItems: 1,
    smartButtonTextConverter: function (itemText, originalItem) {
      return itemText;
    },
    styleActive: true,
  };

  $scope.editorOptions = {};
  $scope.onReady = function () {
    if (CKEDITOR.instances["note-editor"]) {
      CKEDITOR.instances["note-editor"].destroy();
    }

    const noteEditor = CKEDITOR.replace("note-editor", {
      extraPlugins: "mentions,emoji,basicstyles,undo,link,toolbar",
      removePlugins: "elementspath, showborders",
      resize_enabled: false,
      language: "en",
      contentsCss: [
        "/global-vendors/ckeditor4/ckeditor.css",
        "https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700",
      ],
      font_names: "Open Sans",
      allowedContent: true,
      toolbar: [
        {
          name: "document",
          items: ["Undo", "Redo"],
        },
        {
          name: "basicstyles",
          items: ["Bold", "Italic", "Strike"],
        },
        {
          name: "links",
          items: ["EmojiPanel", "Link", "Unlink"],
        },
      ],
      height: 150,
      mentions: [
        {
          feed: dataFeed,
          itemTemplate:
            '<li data-id="{id}">' +
            '<img width="25" class="rounded-circle mr-2" src="{avatar}" />' +
            '<span class="name">{name}</span>' +
            "</li>",
          outputTemplate:
            '<span class="mention" data-agency-member-id="{id}">{name}</span><span>&nbsp;</span>',
          minChars: 0,
        },
      ],
    });

    noteEditor.on("change", function () {
      $scope.note.noteRichText = this.getData();
    });
    noteEditor.on("contentDom", function () {
      noteEditor.document.on("keyup", function (event) {
        if (event.data.$.ctrlKey && event.data.$.code === "Enter") {
          try {
            $scope.createNote();
          } catch {
            //Catching so the createNote() will run next time.
            console.err("An error occured while submmiting note using enter");
          }
        }
      });
    });

    // When finished - add custom class
    $timeout(() => {
      CKEDITOR.instances["note-editor"].element.$.classList.add(
        "active-note-editor"
      );
    });
  };

  function dataFeed(opts, callback) {
    const agencyMembers = $scope.agencyMembers;
    const users = [];
    for (const member of agencyMembers) {
      if (member) {
        users.push({
          id: member.id,
          name: member.displayName,
          avatar: member.photoUrl || "admin/images/blank-profile.jpg",
        });
      }
    }

    var matchProperty = "name",
      data = users.filter(function (item) {
        return (
          item[matchProperty].toLowerCase().indexOf(opts.query.toLowerCase()) ==
          0
        );
      });

    data = data.sort(function (a, b) {
      return a[matchProperty].localeCompare(b[matchProperty], undefined, {
        sensitivity: "accent",
      });
    });

    callback(data);
  }

  $scope.createNote = function () {
    if ($scope.viewOnlyMode) {
      return;
    }
    if ($scope.note.noteRichText === "") {
      toaster.pop("error", "Note wasn't created", "Note content is required");
      return;
    }
    if ($scope.isProfileNoteType && !$scope.note.subject) {
      toaster.pop("error", "Note wasn't created", "Please select subject");
      return;
    }
    if (
      ($scope.note.caregiverId === undefined &&
      $scope.note.patientId === undefined )
      &&
        ($scope.isBulk && 
          ($scope.note.caregiverIds.length === 0 &&
          $scope.note.patientIds.length === 0 ))
    ) {
      toaster.pop(
        "error",
        "Note wasn't created",
        "Please select caregiver or patient"
      );
      return;
    }

    const asHTML = document
      .createRange()
      .createContextualFragment($scope.note.noteRichText);
    const mentionedAgencyMemberIds = [];
    for (const mentionElement of asHTML.querySelectorAll("span.mention")) {
      const agencyMemberId = mentionElement.getAttribute(
        "data-agency-member-id"
      );
      mentionedAgencyMemberIds.push(parseInt(agencyMemberId, 10));
    }

    const noteBody = angular.copy($scope.note);
    
    noteBody.agencyMembers
      .map((member) => member.id)
      .forEach((memberId) => {
        if (mentionedAgencyMemberIds.indexOf(memberId) === -1) {
          mentionedAgencyMemberIds.push(memberId);
        }
      });
    if (noteBody.followUp !== undefined && noteBody.followUp !== null) {
      const localDateObj = LocalDate.of(
        noteBody.followUp.getFullYear(),
        noteBody.followUp.getMonth() + 1,
        noteBody.followUp.getDate()
      );
      //$scope.newPatient.dateOfBirth = localDateObj.format(DateTimeFormatter.ofPattern("MM/dd/yyyy"));
      noteBody.followUp = localDateObj.format(
        DateTimeFormatter.ofPattern("yyyy-MM-dd")
      );
    }

    let body = {
      ...noteBody,
      agencyMemberIds: mentionedAgencyMemberIds,
    };

    if ($scope.editMode && body.id !== undefined) {
      if (isNaN(body.subject)) {
        body = {...body, subject: body.subject.id };
      }

      const url = `agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/notes/${body.id}`;
      DatabaseApi.put(url, body)
        .then(function () {
          toaster.pop("success", "Note edited successfully");
          $scope.note.noteRichText = "";
          CKEDITOR.instances["note-editor"].setData("");
          $uibModalInstance.dismiss();
        })
        .catch(function (err) {
          toaster.pop("warning", "something went wrong", "");
        });
    } else {
      const url = `agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/notes`;
      DatabaseApi.post(url, body)
        .then(function () {
          CKEDITOR.instances["note-editor"].setData("");
          $uibModalInstance.dismiss();

          if($scope.$resolve.intakeCallback !== undefined){
            $scope.$resolve.intakeCallback();
          }
        })
        .catch(function (err) {
          toaster.pop("warning", "something went wrong", "");
        });
    }
  };
  
  $scope.handleDeleteNote = () => {
    if ($scope.viewOnlyMode) {
      return;
    }
    if ($scope.note.id === undefined) {
      return;
    }
    SweetAlert.swal({
      title: "Remove note",
      text: "Are you sure you want to remove this note? ",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3077EB",
      confirmButtonText: "Yes, remove",
      closeOnConfirm: true,
      closeOnCancel: true
  }, (isConfirm) => {
      if (isConfirm) {
        noteService.deleteNote($scope.note.id, $rootScope.agencyId, $rootScope.agencyMemberId).then(res => {
            toaster.pop(
            "success",
            "Success",
            "Delete note"
            );
            $scope.closeModal();
        }).catch(err => {
            toaster.pop(
            "error",
            "Something went wrong",
            "Failed to Delete note");
        });
      }
    });
  };

  $scope.handleNewNoteFile = (newFile) => {
    $scope.note.base64File = newFile;
  };

  $scope.closeModal = function () {
    $uibModalInstance.dismiss();
  };
};
