import { LocalDate } from "@js-joda/core";

export function createFilters<$Filters extends Record<string, unknown>>() {
  function createFreeTextFilter<$Name extends keyof $Filters>(params: {
    name: $Name;
    placeholder: string;
    value: string | null;
    onChange: (name: $Name, value: string | undefined) => void;
    /** debounce ms. default 0 */
    debounce?: number;
  }) {
    return {
      name: params.name,
      placeholder: params.placeholder,
      value: params.value,
      debounce: params.debounce ?? 0,
      onChange: (newValue: string | undefined) => params.onChange(params.name, newValue),
    };
  }
  function createMultiSelectFilter<
    $Name extends keyof $Filters,
    $Option extends NonNullable<$Filters[$Name]> extends unknown[]
      ? NonNullable<$Filters[$Name]>[number]
      : never
  >(params: {
    name: $Name;
    label: string;
    options: { label: string; value: $Option }[] | ReadonlyArray<{ label: string; value: $Option }>;
    value: $Option[] | null;
    onChange: (name: $Name, option: $Option[] | undefined) => void;
  }) {
    return {
      name: params.name,
      label: params.label,
      options: params.options,
      value: params.value,
      onChange: (selected: $Option[] | undefined) => params.onChange(params.name, selected),
    };
  }

  function createSelectFilter<
    $Name extends keyof $Filters,
    $Option extends NonNullable<$Filters[$Name]>
  >(params: {
    name: $Name;
    label: string;
    options: { label: string; value: $Option }[] | ReadonlyArray<{ label: string; value: $Option }>;
    value: $Option | null;
    disabled: boolean;
    onChange: (name: $Name, option: $Option | undefined) => void;
  }) {
    return {
      name: params.name,
      label: params.label,
      options: params.options,
      value: params.value,
      disabled: params.disabled,
      onChange: (selected: $Option | undefined) => params.onChange(params.name, selected),
    };
  }

  function createRangeDatePickerFilter<
    $StartDateName extends keyof $Filters,
    $EndDateName extends keyof $Filters
  >(params: {
    label: string;
    startDate: {
      name: $StartDateName;
      value: LocalDate | null;
    };
    endDate: {
      name: $EndDateName;
      value: LocalDate | null;
    };
    onChange: (name: $StartDateName | $EndDateName, value: LocalDate | undefined) => void;
  }) {
    return {
      placeholder: params.label,
      selected: params.endDate.value,
      startDate: params.startDate.value,
      endDate: params.endDate.value,
      onChange: (value: [LocalDate, LocalDate | null] | null, e: any) => {
        const isRedoing =
          value !== null && params.endDate.value !== null && e.target.tagName === "DIV";

        if (value === null) {
          params.onChange(params.startDate.name, undefined);
          params.onChange(params.endDate.name, undefined);
          return;
        }

        if (value[0] !== null) {
          params.onChange(params.startDate.name, value?.[0]);

          if (isRedoing) {
            params.onChange(params.endDate.name, undefined);
            return;
          }
        }

        if (value[1] !== null) {
          params.onChange(params.endDate.name, value?.[1]);
        }
      },
    };
  }

  return {
    createMultiSelectFilter: createMultiSelectFilter,
    createSelectFilter: createSelectFilter,
    createRangeDatePickerFilter: createRangeDatePickerFilter,
    createFreeTextFilter: createFreeTextFilter,
  };
}
