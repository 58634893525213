import { useDisclosure, useToast } from "@chakra-ui/react";
import { Instant } from "@js-joda/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { BodyOf, QueryParamsOf } from "../../../../core/api";
import LoadingPage from "../../../../shared/components/LoadingPage";
import useApi from "../../../../shared/hooks/useApi";
import { useQueryParams } from "../../../../shared/hooks/useQueryParams";
import { queryKeys } from "../../../../shared/query-keys";
import { NoteId, PatientId } from "../../../../shared/schema/schema";
import { formatErrorResponse } from "../../../../shared/utils/format-response-error";
import IntakeDashboardPage from "./IntakeDashboardPage";

export interface EligibilityCheckResult {
  isEligible: boolean | null;
  isEligibleOnOtherPayer: boolean;
  eligibilityPayer: string | null;
  createdAt: Instant;
  message: string | null;
}

const IntakeDashboardRoute = () => {
  const { api } = useApi();
  const createIntakePatientDisclosure = useDisclosure();
  const intakePatientProfileDisclosure = useDisclosure();
  const toast = useToast();
  const queryClient = useQueryClient();
  const [activePatientProfileId, setActivePatientProfileId] = React.useState<PatientId | null>(
    null
  );

  const { mutate: handleCreateNewIntakePatient, reset } = useMutation({
    mutationKey: ["create-new-intake-patient"],
    mutationFn: (body: BodyOf<"post", "./patient_intake">) =>
      api.post("./patient_intake", { body }),
    onSuccess: (IntakePatient) => {
      toast({
        title: "Intake Patient Created Successfully",
        status: "success",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
      createIntakePatientDisclosure.onClose();
      queryClient.invalidateQueries(queryKeys.patientIntake.search(queryParams.params));
      setActivePatientProfileId(IntakePatient.patient.id);
      intakePatientProfileDisclosure.onOpen();
      reset();
    },
    onError: (error) => {
      toast({
        title: "Could not create new patient",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const { mutate: handleEditPatientIntakeProfileMutation } = useMutation({
    mutationKey: ["edit-patient-intake-profile"],
    mutationFn: (body: BodyOf<"put", "./patients/:patientId/intake">) =>
      api.put("./patients/:patientId/intake", {
        body,
        path: {
          patientId: activePatientProfileId!,
        },
      }),
    onSuccess: () => {
      toast({
        title: "Success! patient's details were updated",
        status: "success",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });

      queryClient.invalidateQueries(queryKeys.patientIntake.get(activePatientProfileId!));
    },
    onError: (error) => {
      toast({
        title: `Error in updating the patient's details`,
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const { mutate: handleRunEligibilityCheckMutation } = useMutation({
    mutationKey: ["run-eligibility-check"],
    mutationFn: () =>
      api.post("./patients/:patientId/check_eligibility", {
        path: {
          patientId: activePatientProfileId!,
        },
      }),
    onSuccess: (_response) => {
      toast({
        title: "Successfuly run eligibility check",
        status: "success",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });

      queryClient.invalidateQueries(["get-eligibility-checks"]);
    },
    onError: (error) => {
      toast({
        title: `Error running eligibility check`,
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const queryParams = useQueryParams<QueryParamsOf<"get", "./patient_intake/dashboard">>({
    storageKey: ["patient_intake", "dashboard-table"],
    defaultOptions: {},
  });

  const medicaidStatuses = useQuery({
    queryKey: queryKeys.patientIntake.medicaidStatus(),
    keepPreviousData: true,
    queryFn: () => api.get("./patient_intake/medicaid_status", {}),
  });

  const intakeStatuses = useQuery({
    queryKey: queryKeys.patientIntake.intakeStatus(),
    keepPreviousData: true,
    queryFn: () => api.get("./patient_intake/intake_status", {}),
  });

  const intakePlans = useQuery({
    queryKey: queryKeys.patientIntake.intakePlan(),
    keepPreviousData: true,
    queryFn: () => api.get("./patient_intake/intake_plan", {}),
  });

  const dashboardData = useQuery({
    queryKey: queryKeys.patientIntake.search(queryParams.params),
    keepPreviousData: true,
    queryFn: () =>
      api.get("./patient_intake/dashboard", {
        query: queryParams.params.toJSON(),
      }),
  });

  const intakeProfile = useQuery({
    queryKey: queryKeys.patientIntake.get(activePatientProfileId!),
    enabled: activePatientProfileId !== null,
    queryFn: () =>
      api.get("./patients/:patientId/intake", {
        path: {
          patientId: activePatientProfileId!,
        },
      }),
    onError: (error) => {
      toast({
        title: `Error in fetching intake profile data.`,
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const eligibilityChecks = useQuery({
    queryKey: ["get-eligibility-checks"],
    queryFn: () =>
      api.get("./eligibility_checks", {
        query: {},
      }),
    onError: (error) => {
      toast({
        title: `Error in fetching eligibility checks data.`,
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const markNoteAsDone = useMutation({
    mutationFn: (noteId: NoteId) => {
      return api.put("./notes/:noteId/status", {
        path: { noteId },
        body: { status: "Done" },
      });
    },
    onSuccess: () => {
      toast({
        title: "Successfuly marked note as done",
        status: "success",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });

      queryClient.invalidateQueries(queryKeys.patientIntake.get(activePatientProfileId!));
    },
    onError: (error) => {
      toast({
        title: `Error in marking note as done.`,
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const handleDashboardRowClick = (patientId: PatientId) => {
    setActivePatientProfileId(patientId);
    intakePatientProfileDisclosure.onOpen();
  };

  const handleAddNewNote = async (patientId: PatientId) => {
    await queryClient.invalidateQueries(queryKeys.patientIntake.get(patientId));
    handleDashboardRowClick(patientId);
  };

  const handleCloseIntakeForm = () => {
    queryClient.invalidateQueries(queryKeys.patientIntake.search(queryParams.params));
    intakePatientProfileDisclosure.onClose();
  };

  const handleEditPatientIntakeProfile = (body: BodyOf<"put", "./patients/:patientId/intake">) => {
    if (activePatientProfileId === null) {
      toast({
        title: `Error in fetching intake profile data.`,
        description: "",
        status: "error",
        position: "top-right",
      });
    } else {
      handleEditPatientIntakeProfileMutation(body);
    }
  };

  const handleClickRunEligibilityCheck = (patientId: PatientId) => {
    if (activePatientProfileId === null || activePatientProfileId !== patientId) {
      toast({
        title: `Error in performing patient eligibility check`,
        description: "",
        status: "error",
        position: "top-right",
      });
    } else {
      handleRunEligibilityCheckMutation();
    }
  };

  if (
    dashboardData.isLoading ||
    medicaidStatuses.isLoading ||
    intakeStatuses.isLoading ||
    intakePlans.isLoading
  ) {
    return <LoadingPage />;
  }

  if (
    dashboardData.isError ||
    medicaidStatuses.isError ||
    intakeStatuses.isError ||
    intakePlans.isError
  ) {
    return <div>Error</div>;
  }

  if (!dashboardData.data || !medicaidStatuses.data || !intakeStatuses.data || !intakePlans.data) {
    return <div>Something went wrong</div>;
  }

  return (
    <IntakeDashboardPage
      createIntakePatientDisclosure={createIntakePatientDisclosure}
      intakePatientProfileDisclosure={intakePatientProfileDisclosure}
      intakePatients={dashboardData.data.patients}
      medicaidStatuses={medicaidStatuses.data.medicaidStatuses}
      intakeStatuses={intakeStatuses.data.intakeStatuses}
      intakePlans={intakePlans.data.intakePlans}
      eligibilityCheckResults={eligibilityChecks.data?.records ?? []}
      filters={queryParams}
      intakeProfileData={intakeProfile.data?.patient ?? null}
      onChangeFilter={queryParams.setValue}
      onChangeFilters={queryParams.setValues}
      onCreateNewIntakePatient={handleCreateNewIntakePatient}
      onCloseIntakeProfile={handleCloseIntakeForm}
      onClickDashboardRow={handleDashboardRowClick}
      onEditPatientIntakeProfile={handleEditPatientIntakeProfile}
      onClickEligibilityCheck={handleClickRunEligibilityCheck}
      onClickMarkNoteDone={markNoteAsDone.mutate}
      onAddNewNote={handleAddNewNote}
    />
  );
};

export default IntakeDashboardRoute;
