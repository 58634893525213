import { Input } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React from "react";

type DatePickerInputProps = Record<string, unknown> & {
  placeholder?: string;
};

const DatePickerInput = React.forwardRef(
  (props: DatePickerInputProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    return <PickerInput {...props} ref={ref} placeholder={props.placeholder ?? "Select date"} />;
  }
);

const PickerInput = styled(Input)`
  font-weight: 500;
  border-color: var(--chakra-colors-gray-300);
  color: var(--chakra-colors-gray-600);
`;

export default DatePickerInput;
