import { match } from "ts-pattern";
import { Messages } from "../../../../../../core/api";
import { phoneFormatter } from "../../../../../../shared/utils/phone-formatter";

export function getParticipantDescription(
  callSessionParticipant: Messages["CallSessionParticipantEvent"]["callSessionParticipant"]
): string {
  return match(callSessionParticipant)
    .with(
      { type: "AgencyMember" },
      (participant) =>
        `${participant.firstName} ${participant.lastName} ${
          participant.subscriber ? `(${participant.subscriber})` : ""
        }`
    )
    .with(
      { type: "Patient" },
      { type: "Caregiver" },
      (participant) => `${participant.type}: ${participant.firstName} ${participant.lastName}`
    )
    .with(
      { type: "NotIdentified" },
      (participant) => `${phoneFormatter.formatNationalIfValid(participant.phoneNumber)}`
    )
    .exhaustive();
}
