import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { match } from "ts-pattern";
import { Messages } from "../../../core/api";
import Dot02SIcon from "../../../shared/icons/Dot02SIcon";
import { fmap } from "../../../shared/utils";
import { phoneFormatter } from "../../../shared/utils/phone-formatter";
import CallTicketIndexBadge from "./CallTicketIndexBadge";

type AgencyMemberRole = Messages["CallSessionParticipant.AgencyMember"] & {
  role: "Caller" | "Participant" | "Recipient";
};

type CaregiverRole = Messages["CallSessionParticipant.Caregiver"] & {
  role: "Caller" | "Participant" | "Recipient";
};

type PatientRole = Messages["CallSessionParticipant.Patient"] & {
  role: "Caller" | "Participant" | "Recipient";
};

type NotIdentifiedRole = Messages["CallSessionParticipant.NotIdentified"] & {
  role: "Caller" | "Participant" | "Recipient";
};

const CallTicketParticipantCard = ({
  entity,
  index,
}: {
  entity: Messages["CallSessionDetails"]["sessionParticipantRoles"][number];
  index: number;
}) => {
  return match(entity)
    .with({ type: "AgencyMember" }, (role) => (
      <CallTicketParticipantCard.AgencyMember role={role} index={index} />
    ))
    .with({ type: "Caregiver" }, (role) => (
      <CallTicketParticipantCard.Caregiver role={role} index={index} />
    ))
    .with({ type: "NotIdentified" }, (role) => (
      <CallTicketParticipantCard.NotIdentified role={role} index={index} />
    ))
    .with({ type: "Patient" }, (role) => (
      <CallTicketParticipantCard.Patient role={role} index={index} />
    ))
    .exhaustive();
};

CallTicketParticipantCard.AgencyMember = ({
  role,
  index,
}: {
  role: AgencyMemberRole;
  index: number;
}) => {
  return (
    <Flex direction={"row"} gap={2} alignItems={"center"}>
      <CallTicketIndexBadge>{index}</CallTicketIndexBadge>
      <Flex direction={"column"}>
        <Text fontWeight={"bold"}>
          {role.firstName} {role.lastName} {fmap(role.subscriber, (e) => `(${e})`)}
        </Text>
        <Flex direction={"row"} color={"gray.400"} alignItems={"center"}>
          <Text>Agency Member</Text>
          <Dot02SIcon boxSize={8} />
          <Text>{role.role}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

CallTicketParticipantCard.Caregiver = ({ role, index }: { role: CaregiverRole; index: number }) => {
  return (
    <Flex direction={"row"} gap={2} alignItems={"center"}>
      <CallTicketIndexBadge>{index}</CallTicketIndexBadge>
      <Flex direction={"column"}>
        <Text fontWeight={"bold"}>
          {role.firstName} {role.lastName}
        </Text>
        <Flex direction={"row"} color={"gray.400"} alignItems={"center"}>
          <Text>Caregiver</Text>
          <Dot02SIcon boxSize={8} />
          <Text>{role.role}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

CallTicketParticipantCard.Patient = ({ role, index }: { role: PatientRole; index: number }) => {
  return (
    <Flex direction={"row"} gap={2} alignItems={"center"}>
      <CallTicketIndexBadge>{index}</CallTicketIndexBadge>
      <Flex direction={"column"}>
        <Text fontWeight={"bold"}>
          {role.firstName} {role.lastName}
        </Text>
        <Flex direction={"row"} color={"gray.400"} alignItems={"center"}>
          <Text>Patient</Text>
          <Dot02SIcon boxSize={8} />
          <Text>{role.role}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

CallTicketParticipantCard.NotIdentified = ({
  role,
  index,
}: {
  role: NotIdentifiedRole;
  index: number;
}) => {
  return (
    <Flex direction={"row"} gap={2} alignItems={"center"}>
      <CallTicketIndexBadge>{index}</CallTicketIndexBadge>
      <Flex direction={"column"}>
        <Text fontWeight={"bold"}>{phoneFormatter.formatNationalIfValid(role.phoneNumber)}</Text>
        <Flex direction={"row"} color={"gray.400"} alignItems={"center"}>
          <Text>Unknown Number</Text>
          <Dot02SIcon boxSize={8} />
          <Text>{role.role}</Text>
          <Dot02SIcon boxSize={8} />
          <Text>{phoneFormatter.formatNationalIfValid(role.phoneNumber)}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CallTicketParticipantCard;
