import { PatientId } from "@medflyt/messages/ids";
import { startOutboundCall } from "../call-center.utils";
import { InitiateCallResponse } from "../components/call-center.types";

//! @ngInject
export class CallCenterCallWidgetCtrl implements ng.IComponentController {
  static readonly $name = "callCenterCallWidgetCtrl";

  constructor(
    private patientId: PatientId,
    private phoneNumber: number,
    private callInitiationDetails: InitiateCallResponse
  ) {}

  async $onInit(): Promise<void> {
    startOutboundCall(this.callInitiationDetails.destination, {
      onSessionConnected: () => {
        console.log("Session Connected");
        // TODO: Participant event
      },
      onSessionTerminated: () => {
        console.log("Session Terminated");
        // TODO: Participant event
      },
    });
  }
}
