import { LocalDate } from "@js-joda/core";
import Immutable from "immutable";
import React from "react";

export type QueryParams<$QueryParams> = {
  params: Immutable.Map<keyof $QueryParams, unknown>;
  getValue: <K extends keyof $QueryParams>(key: K) => $QueryParams[K] | undefined;
  getValueOrNull: <K extends keyof $QueryParams>(key: K) => NonNullable<$QueryParams[K]> | null;
  setValue: <K extends keyof $QueryParams>(key: K, value: $QueryParams[K]) => void;
  setValues: (values: Partial<$QueryParams>) => void;
};

export function useQueryParams<$QueryParams extends { [key: string]: unknown }>(
  params?: Partial<{ storageKey: string[]; defaultOptions: $QueryParams }>
): QueryParams<$QueryParams> {
  const [queryParams, setQueryParams] = React.useState<
    Immutable.Map<keyof $QueryParams, $QueryParams[number]>
  >(() => {
    if (params?.storageKey !== undefined) {
      const fromStorage = localStorage.getItem(JSON.stringify(params.storageKey));

      if (fromStorage !== null) {
        const parsed = JSON.parse(fromStorage);

        for (const [key, value] of Object.entries(parsed)) {
          if (typeof value === "string" && /^\d{4}-\d{2}-\d{2}$/.test(value)) {
            parsed[key] = LocalDate.parse(value);
          }
        }

        return Immutable.Map(parsed);
      }
    }
    if (params?.defaultOptions !== undefined) {
      return Immutable.Map(params.defaultOptions as any);
    }

    return Immutable.Map();
  });

  const setValue = <T extends keyof $QueryParams>(key: T, value: $QueryParams[T] | undefined) => {
    setQueryParams((prev) => {
      const newParams = value === undefined ? prev.delete(key) : prev.set(key, value as any);

      if (params?.storageKey !== undefined) {
        localStorage.setItem(
          JSON.stringify(params.storageKey),
          JSON.stringify(newParams.toObject())
        );
      }

      return newParams;
    });
  };

  const setValues = (values: Partial<$QueryParams>) => {
    const newParams = Immutable.Map({
      ...queryParams.toJS(),
      ...values,
    }) as Immutable.Map<keyof $QueryParams, $QueryParams[number]>;

    if (params?.storageKey !== undefined) {
      localStorage.setItem(JSON.stringify(params.storageKey), JSON.stringify(newParams.toObject()));
    }

    setQueryParams(newParams);
  };

  const getValue = <T extends keyof $QueryParams>(key: T) => {
    return queryParams.get(key) as $QueryParams[T] | undefined;
  };

  const getValueOrNull = <T extends keyof $QueryParams>(key: T) => {
    return (queryParams.get(key) ?? null) as NonNullable<$QueryParams[T]> | null;
  };

  return { params: queryParams, setValue, setValues, getValue, getValueOrNull };
}
