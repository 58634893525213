import React from "react";
import { Messages } from "../../../core/api";
import { CommCenterTicketId, NoteSubjectId } from "../../../shared/schema/schema";
import { isChatMessagesTicket, isPhoneCallTicket } from "../utils/communication-utils";
import CallTicket from "./CallTicket";
import ChatMessages from "./ChatMessages";
import { Text } from "@chakra-ui/react";

interface Props {
  ticket: Messages["CommCenterTicket"];
  noteSubjects: { label: string; value: NoteSubjectId }[];
  liveCallTicketIds: CommCenterTicketId[];
  onClickHangup: () => void;
  onSubmitNewMessage: (message: string) => void;
}

const ActiveTicket = ({
  ticket,
  liveCallTicketIds,
  noteSubjects,
  onClickHangup,
  onSubmitNewMessage,
}: Props): JSX.Element => {
  if (isChatMessagesTicket(ticket)) {
    return <ChatMessages activeTicket={ticket} onSubmitNewMessage={onSubmitNewMessage} />;
  }
  if (isPhoneCallTicket(ticket)) {
    return (
      <CallTicket
        liveCallTicketIds={liveCallTicketIds}
        noteSubjects={noteSubjects}
        onClickHangup={onClickHangup}
        activeCallTicket={ticket}
      />
    );
  }
  return <Text>Unhandled Ticket</Text>;
};

export default ActiveTicket;
