import { Duration, LocalDateTime, nativeJs } from "js-joda";
import { isDefined } from "../../../../scripts/utils/generalUtils";
import angular from "angular";

//! @ngInject
class CountdownTimerCtrl implements ng.IComponentController, Bindings {
    // --- Bindings --- //
    timerUntil!: LocalDateTime;
    onTimerEnd?: ()=> void;
    // --- END Bindings --- //

    timer: angular.IPromise<void> | null = null;
    timeLabel: string | null = null;

    constructor(private $timeout: ng.ITimeoutService) {
    }

    $onInit() {
        this.run();
        this.start();
    }


    $onDestroy() {
        this.stop();
    }

    start() {
        this.timer = this.$timeout(()=> this.run(), 1000);
    }

    run() {
        const duration = this.getDuration();
        this.timeLabel = formatCountDown(duration);

        if (!duration.isNegative()) {
            this.start();
        } else if (isDefined(this.onTimerEnd)) {
            this.onTimerEnd();
            this.stop();
        }
    }

    stop() {
        if (this.timer !== null) {
            this.$timeout.cancel(this.timer);
            this.timer = null;
        }
    }

    getDuration() {
        const timerUntil = LocalDateTime.from(nativeJs(new Date(this.timerUntil.toString())));
        return Duration.between(LocalDateTime.now(), timerUntil);
    }
}

const formatTimePart = (value: number) => ("0" + value).slice(-2);

const formatCountDown = (duration: Duration) => {
    if (duration.isNegative()) {
        return "00:00";
    }

    const hours = duration.toHours();
    const minutes = duration.minusHours(hours).toMinutes();

    return [
        formatTimePart(hours),
        formatTimePart(minutes)
    ].join(":");
};

interface Bindings {
    timerUntil: LocalDateTime;
    onTimerEnd?: ()=> void;
}

export const countdownTimerComponent = {
    $name: "countdownTimer",
    templateUrl:
        "admin/modules/shared/components/countdown-timer/countdown-timer.component.html",
    controller: CountdownTimerCtrl,
    controllerAs: "ctrl",
    bindings: {
        timerUntil: "<",
        onTimerEnd: "<",
    }
};
