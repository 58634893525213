import { FormLabelProps, Input, InputProps, ThemingProps } from "@chakra-ui/react";
import { debounce } from "lodash";
import React from "react";

interface Props {
  placeholder: string;
  inputProps?: InputProps;
  labelProps?: FormLabelProps;
  value: string | null;
  onChange: (selected: string | undefined) => void;
  /** debounce ms */
  debounce: number;
  disabled?: boolean;
  size?: ThemingProps["size"];
}

const FreeTextSearch = (props: Props): JSX.Element => {
  const debounceOnChange = debounce(props.onChange, props.debounce);

  return (
    <Input
      width={"sm"}
      type="text"
      {...props.inputProps}
      placeholder={props.placeholder}
      onChange={({ currentTarget: { value } }) => {
        debounceOnChange(value.trim() === "" ? undefined : value);
      }}
    />
  );
};

export default FreeTextSearch;
