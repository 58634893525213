import { Caregiver } from "../../../../scripts/messages/caregiver";
import { CaregiverId, VisitBroadcastId, VisitInstanceId } from "../../../../scripts/messages/ids";
import { VisitInstance } from "../../../../scripts/messages/visit";
import { VisitBroadcastService } from "../../visitBroadcast.service";
import { VisitBroadcastPartialAssingParams } from "../../visitBroadcast.types";
import "./caregivers-visits-requests-table.component.scss";
import {
    CaregiverInShift,
    CaregiverVisitRequest,
    CaregiverVisitsRequestsTableBindings
} from "./caregivers-visits-requests-table.types";
import {
  arePatternShiftsEqual,
  decouplePatternShiftIdToShiftDetails,
  getPatternVisitShifts,
  getShiftId, getSinglesVisitShifts, mapCaregiversIntoCaregiversInShifts, mapVisitInstancesTimes
} from "../visit-table-common.utils";
import { Shift, ShiftId, VisitAssignmentRequest } from "../visit-table-common.types";


//! @ngInject
class caregiversVisitsRequestsTableCtrl
  implements ng.IComponentController, CaregiverVisitsRequestsTableBindings
{
  caregiverVisitRequests!: CaregiverVisitRequest[];
  visitAssignmentRequests!: VisitAssignmentRequest[];
  visitBroadcastType!: "PATTERN" | "SINGLES";
  visitInstances!: VisitInstance[];
  visitBroadcastId!: VisitBroadcastId;
  startFlyToCaregiver!: (caregiverId: CaregiverId) => void;
  stopFlyToCaregiver!: () => void;
  closeModal!: () => void;
  updateEngagements!: () => void;

  shifts: Shift[];
  selectedCaregiversInShifts: Map<ShiftId, CaregiverId>;
  caregivers: CaregiverInShift[];

  constructor(
    private $filter: ng.IFilterService,
    private $rootScope: ng.IRootScopeService,
    private toaster: toaster.IToasterService,
    private mfModal: any,
    private visitBroadcastService: VisitBroadcastService
  ) {
    this.shifts = [];
    this.selectedCaregiversInShifts = new Map<ShiftId, CaregiverId>();
    this.caregivers = [];
  }

  $onInit() {
    this.visitInstances = mapVisitInstancesTimes(this.visitInstances);
  }

  $onChanges(changesObj) {
    if ("visitInstances" in changesObj) {
      this.visitInstances = mapVisitInstancesTimes(this.visitInstances);
    }

    if ("caregiverVisitRequests" in changesObj || "visitAssignmentRequests" in changesObj) {
      const caregiverVisitRequests = changesObj.caregiverVisitRequests.currentValue;
      const visitAssignmentRequests = changesObj.visitAssignmentRequests.currentValue;

      if (caregiverVisitRequests.length > 0 || visitAssignmentRequests.length > 0) {
        this.setShiftsOnEngagementDataChange();
        this.caregivers = mapCaregiversIntoCaregiversInShifts(
          this.shifts,
          this.caregiverVisitRequests,
          this.visitAssignmentRequests
        );
      } else {
        // Without engagements there's no point in having shifts.
        this.shifts = [];
      }
    }
  }

  // --- Arranging the data on init ----

  mapCaregiversToInstancesIds(caregiverVisitRequests: CaregiverVisitRequest[]) {
    const caregiversByInstanceIdMap = new Map<VisitInstanceId, Caregiver[]>();

    caregiverVisitRequests.forEach((engagement) => {
      engagement.requestedInstances.forEach((instanceId) => {
        const caregiversByShifts = caregiversByInstanceIdMap.get(instanceId);

        if (caregiversByShifts === undefined) {
          caregiversByInstanceIdMap.set(instanceId, [engagement.caregiver]);
        } else {
          caregiversByShifts.push(engagement.caregiver);
          caregiversByInstanceIdMap.set(instanceId, caregiversByShifts);
        }
      });
    });

    return caregiversByInstanceIdMap;
  }

  setShiftsOnEngagementDataChange() {
    const caregiversByInstanceIdMap = this.mapCaregiversToInstancesIds(this.caregiverVisitRequests);

    if (this.visitBroadcastType === "PATTERN") {
      this.shifts = getPatternVisitShifts(this.visitInstances, caregiversByInstanceIdMap);
    } else {
      this.shifts = getSinglesVisitShifts(this.visitInstances, caregiversByInstanceIdMap, this.$filter);
    }
  }

  // ---- User actions on component ----

  toggleShiftSelection(shift: Shift, caregiverId: CaregiverId) {
    const shiftId = getShiftId(shift);

    if (this.selectedCaregiversInShifts.has(shiftId)) {
      const shiftKey = getShiftId(shift);
      this.selectedCaregiversInShifts.delete(shiftKey);
    } else {
      this.selectedCaregiversInShifts.set(shiftId, caregiverId);
    }
  }

  getSelectButtonClass(shift: Shift, caregiverId: CaregiverId) {
    const shiftId = getShiftId(shift);
    return this.isShiftSelected(shift) &&
      this.selectedCaregiversInShifts.get(shiftId) === caregiverId
      ? "select-to-assign-btn-selected"
      : "btn-primary";
  }

  getSelectBtnText(caregiverId: CaregiverId, shifts: Shift[]) {
    return !this.isChooseAllDisabled(caregiverId, shifts) &&
      !this.shouldSelectAllShifts(caregiverId, shifts)
      ? "Unselect"
      : "Select";
  }

  canSelectShift(shift: Shift, caregiverId: CaregiverId) {
    const shiftId = getShiftId(shift);
    const chosenCaregiverForShift = this.selectedCaregiversInShifts.get(shiftId);
    const isShiftChosenForAnotherCaregiver =
      chosenCaregiverForShift !== undefined && chosenCaregiverForShift !== caregiverId;

    return !isShiftChosenForAnotherCaregiver && !this.isShiftRequestedAssignment(shift);
  }

  caregiverRequestedShift(shift: Shift, caregiverId: CaregiverId) {
    return shift.caregivers.some((c) => c.id === caregiverId);
  }

  isShiftRequestedAssignment(shift: Shift) {
    return this
      .visitAssignmentRequests
      .some(({ pendingConfirmationInstances }) => pendingConfirmationInstances
        .some(({ visitInstanceId }) => shift.type === "PATTERN"
          ? shift.visitInstancesIds.indexOf(visitInstanceId) >= 0
          : shift.visitInstanceId = visitInstanceId
        )
      );
  }

  getCaregiverAssignedInstance(shift: Shift, caregiverId: CaregiverId) {
    for (const { caregiver, pendingConfirmationInstances } of this.visitAssignmentRequests) {
      if (caregiverId === caregiver.id) {
        return pendingConfirmationInstances.find(
          ({ visitInstanceId }) => shift.type === "PATTERN"
            ? shift.visitInstancesIds.indexOf(visitInstanceId) >= 0
            : shift.visitInstanceId = visitInstanceId
        )
      }
    }
  }

  isShiftSelected(shift: Shift): boolean {
    const shiftId = getShiftId(shift);
    return this.selectedCaregiversInShifts.has(shiftId);
  }

  shouldSelectAllShifts(caregiverId: CaregiverId, caregiverShifts: Shift[]) {
    const canBeSelectedShifts = caregiverShifts.filter((shift) =>
      this.canSelectShift(shift, caregiverId)
    );
    const selectedShifts = caregiverShifts.filter((shift) => {
      const shiftId = getShiftId(shift);
      return this.selectedCaregiversInShifts.get(shiftId) === caregiverId;
    });

    return selectedShifts.length !== canBeSelectedShifts.length;
  }

  isChooseAllDisabled(caregiverId: CaregiverId, caregiverShifts: Shift[]) {
    const canBeSelectedShifts = caregiverShifts.filter((shift) =>
      this.canSelectShift(shift, caregiverId)
    );
    const selectedShifts = caregiverShifts.filter((shift) => {
      const shiftId = getShiftId(shift);
      return this.selectedCaregiversInShifts.get(shiftId) === caregiverId;
    });

    return canBeSelectedShifts.length === 0 && selectedShifts.length === 0;
  }

  toggleAllShiftsSelectionForCaregiver(caregiverId: CaregiverId, caregiverShifts: Shift[]) {
    const shouldSelectAll = this.shouldSelectAllShifts(caregiverId, caregiverShifts);
    const canBeSelectedShifts = caregiverShifts.filter((shift) =>
      this.canSelectShift(shift, caregiverId)
    );

    for (const shift of canBeSelectedShifts) {
      const shiftId = getShiftId(shift);

      if (shouldSelectAll) {
        this.selectedCaregiversInShifts.set(shiftId, caregiverId);
      } else {
        this.selectedCaregiversInShifts.delete(shiftId);
      }
    }
  }

  clearSelectedAssignments() {
    this.selectedCaregiversInShifts = new Map<ShiftId, CaregiverId>();
  }

  buildRejectVisitBodyParams(caregiverId: CaregiverId, caregiverRequestedShifts: Shift[]) {
    const visitInstancesIds: VisitInstanceId[] = [];

    for (const shift of caregiverRequestedShifts) {
      if (shift.type === "PATTERN") {
        visitInstancesIds.push(...shift.visitInstancesIds);
      } else {
        visitInstancesIds.push(shift.visitInstanceId);
      }
    }

    return {
      visitInstances: visitInstancesIds,
    };
  }

  buildAssignCaregiverRequestBodyParams() {
    const requestBody: VisitBroadcastPartialAssingParams = { assignParams: [] };

    for (const [shiftId, caregiverId] of this.selectedCaregiversInShifts) {
      if (this.visitBroadcastType === "PATTERN") {
        // Trying to find the shift with the id that matches to 'shiftId', to take it's requested instances ids and build
        // the assign params.
        const currentShiftTimes = decouplePatternShiftIdToShiftDetails(shiftId as string);

        const currentShift = this.shifts.find((shift) => {
          if (shift.type === "PATTERN") {
            return arePatternShiftsEqual({ ...shift }, currentShiftTimes);
          }
        });

        const visitInstancesIdsInShift =
          currentShift !== undefined && currentShift.type === "PATTERN"
            ? currentShift.visitInstancesIds
            : [];

        // Caregivers can request specific instances from a pattern visit if they have an overlap visit instance of an availability issue in a specific day.
        // For that reason, I'm filtering out all of the instances that the caregiver cannot ask.
        const currCaregiverEngagement = this.caregiverVisitRequests.find((engagement) => caregiverId === engagement.caregiver.id);
        const caregiverRequestedInstances = currCaregiverEngagement === undefined ? [] : currCaregiverEngagement.requestedInstances;
        const instancedRequestedByCaregiverFromShift = visitInstancesIdsInShift.filter((id) => caregiverRequestedInstances.includes(id));

        requestBody.assignParams.push({
          caregiverId,
          visitInstancesIds: instancedRequestedByCaregiverFromShift,
        });
      } else {
        const singleShiftId = shiftId as VisitInstanceId;

        requestBody.assignParams.push({
          caregiverId: caregiverId,
          visitInstancesIds: [singleShiftId],
        });
      }
    }

    return requestBody;
  }

  assignShiftsToCaregivers() {
    const requestBody = this.buildAssignCaregiverRequestBodyParams();
    
    const assignCaregiverToVisits = () => this.$rootScope.isOnboardingAgency
        ? this.visitBroadcastService.requestAssignmentCaregiversToVisit(this.visitBroadcastId, requestBody)
        : this.visitBroadcastService.assignCaregiversToVisit(this.visitBroadcastId, requestBody)
    ;

    assignCaregiverToVisits().then((res) => {
      if (res.data.assignWithIncreasedCaregiverOvertime) {
        this.toaster.pop({
          type: "warning",
          title: "Warning",
          body: `Successfully assigned caregiver with increased caregiver overtime`,
        });
      } else {
        this.toaster.pop("success", "Successfully assigned caregiver");
      }
      this.$rootScope.$emit("refresh_visits");
      this.closeModal();
    },
    (err) => {
      let errorMessage = "Failed to assign caregiver";
      if (err.status === 403) {
        errorMessage = "Not permitted to increase caregiver overtime.";
      }
      this.toaster.pop("error", "Oops...", errorMessage);
    }
    );
  }

  openShouldRejectModal(caregiverId: CaregiverId, caregiverRequestedShifts: Shift[]) {
    const modal = this.mfModal.create({
      subject: "Are You Sure?",
      variant: "warning",
      message:
        "Clicking 'Reject' will result in rejecting the entire request (all of the caregiver's requested shifts).",
      cancelLabel: "I changed my mind",
      confirmLabel: "Reject Request",
      showInput: false,
      layoutOrder: ["message"],
      hideCancelButton: false,
      preventBackdropClose: true,
      onConfirm: () => {
        const requestBody = this.buildRejectVisitBodyParams(caregiverId, caregiverRequestedShifts);

        this.visitBroadcastService
          .rejectCaregiverVisitRequest(caregiverId, this.visitBroadcastId, requestBody)
          .then((_res) => {
            modal.close();
            this.toaster.pop("success", "Successfully rejected caregiver's request.");
            this.$rootScope.$broadcast("visit_changed", { visitId: this.visitBroadcastId });
          })
          .catch((_err) => {
            this.toaster.pop("error", "Oops...", "Failed to reject the caregiver's request.");
          });
      },
    });
  }
}

export const caregiverVisitRequestsTable = {
  templateUrl:
    "admin/modules/visit-broadcast/components/caregivers-visits-requests-table/caregivers-visits-requests-table.component.html",
  controller: caregiversVisitsRequestsTableCtrl,
  controllerAs: "ctrl",
  bindings: {
    caregiverVisitRequests: "<",
    visitAssignmentRequests: "<",
    visitBroadcastType: "<",
    visitInstances: "<",
    visitBroadcastId: "<",
    closeModal: "&",
    updateEngagements: "&",
    startFlyToCaregiver: "<",
    stopFlyToCaregiver: "<"
  },
};
