import { useToast } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { Messages, QueryParamsOf } from "../../../core/api";
import LoadingPage from "../../../shared/components/LoadingPage";
import useApi from "../../../shared/hooks/useApi";
import useAuthData from "../../../shared/hooks/useAuthInfo";
import { useQueryParams } from "../../../shared/hooks/useQueryParams";
import { queryKeys } from "../../../shared/query-keys";
import { CaregiverId, FaxDetailsId, PatientId } from "../../../shared/schema/schema";
import { formatErrorResponse } from "../../../shared/utils/format-response-error";
import { optimisticUpdate } from "../../../shared/utils/optimistic-update";
import { SelectEntityParams } from "../components/EditFaxEntitySection";
import { SendFaxRequest } from "../components/SendFaxForm";

import OpenFaxDashboardPage from "./OpenFaxDashboardPage";

export interface EditEntityRequestBody {
  faxId: FaxDetailsId;
  newDetails:
    | {
        relatedEntityType: "Caregiver";
        caregiverId: CaregiverId;
      }
    | {
        relatedEntityType: "Patient";
        patientId: PatientId;
      }
    | {
        relatedEntityType: null;
      };
}

const OpenFaxDashboardRoute = () => {
  const { api } = useApi();
  const toast = useToast();
  const { agency, agencyMember } = useAuthData();
  const queryClient = useQueryClient();

  const queryParams = useQueryParams<QueryParamsOf<"get", "./faxes">>({
    storageKey: ["comm_center", "fax-table"],
  });

  const faxesArray = useQuery({
    queryKey: queryKeys.fax.search(queryParams.params),
    keepPreviousData: true,
    queryFn: () => {
      return api.get("./faxes", {
        query: queryParams.params.toJSON(),
      });
    },
  });

  const updateFaxStatusAction = useMutation({
    mutationFn: (faxId: FaxDetailsId) => {
      return api.post("./faxes/update_status", {
        body: { id: faxId },
      });
    },
    onMutate: (faxId: FaxDetailsId) => {
      return optimisticUpdate<{ faxes: Messages["DashboardFax"][] }>({
        queryClient,
        queryKey: queryKeys.fax.search.K,
        update: (draft) => {
          if (draft.faxes) {
            draft.faxes
              .filter((fax) => fax.id === faxId)
              .forEach((fax) => (fax.isActionStatusDone = !fax.isActionStatusDone));
          }
        },
      });
    },
    onError: (error) => {
      toast({
        title: "Could not update status",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const editAssignedEntity = useMutation({
    mutationFn: (editEntityRequestBody: EditEntityRequestBody) => {
      return api.put("./faxes/:faxId/assign_entity", {
        path: {
          faxId: editEntityRequestBody.faxId,
        },
        body: {
          newDetails: editEntityRequestBody.newDetails,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.fax.search(queryParams.params));
    },
    onError: (error) => {
      toast({
        title: "Could not assign new patient/caregiver to fax",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const sendFax = useMutation({
    mutationFn: (sendFaxRequest: SendFaxRequest) => {
      // This is here temporarely because we don't use Router2 in this endpoint,
      // and as a result this endpoint is not generated by openapi command.
      const data = new FormData();
      data.append("comment", sendFaxRequest.comment);
      data.append("faxNumber", sendFaxRequest.faxNumber);
      data.append("subject", sendFaxRequest.subject);
      data.append("recipient", sendFaxRequest.recipientName);
      data.append("file", sendFaxRequest.file, sendFaxRequest.file.name);
      data.append("relatedEntity", JSON.stringify(sendFaxRequest.relatedEntity));
      return axios.post(
        `${import.meta.env.API_URL}agencies/${agency.id}/agency_members/${
          agencyMember.id
        }/fax/send`,
        data
      );
    },
    onSuccess: () => {
      toast({
        title: "Fax sent successfully",
        status: "success",
        position: "top-right",
      });

      queryClient.invalidateQueries(queryKeys.fax.search(queryParams.params));
    },
    onError: (error: unknown) => {
      toast({
        title: "Something went wrong. Couldn't send fax.",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const handleEditEntity = (faxId: FaxDetailsId, entity: SelectEntityParams | null) => {
    const editRequest: EditEntityRequestBody = {
      faxId,
      newDetails:
        entity !== null
          ? entity
          : {
              relatedEntityType: null,
            },
    };

    editAssignedEntity.mutate(editRequest);
  };

  const handleSendFax = (sendFaxRequest: SendFaxRequest) => {
    sendFax.mutate(sendFaxRequest);
  };

  if (faxesArray.isLoading) {
    return <LoadingPage />;
  }

  if (faxesArray.isError) {
    return <div>Error</div>;
  }

  if (!faxesArray.data) {
    return <div>Something went wrong</div>;
  }

  return (
    <OpenFaxDashboardPage
      faxes={faxesArray.data.faxes}
      onSendFax={handleSendFax}
      filters={queryParams}
      onChangeFilter={queryParams.setValue}
      onChangeFilters={queryParams.setValues}
      onUpdateStatus={updateFaxStatusAction.mutate}
      onSelectEntity={handleEditEntity}
    />
  );
};

export default OpenFaxDashboardRoute;
