import React from "react";

/**
 * If you use react-in-angularjs and set state doesn't work for you, use this hook inside your component.
 * For some reason, sometimes, react re-renders only when angular is digesting. In order to trigger a digestion,
 * this component will set state every one second to fix it. Avoid using this hook if necessary.
 */
export default function useUnstableAngularizeDigestWorkaround() {
  const [, setDigest$] = React.useState(0);

  React.useEffect(() => {
    if (import.meta.env.NODE_ENV !== "development") {
      return;
    }
    const i = setInterval(
      () =>
        setDigest$((p) => {
          return p + 1;
        }),
      500
    );

    return () => clearInterval(i);
  }, []);
}
