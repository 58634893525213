import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { FaPhoneSlash } from "react-icons/fa";
import { dateFormatter } from "../../../../../../../shared/utils/date-formatter";
import CallTicketIndexBadge from "../../../../CallTicketIndexBadge";
import { getParticipantDescription } from "../call-ticket-event-utils";
import { CallHangupEvent } from "../call-ticket-events-typings";

interface Props {
  event: CallHangupEvent;
}

const CallTicketEventCardHangup = ({ event }: Props) => {
  return (
    <Flex gap={2} alignItems={"center"}>
      <CallTicketIndexBadge>
        <FaPhoneSlash />
      </CallTicketIndexBadge>
      <Flex direction={"column"}>
        <Text color={"gray.400"}>{dateFormatter.toDateTime(event.createdAt)}</Text>
        <Flex gap={1}>
          <Text fontWeight={"bold"}>{getParticipantDescription(event.callSessionParticipant)}</Text>
          <Text>hangup call.</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CallTicketEventCardHangup;
