import { ChevronDownIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  Highlight,
  HighlightProps,
  IconButton,
  Image,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Progress,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { UseQueryResult } from "@tanstack/react-query";
import React from "react";
import FocusLock from "react-focus-lock";
import { FaRegTimesCircle } from "react-icons/fa";
import { match } from "ts-pattern";
import { fmap } from "../utils";
import { getFullName } from "../utils/get-full-name";
import { Loadable, loadable } from "../utils/loadable";
import { CircleShimmer, TextShimmer } from "./Shimmer";

type BaseEntity = {
  id: unknown;
  displayId: unknown;
  firstName: string;
  middleName: string | null;
  lastName: string;
};

type Props<
  $Entity extends BaseEntity,
  $SearchResultData extends BaseEntity[],
  $EntitiesQueryData extends $Entity[]
> = {
  value: $Entity["id"] | null;
  entitiesQuery: UseQueryResult<$EntitiesQueryData, unknown>;
  searchQuery: UseQueryResult<$SearchResultData>;
  buttonPlaceholder: string;
  inputPlaceholder: string;
  notFoundPlaceholder: string;
  searchText: string;
  leftIcon: React.ReactElement;
  isDisabled?: boolean;
  onChangeSearchText: (value: string) => void;
  photoUrlPredicate: (entity: $SearchResultData[number]) => string | undefined;
  onChange: (value: $Entity | null) => void;
};

const EntitySelect = <
  $Entity extends BaseEntity,
  $SearchResultData extends BaseEntity[],
  $EntitiesQueryData extends $Entity[]
>(
  props: Props<$Entity, $SearchResultData, $EntitiesQueryData>
) => {
  const disclosure = useDisclosure({ onClose: () => props.onChangeSearchText("") });

  const getSelectedEntity = (): Loadable<$Entity | null> => {
    if (props.entitiesQuery.isPaused || props.value === null) {
      return loadable.resolve(null);
    }

    if (props.entitiesQuery.isLoading) {
      return loadable.loading();
    }

    if (props.entitiesQuery.data !== undefined) {
      return loadable.resolve(
        props.entitiesQuery.data.find((entity) => entity.id === props.value) ?? null
      );
    }

    return loadable.reject(props.entitiesQuery.error);
  };

  const entityName = match(getSelectedEntity())
    .with({ type: "Loading" }, () => "Loading...")
    .with({ type: "Resolved" }, ({ value }) => fmap(value, getFullName) ?? props.buttonPlaceholder)
    .with({ type: "Rejected" }, () => "Error")
    .exhaustive();

  return (
    <Popover placement="bottom-start" isLazy={true} {...disclosure}>
      <ButtonGroup
        isAttached={true}
        variant="outline"
        color={props.value !== null ? "blue.500" : "gray.600"}
      >
        <PopoverTrigger>
          <Button
            leftIcon={props.leftIcon}
            rightIcon={props.value === null ? <ChevronDownIcon /> : undefined}
            bg={props.value !== null ? "blue.50" : "transparent"}
            borderColor={props.value !== null ? "blue.200" : "gray.300"}
            disabled={props.isDisabled ?? false}
          >
            {entityName}
          </Button>
        </PopoverTrigger>
        {props.value !== null && (
          <IconButton
            icon={<CloseIcon />}
            aria-label="close"
            fontSize={8}
            onClick={() => props.onChange(null)}
            bg={props.value !== null ? "blue.50" : "transparent"}
            borderColor={props.value !== null ? "blue.200" : "gray.300"}
            disabled={props.isDisabled ?? false}
          />
        )}
      </ButtonGroup>
      <PopoverContent>
        <PopoverBody p={0}>
          <FocusLock>
            <EntitySelectInput
              placeholder={props.inputPlaceholder}
              value={props.searchText}
              onChange={props.onChangeSearchText}
            />
          </FocusLock>
          <Box opacity={!props.searchQuery.isLoading && props.searchQuery.isFetching ? 1 : 0}>
            <Progress size="xs" isIndeterminate h="1px" marginTop="-1px" bg="gray.200" />
          </Box>

          <Box maxH="40vh" overflow="auto">
            {match(loadable.fromQuery(props.searchQuery))
              .with({ type: "Loading" }, () =>
                props.searchQuery.isFetching ? (
                  <EntitySelectItemsShimmer />
                ) : (
                  <Center py={10} color="gray.400" fontSize={16}>
                    <Text>Search by name or ID</Text>
                  </Center>
                )
              )
              .with({ type: "Resolved" }, ({ value: results }) => (
                <Box>
                  {results.length === 0 && (
                    <EntitySelectNotFound placeholder={props.notFoundPlaceholder} />
                  )}
                  <Box>
                    {results.map((result) => {
                      const entity = props.entitiesQuery.data?.find((x) => x.id === result.id);
                      return (
                        <EntitySelectItem
                          key={`${result.id}`}
                          highlight={props.searchText}
                          entity={result}
                          photoUrl={props.photoUrlPredicate(result)}
                          onClick={() => {
                            disclosure.onClose();
                            props.onChange(entity ?? null);
                          }}
                        />
                      );
                    })}
                  </Box>
                </Box>
              ))
              .with({ type: "Rejected" }, ({ error }) => (
                <Text>Error ({JSON.stringify(error)})</Text>
              ))
              .exhaustive()}
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

function FixHighlight(props: HighlightProps): JSX.Element {
  if (props.query.length === 0) {
    return <>{props.children}</>;
  }

  return <Highlight {...props} />;
}

function EntitySelectNotFound(props: { placeholder: string }): JSX.Element {
  return (
    <Center py={8} flexDirection="column" gap={2} fontSize={16} color="gray.400">
      <FaRegTimesCircle />
      <Text>{props.placeholder}</Text>
    </Center>
  );
}

function EntitySelectItem<$Entity extends BaseEntity>(props: {
  highlight: string;
  entity: $Entity;
  photoUrl: string | undefined;
  onClick: (entity: $Entity) => void;
}) {
  const handleClick = () => {
    return props.onClick(props.entity);
  };

  const photoUrl = props.photoUrl ?? "/admin/images/blank-profile.jpg";

  return (
    <Flex
      gap={2}
      p={4}
      align="center"
      cursor="pointer"
      _hover={{ bg: "gray.50" }}
      onClick={handleClick}
    >
      <Image src={photoUrl} w={10} h={10} objectFit="cover" rounded="full" />
      <Flex direction="column">
        <Text fontWeight={600}>
          <FixHighlight
            query={props.highlight}
            styles={{ px: 0, py: 0, bg: "blue.100", borderRadius: 4 }}
          >
            {getFullName(props.entity)}
          </FixHighlight>
        </Text>
        <Text fontSize="sm" fontWeight={500} color="gray.500">
          <FixHighlight
            query={props.highlight}
            styles={{ px: "1", py: "1", bg: "blue.100", borderRadius: 4 }}
          >
            {`#${props.entity.displayId ?? props.entity.id}`}
          </FixHighlight>
        </Text>
      </Flex>
    </Flex>
  );
}

function EntitySelectItemsShimmer() {
  return (
    <Box>
      <EntitySelectItemShimmer />
      <EntitySelectItemShimmer />
      <EntitySelectItemShimmer />
    </Box>
  );
}

export function EntitySelectItemShimmer() {
  return (
    <Flex gap={2} p={4} align="center">
      <Box flexShrink={0}>
        <CircleShimmer width={40} height={40} />
      </Box>
      <TextShimmer height={40} width={300} />
    </Flex>
  );
}

function EntitySelectInput(props: {
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
}) {
  return (
    <Input
      autoFocus={true}
      variant="unstyled"
      placeholder={props.placeholder}
      borderRadius={0}
      borderBottom="1px solid"
      borderBottomColor="gray.200"
      py={3}
      px={4}
      value={props.value}
      onChange={(e) => props.onChange(e.target.value)}
    />
  );
}

export default EntitySelect;
