import { Instant } from "@js-joda/core";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import jwtDecode from "jwt-decode";
import React from "react";
import { webappChannel } from "../../core";
import { Messages, ResponseOf } from "../../core/api";
import { UserId } from "../schema/schema";
import { useWebappChannelPayloadEvent } from "./useWebappChannelEvent";

export type AuthTokens = { authToken: string; refreshToken: string };

interface JWTResponse {
  userLoginDetails: {
    agencyMember: Messages["AgencyMember"];
    agency: Messages["Agency"];
  };
  authData: {
    userId: UserId;
    userCreatedAt: Instant;
    superuser: boolean;
    email: string | null;
  };
}

export interface AuthData {
  userId: UserId;
  agencyMember: Messages["AgencyMember"];
  agency: Messages["Agency"];
}

const authTokensAtom = atomWithStorage<AuthTokens | null>("tokens", null);

const useAuth = () => {
  const [tokens, setTokens] = useAtom(authTokensAtom);
  const [isUnauthorized, setIsUnauthorized] = React.useState(false);

  useWebappChannelPayloadEvent({
    eventName: "AUTH_SET",
    runInitial: true,
    onEvent: () => {
      const info = webappChannel.getPayload("AUTH_SET") ?? null;

      return info !== null
        ? setTokens({ authToken: info.authToken, refreshToken: info.refreshToken })
        : setIsUnauthorized(true);
    },
  });

  const isLoggedIn = tokens != null;

  const logout = (onLoggedOut: () => void) => {
    const clearExcept = [
      "stealthMode",
      "code",
      "showVideoNewVisitForm",
      "caregiversTableSettings",
      "trainingTableSettings",
      "pendingApplicationTableSettings",
      "coronaReportsTableSettings",
      "medflytPassportTableSettings",
      "billingAccountsReceivableTableSettings",
      "savedAssignedCoordinators",
      "faxComment",
      "localDistHash",
      "hide-sweep-vegas",
      "hide-reset-attempts-video",
      "hide-corona-banner",
      "trainingCenterStatisticsTableColumn",
      "trainingCenterOverallStatisticsTableColumn",
      "searchFiltersSettings",
      "visitsDashboardTableSettings",
      "stateOfHomeCareSurvey",
      "patientCalendarShowDeletedVisits",
      "caregiverCalendarShowDeletedVisits",
      "ClientSurveyNextDate",
    ];

    const localStorageKeys = Object.keys(localStorage);
    for (const key of localStorageKeys) {
      if (!clearExcept.includes(key)) {
        localStorage.removeItem(key);
      }
    }

    return onLoggedOut();
  };

  function getAuthData() {
    if (tokens == null) {
      return { type: "guest" as const };
    }

    if (isUnauthorized) {
      return { type: "unauthorized" as const };
    }

    const { authData, userLoginDetails } = jwtDecode<JWTResponse>(tokens.authToken);

    const data: AuthData = {
      userId: authData.userId,
      agency: userLoginDetails.agency,
      agencyMember: userLoginDetails.agencyMember,
    };

    return {
      type: "authenticated" as const,
      data: data,
      tokens: tokens,
    };
  }

  function handleTokenRefresh(data: ResponseOf<"post", "/auth/token">) {
    setTokens({
      authToken: data.accessJWT,
      refreshToken: data.refreshJWT,
    });
  }

  return {
    tokens,
    isUnauthorized,
    isLoggedIn,
    logout,
    authData: getAuthData(),
    handleTokenRefresh,
  };
};

export default useAuth;
