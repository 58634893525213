import { Box, Divider } from "@chakra-ui/react";
import React from "react";
import { Shimmer } from "react-shimmer";
import { Messages } from "../../../core/api";
import { TextShimmer } from "../../../shared/components/Shimmer";
import { CommCenterTicketId } from "../../../shared/schema/schema";
import { loadable, Loadable } from "../../../shared/utils/loadable";
import TicketsGroup from "./TicketsGroup";

interface Props {
  tickets: Loadable<Messages["CommCenterTicket"][]>;
  selectedTicketId?: CommCenterTicketId;
  onClickTicket: (ticketId: CommCenterTicketId) => void;
}

const TicketsList = (props: Props) => {
  if (!loadable.isResolved(props.tickets)) {
    return <TicketsList.Loading />;
  }

  const { value: tickets } = props.tickets;

  const openTickets = tickets.filter((ticket) => ticket.status !== "RESOLVED");
  const resolvedTickets = tickets.filter((ticket) => ticket.status === "RESOLVED");

  return (
    <Box>
      <TicketsGroup
        label={<>Open Tickets ({openTickets.length})</>}
        tickets={openTickets}
        activeTicketId={props.selectedTicketId}
        onClickTicket={props.onClickTicket}
      />

      <Divider />

      <TicketsGroup
        label={<>Resolved Tickets ({resolvedTickets.length})</>}
        tickets={resolvedTickets}
        activeTicketId={props.selectedTicketId}
        onClickTicket={props.onClickTicket}
      />
    </Box>
  );
};

TicketsList.Loading = () => {
  const headline = (
    <Box m={2}>
      <Shimmer width={360} height={50} />
    </Box>
  );

  const box = (
    <Box m={3}>
      <TextShimmer width={350} height={90} />
    </Box>
  );

  return (
    <Box>
      {headline}
      {box}
      {box}
      {box}
      {headline}
      {box}
      {box}
      {box}
    </Box>
  );
};

export default TicketsList;
