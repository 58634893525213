import { useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Messages } from "../../../../../core/api";
import useApi from "../../../../../shared/hooks/useApi";
import useAuth from "../../../../../shared/hooks/useAuth";
import { createFilters } from "../../../../../shared/hooks/useFilters";
import { fmap } from "../../../../../shared/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CaregiverId, NoteSubjectId, PatientId } from "../../../../../shared/schema/schema";
import { queryKeys } from "../../../../../shared/query-keys";
import useCallCenterNoteCache from "../../../hooks/useCallCenterNoteCache";

type PatientPrticipant = Messages["CallSessionParticipant.Patient"] & {
  role: "Caller" | "Participant" | "Recipient";
};

type Caregiverparticipant = Messages["CallSessionParticipant.Caregiver"] & {
  role: "Caller" | "Participant" | "Recipient";
};

const isPatientParticipant = (
  val: Messages["CallSessionParticipantRoles"]
): val is PatientPrticipant => val.type === "Patient";

const isCaregiverParticipant = (
  val: Messages["CallSessionParticipantRoles"]
): val is Caregiverparticipant => val.type === "Caregiver";

const createNoteSchema = z.object({
  text: z.string().min(4),
  subjectId: z
    .number()
    .nullable()
    .transform((x) => fmap(x, NoteSubjectId.wrap)),
});

export type CreateNoteForm = z.infer<typeof createNoteSchema>;

const useCallTicketNotes = ({
  noteSubjects,
  ticket,
}: {
  ticket: Messages["CommCenterTicket"] & {
    callSessionDetails: Messages["CallSessionDetails"];
  };
  noteSubjects: { label: string; value: NoteSubjectId }[];
}) => {
  const { getTicketNoteCacheText, getTicketNoteCacheSubjectId, clearTicketNoteCache } =
    useCallCenterNoteCache();
  const queryClient = useQueryClient();
  const toast = useToast();
  const { api } = useApi();
  const { authData } = useAuth();
  const { control, handleSubmit, setValue, getValues, reset, formState } = useForm<CreateNoteForm>({
    resolver: zodResolver(createNoteSchema),
    defaultValues: async () => ({
      subjectId: getTicketNoteCacheSubjectId(ticket.id),
      text: getTicketNoteCacheText(ticket.id),
    }),
  });

  const createNoteMutation = useMutation({
    mutationKey: ["create-call-ticket-note"],
    mutationFn: (data: {
      text: string;
      subjectId: NoteSubjectId | null;
      patientId?: PatientId;
      caregiverId?: CaregiverId;
    }) =>
      api.post("./call_center/:callSessionId/note", {
        path: {
          callSessionId: ticket.callSessionDetails.sessionId,
        },
        body: {
          status: "NONE",
          noteRichText: data.text,
          subject: data.subjectId ?? undefined,
          noteType: "phone_call",
          patientId: data.patientId,
          caregiverId: data.caregiverId,
          agencyMemberIds: authData.data?.agencyMember.id ? [authData.data?.agencyMember.id] : [],
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.commCenter.get(ticket.id),
      });
      clearTicketNoteCache(ticket.id);
      reset();
      toast({
        position: "top-right",
        status: "success",
        title: "Note added successfully",
      });
    },
  });

  const { createSelectFilter } = createFilters<CreateNoteForm>();
  const subjectIdSelectFilter = createSelectFilter({
    name: "subjectId",
    disabled: false,
    label: "Note subject",
    options: noteSubjects,
    onChange: (name, selected) => setValue(name, selected ?? null),
    value: getValues().subjectId,
  });

  const handleValidSubmit = (data: CreateNoteForm) => {
    const patient = ticket.callSessionDetails.sessionParticipantRoles.find(isPatientParticipant);
    const caregiver =
      ticket.callSessionDetails.sessionParticipantRoles.find(isCaregiverParticipant);
    createNoteMutation.mutate({
      subjectId: data.subjectId,
      text: data.text,
      caregiverId: caregiver?.caregiverId,
      patientId: patient?.patientId,
    });
  };

  return {
    formState,
    control,
    subjectIdSelectFilter,
    onSubmit: handleSubmit(handleValidSubmit),
    clearForm: () => {
      reset();
      clearTicketNoteCache(ticket.id);
    },
  };
};

export default useCallTicketNotes;
