import React from "react";
import { createIcon } from "@chakra-ui/react";

const BaselinePlayCircleIcon = createIcon({
  displayName: "BaselinePlayCircleIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zM9.5 16.5v-9l7 4.5l-7 4.5z"
    />
  ),
});

export default BaselinePlayCircleIcon;
