import { CaregiverId, PatientId } from "@medflyt/messages/ids";
import angular from "angular";
import ng from "angular";
import { Moment } from "moment";
import { MultiselectCallbackParameter } from "public/admin/modules/shared/components/multiselect/multiselect.component";
import { FaxDetailsId } from "webapp-react/src/shared/schema/schema";
import { FaxesService } from "../../faxes.service";
import { DashboardFax, FaxStatus, FaxStatusFilterLabel } from "../../faxes.types";
import "./profile-faxes-table.component.scss";

interface ProfileFaxesTableBindings {
  entityType: "Caregiver" | "Patient";
  entityId: CaregiverId | PatientId;
}

interface FaxTableFilters {
  dropdowns: {
    status: {
      options: Array<{
        id: number;
        label: FaxStatusFilterLabel;
      }>;
      selected: Array<{
        id: number;
        label: FaxStatusFilterLabel;
      }>;
    };
    isDone: {
      options: Array<{
        id: string;
        label: string;
      }>;
      selected: Array<{
        id: string;
        label: string;
      }>;
    };
  };
  createdFrom: Moment | undefined;
  createdTo: Moment | undefined;
}

//! @ngInject
class profileFaxesTableCtrl implements ng.IComponentController, ProfileFaxesTableBindings {
  entityType!: "Caregiver" | "Patient";
  entityId!: CaregiverId | PatientId;
  faxesTableParams!: NgTableParams<DashboardFax>;

  public filters: FaxTableFilters = {
    dropdowns: {
      status: {
        options: [
          { id: 0, label: "Processing" },
          { id: 1, label: "Failed" },
          { id: 2, label: "Success" },
        ],
        selected: [
          { id: 0, label: "Processing" },
          { id: 1, label: "Failed" },
          { id: 2, label: "Success" },
        ],
      },
      isDone: {
        options: ["Yes", "No"].map((status) => ({ id: status, label: status })),
        selected: [{ id: "No", label: "No" }],
      },
    },
    createdFrom: undefined,
    createdTo: undefined,
  };

  constructor(
    private $rootScope: angular.IRootScopeService,
    private faxesService: FaxesService,
    private toaster: toaster.IToasterService,
    private NgTableParams: NgTable.ITableParamsConstructor<DashboardFax>
  ) {}

  $onInit() {
    this.initTable();
  }

  initTable = () => {
    const caregiverId =
      this.entityType === "Caregiver" ? (this.entityId as CaregiverId) : undefined;
    const patientId = this.entityType === "Patient" ? (this.entityId as PatientId) : undefined;
    const statuses = this.filters.dropdowns.status.selected.map((option) =>
      this.matchStatusToLabel(option.label)
    );

    this.faxesService
      .getFaxes(caregiverId, patientId, this.filters.createdFrom, this.filters.createdTo, statuses)
      .then((res) => {
        const options = {
          count: 10,
          sorting: { category: "asc" },
        };

        this.faxesTableParams = new this.NgTableParams(options, {
          counts: [10, 25, 50, 100],
          dataset: res.data.faxes,
        });
      })
      .catch((err) => {
        console.log(err.message);
        this.toaster.pop("error", "Failed to load faxes");
      });
  };

  $onChanges(onChangesObj: angular.IOnChangesObject): void {
    if ("faxTableFilters" in onChangesObj) {
      this.initTable();
    }
  }

  matchStatusToLabel = (statusLabel: FaxStatusFilterLabel): FaxStatus => {
    switch (statusLabel) {
      case "Processing":
        return "processing";
      case "Failed":
        return "failed";
      case "Success":
        return "success";
      default:
        throw new Error("Not a fax status");
    }
  };

  handleCreatedAtFilterChange = (startDate, endDate) => {
    this.filters = {
      ...this.filters,
      createdFrom: startDate,
      createdTo: endDate,
    };

    this.initTable();
  };

  handleStatusDropdownFilterChange = (selected: MultiselectCallbackParameter<number>) => {
    if (selected.length === 0) {
      this.filters.dropdowns.status.selected = [];
    } else {
      const selectedIds = selected.map((selected) => selected.id);
      const selectedOptions = this.filters.dropdowns.status.options.filter((option) =>
        selectedIds.includes(option.id)
      );
      this.filters.dropdowns.status.selected = selectedOptions;
    }

    this.initTable();
  };

  viewFile = (faxId: FaxDetailsId) => {
    this.$rootScope.openDocumentPreviewModalById(faxId as any);
  };

  markFaxAsDone = (faxId: FaxDetailsId) => {
    this.faxesService
      .markFaxAsDone(faxId)
      .then((_res) => {
        this.toaster.success("Successfuly updated fax");
        this.initTable();
      })
      .catch((err) => {
        console.log(err.message);
        this.toaster.pop("error", "Failed to update the fax status");
      });
  };
}

export const profileFaxesTable = {
  templateUrl:
    "admin/modules/faxes/components/profile-faxes-table/profile-faxes-table.component.html",
  controller: profileFaxesTableCtrl,
  controllerAs: "ctrl",
  bindings: {
    entityType: "<",
    entityId: "<",
  },
};
