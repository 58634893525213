import { LocalDate, LocalTime, DateTimeFormatter, nativeJs } from "js-joda";

import moment from "moment";

//! @ngInject
export function newPatientTaskModalCtrl($scope, $rootScope, DatabaseApi, toaster, $uibModalInstance, SweetAlert, visitConsts, wildcard, dateUtils, PatientModalService) {
    $scope.showRequirements = false;
    $scope.certificationPeriodLocked = $scope.certificationPeriodLocked ?? false;
    function initialize() {
        $scope.repeatMonthsOptions = [];
        $scope.isSaving = false;
        $scope.isBroadcasting = false;

        for (let i = 1; i <= 24; i++) {
            $scope.repeatMonthsOptions.push({ id: i, label: i.toString() });
        }

        if ($scope.task) {
            PatientModalService.getPatientContractBasic($scope.patientId, true).then((contracts) => {
              if ($scope.formData.contract?.id === undefined) {
                $scope.formData.contract = contracts.find(
                  (x) => x.id === $scope.task.patientContractId
                );
              }
            });

            // Editing existing task.
            $scope.isEditMode = true;
            $scope.formData.contract = {};
            $scope.formData.serviceCode = DatabaseApi.serviceCodes().find(x => x.id === $scope.task.serviceCodeId) ?? {};
            $scope.formData.payrollCode = DatabaseApi.payrollCodes().find(x => x.id === $scope.task.payrollCodeId) ?? {};
            $scope.formData.certifications = $scope.task.certifications || [];
            $scope.formData.caregiver = $scope.task.caregiverId ? $scope.caregiversMap[$scope.task.caregiverId] : null;
            $scope.formData.datePicker.date.startDate = $scope.task.startDate ? $scope.task.startDate.toDate() : new Date();
            $scope.formData.datePicker.date.endDate = $scope.task.dueDate ? $scope.task.dueDate.toDate() : new Date();
            $scope.customizeDaysTimes = Boolean($scope.task.dayTimeParams);
            $scope.formData.selectedCms485CertificationPeriodId = $scope.task.certificationPeriodId;

            $scope.certificationPeriodLocked = $scope.certificationPeriodLocked || $scope.task.isCertificationPeriodBoundTask;

            if ($scope.task.startTime) {
                $scope.formData.scheduledDateTime.settings.startTime = moment(new Date($scope.task.startTime));
                $scope.formData.scheduledDateTime.settings.endTime = moment(new Date($scope.task.endTime));
                $scope.formData.scheduledDateTime.settings.emptyOnStart = false;
                $scope.formData.scheduledDateTime.settings.fixedDuration = $scope.task.durationMinutes;
            }

            initDayTimeParams();

        } else {
            // New task.
            $scope.isEditMode = false;

            if ($scope.date) {
                $scope.formData.datePicker.date.startDate = new Date($scope.date);
                $scope.formData.datePicker.date.endDate = new Date($scope.date.plusMonths(1));
                if ($scope.certificationPeriod) {
                    initFormWithCertificationPeriodData();
                    $scope.formData.selectedCms485CertificationPeriodId = $scope.certificationPeriod.id;
                }
            }

            $scope.isFutureAssigned = false;
        }
    }

    $scope.isFutureAssignedEnabled = () => $scope.formData.caregiver && $scope.newPatientTaskModel.repeatMonths.id;

    const initFormWithCertificationPeriodData = () => {
        $scope.isCertificationPeriod = true;
        const context = $scope.certificationPeriod.type;
        const taskTemplate = $scope.patientTaskTemplates.find(t => t.context === context);
        if (taskTemplate !== undefined) {
            $scope.setTaskTemplate(taskTemplate);
            $scope.hasCertificationPeriodTaskTemplate = true;
        }
    };

    function initDayTimeParams() {
        if ($scope.task.dayTimeParams && $scope.task.dayTimeParams.length > 0) {
            const isEverydayTheSame = isResponseDayTimeTheSame();
            const dayTimeParams = isEverydayTheSame ? [$scope.task.dayTimeParams[0]] : $scope.task.dayTimeParams;

            $scope.formData.isDifferentDayTimes = !isEverydayTheSame;

            for (let i = 0; i <= 1; i ++) {
                const result = {};

                dayTimeParams.forEach(dayTime => {
                    const day = dayTime.dayOfWeek[0] + (dayTime.dayOfWeek.slice(1)).toLowerCase();
                    const dayKey = isEverydayTheSame ? 'Everyday' : day;
                    const startTime = moment(dayTime.startTime, 'HH:mm');
                    const endTime = moment(dayTime.endTime, 'HH:mm');

                    result[dayKey] = {
                        startTime,
                        endTime,
                        isOverNight: !startTime.isBefore(endTime),
                        duration: getDurationLabel(moment.duration(endTime.diff(startTime))),
                        durationHours: endTime.diff(startTime, 'hours'),
                        durationMinutes: endTime.diff(startTime, 'minutes')
                    };

                    return result;
                });

                $scope.formData.advancedTimeShiftsPermanent[i] = result;
            }

            const daysOfTheWeek = {};

            for (const dayTime of $scope.task.dayTimeParams) {
                const day = dayTime.dayOfWeek[0] + (dayTime.dayOfWeek.slice(1)).toLowerCase();
                daysOfTheWeek[day] = true;
            }

            $scope.formData.daysOfTheWeek = daysOfTheWeek;
        }
    }

    function isResponseDayTimeTheSame() {
        let startTime, endTime;

        for (const dayTime of $scope.task.dayTimeParams) {
            if (startTime === undefined) {
                startTime = dayTime.startTime;
                endTime = dayTime.endTime;
            }

            if (startTime !== dayTime.startTime || endTime !== dayTime.endTime) {
                return false;
            }
        }

        return true;
    }

    $scope.getDefaultShift = (shiftId) => {
        const tempShift = shiftId === 0 ?
            visitConsts.defaultFirstShift :
            visitConsts.defaultSecondShift;
        return {
            startTime: tempShift.startTime.clone(),
            endTime: tempShift.endTime.clone(),
            isOverNight: tempShift.isOverNight,
            duration: tempShift.duration,
            durationHours: tempShift.durationHours,
            durationMinutes: tempShift.durationMinutes
        }
    };

    $scope.customizeDaysTimes = false;

    $scope.formData = {
        // For the contract component
        contract: {},
        serviceCode: {},
        payrollCode: {},
        certifications: [],
        
        // For the footer component
        caregiver: null,
        enableCaregivers : {
            shifts: false,
            global: true,
            footer: true,
            broadcast: true,
        },

        // For the dates component
        shifts: 1,
        disableDays: false,
        daysOfTheWeek: {},
        weeksDiff: 0,
        datePicker: {
            date: {
                startDate: new Date(),
                endDate: moment().add(1, "months").toDate()
            }
        },
        isDifferentDayTimes: false,
        visitType: "Permanent",
        usedAuthorizationRow: null,
        advancedTimeShiftsPermanent: [
            {"Everyday": $scope.getDefaultShift(0) },
            {"Everyday": $scope.getDefaultShift(1) }
        ],
        selectedCms485CertificationPeriodId: null,
        scheduledDateTime: {
            settings: {
                startDate: new Date(),
                endDate: moment().add(1, "months").toDate(),
                fixedDuration: null,
                emptyOnStart: true
            },
            data: null
        },
        assignedTaskEditType: $scope.task?.state === "Assigned" ? 'CurrentTask' : null
    };

    $scope.toggleData = {
        contractAndPayment: false,
        showMoreStartDate: false,
        scheduledDateTime: false
    };

    $scope.toggle = (key) => $scope.toggleData[key] = !$scope.toggleData[key];

    $scope.handleCertificationsChanged = () => {};

    $scope.modalTitle = $scope.type !== null ? 'Review And Assign' : 'New Patient Task';
    $scope.newPatientTaskModel = {
        caregiverId: null,
        taskTemplate: {},
        documents: [],
        repeatMonths: {},
        planOfCareTypeId: null,
        isDraft: true,
        initialDates: {
            from: new Date(LocalDate.now().format(DateTimeFormatter.ofPattern("MM/dd/yyyy"))),
            to: new Date(LocalDate.now().plusMonths(1).format(DateTimeFormatter.ofPattern("MM/dd/yyyy")))
        }
    };
    $scope.filteredCaregivers = [];
    $scope.caregiversMap = DatabaseApi.caregivers() || {};

    // HHA and PCA caregivers should be selected for both PCA and HHA visits.
    const mapCaregiverCertifications = (certifications) => {
        const hasHHA = certifications.some(cert => cert === "HHA");
        const hasPCA = certifications.some(cert => cert === "PCA");

        if(hasHHA && !hasPCA){
            certifications.push("PCA");
        }

        if(!hasHHA && hasPCA){
            certifications.push("HHA");
        }

        return certifications;
    }

    function setFilteredCaregiversMapByCertifications(allowedCertifications) {
        $scope.filteredCaregivers = Object.values($scope.caregiversMap)
            .filter(caregiver => (
                mapCaregiverCertifications(caregiver.certifications).some(cert => allowedCertifications.includes(cert))
                && !caregiver.blacklisted.includes(Number($scope.patientId))
            ));
        $scope.formData.caregiver = null;
    }

    if ($scope.task) {
        setPatientDraftOrFutureTaskData();
    }

    $scope.selectPatientTaskDocumentsExtraSettings = {
        styleActive: true,
        scrollable: true,
        scrollableHeight: "400px",
        enableSearch: true
    };

    $scope.repeatMonthsExtraSettings = {
        styleActive: true,
        singleSelection: true,
        selectionLimit: 1,
        smartButtonMaxItems: 1,
        closeOnSelect: true,
        scrollable: true,
        scrollableHeight: "150px"
    };

    $scope.setTaskTemplate = (selectedTaskTemplate) => {
        if ($scope.newPatientTaskModel.taskTemplate.id === selectedTaskTemplate.id) {
            $scope.newPatientTaskModel.taskTemplate = {};
            $scope.filteredCaregivers = [];
            $scope.formData.scheduledDateTime.settings.fixedDuration = null;
        } else {
            $scope.newPatientTaskModel.taskTemplate = selectedTaskTemplate;
            setTaskTemplatesRelevantDocuments($scope.newPatientTaskModel.taskTemplate);
    
            // dynamically change task name also
            $scope.newPatientTaskModel.title = $scope.newPatientTaskModel.taskTemplate.title;
            setFilteredCaregiversMapByCertifications($scope.newPatientTaskModel.taskTemplate.allowedCertifications);
            $scope.formData.scheduledDateTime.settings.fixedDuration = selectedTaskTemplate.durationMinutes;
        }
    }

    function setTaskTemplatesRelevantDocuments(taskTemplate) {

        $scope.newPatientTaskModel.documents = taskTemplate.documents.map(documentId => {
            return $scope.documentsMultiSelectOptions.find(d => d.id === documentId);
        }).filter(obj => obj !== undefined);

        // check and set planOfCareTypeId

        if (taskTemplate.hasPlanOfCare && !$scope.patientOfficePOC) {
            return toaster.pop('warning', "Note that you have selected a template with a plan of care, but patient office does not have a plan of care");
        }

        if (taskTemplate.hasPlanOfCare && $scope.patientOfficePOC) {
            $scope.newPatientTaskModel.documents.push({
                id: 0,
                label: $scope.patientOfficePOC.name,
                isPlanOfCare: true
            });
            $scope.newPatientTaskModel.planOfCareTypeId = $scope.patientOfficePOC.planOfCareTypeId;
        }
    }

    function setPatientDraftOrFutureTaskData() {
        const data = angular.copy($scope.task);

        data.taskTemplate = $scope.patientTaskTemplates.find(taskTemplate => taskTemplate.id === data.taskTemplateId);

        data.startDate = new Date(data.startDate);
        data.dueDate = new Date(data.dueDate);
        data.initialDates = {
            from: data.startDate,
            to: data.dueDate
        }

        data.isDraft = false;

        $scope.newPatientTaskModel = data;

        $scope.newPatientTaskModel.repeatMonths = {
            id: $scope.newPatientTaskModel.repeatMonths
        };

        $scope.newPatientTaskModel.documents = data.documents
            .filter(documentId => documentId !== undefined)
            .map(documentId => $scope.documentsMultiSelectOptions.find(d => d.id === documentId));

        // check if he had plan of care upon task
        if ($scope.newPatientTaskModel.planOfCareTypeId) {
            $scope.newPatientTaskModel.documents.push({id: 0});
        }

        // set filtered caregivers
        setFilteredCaregiversMapByCertifications($scope.newPatientTaskModel.taskTemplate.allowedCertifications);
    }

    $scope.newPatientTaskDocumentsEvents = {
        onItemSelect: (item) => {
            // check whether it is plan of care, and set planOfCareTypeId
            const selectedDocument = $scope.documentsMultiSelectOptions.find(d => d.id === item.id);
            if (selectedDocument && selectedDocument.isPlanOfCare) {
                $scope.newPatientTaskModel.planOfCareTypeId = $scope.planOfCare.planOfCareTypeId;
            }
        },

        onItemDeselect: (item) => {
            // check whether it is plan of care, and reset planOfCareTypeId
            const selectedDocument = $scope.documentsMultiSelectOptions.find(d => d.id === item.id);
            if (selectedDocument && selectedDocument.isPlanOfCare) {
                $scope.newPatientTaskModel.planOfCareTypeId = null
            }
        }
    }

    $scope.hadToChooseCertificationPeriod = ()=> $rootScope.visitSettings.requireCms485CertificationPeriod
        && !$scope.formData.selectedCms485CertificationPeriodId
        && $scope.isCms485Selected();

    $scope.submitForm = function (options) {
        const isBroadcasting = Boolean(options.radius);
        const documents = $scope.newPatientTaskModel.documents
                            .map(doc => doc.id)
                            .filter(docId => docId !== 0 && docId !== undefined);
        const scheduledDateTime = $scope.formData.scheduledDateTime.data ? {
            scheduledDateTime: dateUtils.dateToLocalDateTime($scope.formData.scheduledDateTime.data.startTime.toDate()),
            duration: $scope.formData.scheduledDateTime.data.totalDurationInMinutes
        } : null;

        if (!$scope.formData.serviceCode.id || !$scope.formData.contract.id) {
            toaster.pop('error', "Invalid Input", "Must choose contract and service code");
            return;
        }

        if (!$scope.newPatientTaskModel.taskTemplate.id) {
            toaster.pop('error', "Invalid Input", "No template was chosen");
            return;
        }

        if (documents.length === 0 && !$scope.newPatientTaskModel.planOfCareTypeId) {
            toaster.pop('error', "Invalid Input", "No documents selected");
            return;
        }

        if ($scope.task && ["FutureTask", "RegularTask"].includes($scope.task.type) && !$scope.formData.caregiver && !isBroadcasting) {
            toaster.pop('error', "Invalid Input", "Must assign caregiver, or broadcast instead");
            return;
        }

        if ($scope.hadToChooseCertificationPeriod()) {
            toaster.pop('error', "Invalid Input", "Please select the certification period for the document");
            $scope.showRequirements = true;
            return;
        }

        const body = {
            serviceCodeId: $scope.formData.serviceCode.id,
            payrollCodeId: !$scope.formData.payrollCode.id || $scope.formData.payrollCode.id === -1 ? null : $scope.formData.payrollCode.id,
            patientContractId: $scope.formData.contract.id,
            isBroadcasting,
            broadcastDistance: options.radius ? options.radius * 1609 : null, // miles to meters
            patientId: $scope.patient.id,
            caregiverId: $scope.formData.caregiver ? $scope.formData.caregiver.id : null,
            taskTemplateId: $scope.newPatientTaskModel.taskTemplate.id,
            title: $scope.newPatientTaskModel.title,
            startDate: LocalDate.from(nativeJs($scope.formData.datePicker.date.startDate)),
            dueDate: LocalDate.from(nativeJs($scope.formData.datePicker.date.endDate)),
            dayTimeParams: $scope.customizeDaysTimes ? buildDayTimeParamsRequest() : null,
            documents,
            repeatMonths: $scope.newPatientTaskModel.repeatMonths.id || null,
            planOfCareTypeId: $scope.newPatientTaskModel.planOfCareTypeId || null,
            isDraft: $scope.newPatientTaskModel.isDraft, 
            certificationPeriodId: $scope.isCms485Selected() ? $scope.formData.selectedCms485CertificationPeriodId : null,
            isFutureAssigned: Boolean($scope.isFutureAssignedEnabled() && $scope.isFutureAssigned),
            scheduledDateTime: scheduledDateTime
        };

        if ($scope.type !== null) {
            if ($scope.task.state === "Assigned") {
                updateAssignedTaskInstance($scope.task, options);
            } else if (!$scope.formData.caregiver && !body.isBroadcasting) {
                openSaveAsDraftModal(body);
            } else {
                updateDraftOrFuturePatientTask(body);
            }
        } else {
            createNewPatientTask(body);
        }
    }

    $scope.handleClickIsFutureAssigned = () => {
        $scope.isFutureAssigned = !$scope.isFutureAssigned;
    };

    function buildDayTimeParamsRequest() {
        const result = [];

        for (const [day, isActive] of Object.entries($scope.formData.daysOfTheWeek)) {
            if (isActive) {
                const shift = getShiftDetails(day);
                result.push({
                    dayOfWeek: day.toUpperCase(),
                    startTime: LocalTime.from(nativeJs(shift.startTime)),
                    endTime: LocalTime.from(nativeJs(shift.endTime)),
                });
            }
        }

        return result;
    }

    function getShiftDetails(day) {
        const key = $scope.formData.isDifferentDayTimes ? day : "Everyday";
        return $scope.formData.advancedTimeShiftsPermanent[0][key];
    }

    function createNewPatientTask(body) {
        const url = (
          $scope.certificationPeriod
            ? "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/certification_periods/:certificationPeriodId/assign_task"
            : "agencies/:agencyId/agency_members/:agencyMemberId/patient_task"
        )
          .replace(":agencyId", $rootScope.agencyId)
          .replace(":agencyMemberId", $rootScope.agencyMemberId)
          .replace(":patientId", $scope.patient.id)
          .replace(
            ":certificationPeriodId",
            $scope.certificationPeriod ? $scope.certificationPeriod.id : ""
          );
        
        $scope.isSaving = true;

        DatabaseApi.post(url, body).then((res) => {
            toaster.pop('success', "Patient Task created successfully");
            $scope.closeModal(res.data.task);
            $rootScope.$emit('patient_task_saved');
            if (res.data.task.scheduleDateTime !== null) {
                $rootScope.$emit("refresh_visits");
            }
        }).catch(() => {
            $scope.isSaving = false;
            toaster.pop('error', "Something went wrong", "Failed to create patient task");
        });
    }

    function openSaveAsDraftModal(body) {
        SweetAlert.swal({
            title: "Save as draft?",
            text: "You didnt assign any caregiver for this task, save anyway?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3077EB",
            confirmButtonText: "Yes, save as draft",
            closeOnConfirm: true,
            closeOnCancel: true
        }, function (isConfirm) {
            if (isConfirm) {
                body.isDraft = true;
                updateDraftOrFuturePatientTask(body);
            }
        });
    }

    function updateDraftOrFuturePatientTask(body) {
        let url = 'agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId
            + '/patient_tasks/' + $scope.task.taskId;
        switch ($scope.task.type) {
            case "FutureTask": {
                url += "/assign";
                break;
            }

            default: break;
        }

        body.type = $scope.task.type;

        $scope.isSaving = true;

        DatabaseApi.put(url, body).then(function (res) {
            toaster.pop('success', "Patient Task assigned successfully");
            $scope.closeModal(res.data.task);
            $rootScope.$emit('patient_task_saved');
        }).catch(() => {
            toaster.pop('error', "Something went wrong", "Failed to assign patient task");
            $scope.isSaving = false;
        });
    }

    function updateAssignedTaskInstance(task, options) {
        const isBroadcasting = Boolean(options.radius);

        const url = wildcard(
            "agencies/:agencyId/agency_members/:agencyMemberId/patient_task_instances/:patientTaskInstanceId",
            $rootScope.agencyId,
            $rootScope.agencyMemberId,
            task.taskInstanceId,
        );

        const scheduledDateTime = $scope.formData.scheduledDateTime.data ? {
            scheduledDateTime: dateUtils.dateToLocalDateTime($scope.formData.scheduledDateTime.data.startTime.toDate()),
            duration: $scope.formData.scheduledDateTime.data.totalDurationInMinutes
        } : null;

        const body = {
            type: $scope.formData.assignedTaskEditType,
            caregiverId: isBroadcasting ? null : $scope.formData.caregiver.id,
            title: $scope.newPatientTaskModel.title,
            startDate: LocalDate.from(nativeJs($scope.formData.datePicker.date.startDate)),
            dueDate: LocalDate.from(nativeJs($scope.formData.datePicker.date.endDate)),
            planOfCareTypeId: $scope.newPatientTaskModel.planOfCareTypeId || null,
            documents: $scope.newPatientTaskModel.documents.map(({id}) => id).filter(id => Boolean(id)),
            isBroadcasting,
            broadcastDistance: options.radius ? options.radius * 1609 : null, // miles to meters
            serviceCodeId: $scope.formData.serviceCode.id,
            payrollCodeId: !$scope.formData.payrollCode.id || $scope.formData.payrollCode.id === -1 ? null : $scope.formData.payrollCode.id,
            patientContractId: $scope.formData.contract.id,
            dayTimeParams: $scope.customizeDaysTimes ? buildDayTimeParamsRequest() : null,
            repeatMonths: $scope.newPatientTaskModel.repeatMonths.id || null,
            scheduledDateTime: scheduledDateTime,
            certificationPeriodId: $scope.isCms485Selected() ? $scope.formData.selectedCms485CertificationPeriodId : null
        };

        $scope.isSaving = !isBroadcasting;
        $scope.isBroadcasting = isBroadcasting;

        DatabaseApi.put(url, body).then(function (res) {
            toaster.pop('success', "Patient Task updated successfully");
            $scope.closeModal(res.data.task);
            $rootScope.$emit('patient_task_saved');
            if ($scope.task.scheduleDateTime !== null || body.scheduledDateTime) {
                $rootScope.$emit("refresh_visits");
            }
        }).catch(() => {
            toaster.pop('error', "Something went wrong", "Failed to update patient task");
            $scope.isSaving = false;
            $scope.isBroadcasting = false;
        });
    }

    const getDurationLabel = (duration) => {
        if (duration <= 0) duration.add(1, 'days');

        let hours = duration.get('hours');

        if (duration.get('days')) {
            hours += 24;
        }

        let minutes = duration.get('minutes');

        if (duration.get('seconds') > 30) {
            minutes++;

            if (minutes === 60) {
                hours++;
                minutes = 0;
            }
        }

        if (minutes) return hours + ':' + minutes + 'h';
        if (hours) return hours + 'h';

        return '0h';
    }

    $scope.advancedDays = visitConsts.advancedDays;

    $scope.applyShiftTimesToAll = (selectedShift) => {
        const startTime = selectedShift.startTime;
        const endTime = selectedShift.endTime;

        $scope.formData.advancedTimeShiftsPermanent.forEach((shift, shiftId) => {
            if (shiftId >= $scope.formData.shifts) {
                return;
            }
            const newStartTime = startTime ? moment().hours(startTime.hours()).minutes(startTime.minutes()) : null;
            const newEndTime = endTime ? moment().hours(endTime.hours()).minutes(endTime.minutes()) : null;
            Object.keys(shift).forEach(dayFullName => {
                if ($scope.formData.daysOfTheWeek[dayFullName]) {
                    $scope.handleUpdateTimesData(shiftId, dayFullName, "startTime", newStartTime);
                    $scope.handleUpdateTimesData(shiftId, dayFullName, "endTime", newEndTime);
                }
            });
        });
    };

    $scope.handleUpdateTimesData = (shiftId, dayName, key, newVal) => {
        if (
            !$scope.formData.advancedTimeShiftsPermanent ||
            !$scope.formData.advancedTimeShiftsPermanent[shiftId] ||
            !$scope.formData.advancedTimeShiftsPermanent[shiftId][dayName] ||
            $scope.formData.advancedTimeShiftsPermanent[shiftId][dayName][key] === undefined
        ) {
            return;
        }
        const day = angular.copy($scope.formData.advancedTimeShiftsPermanent[shiftId][dayName]);
        day[key] = newVal;
        const start = day.startTime;
        const end = day.endTime;
        const startMinutes = start.hour() * 60 + start.minute();
        const endMinutes = end.hour() * 60 + end.minute();
        let isOverNight = false;
        let totalMinutes = endMinutes - startMinutes;
        if (totalMinutes < 0) {
            totalMinutes += 24 * 60;
            isOverNight = true;
        }
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes - hours * 60;
        const hoursStr = hours > 0 ? `${hours}H` : '';
        const minutesStr = minutes > 0 ? `${minutes}M` : '';
        const durationStr = `${hoursStr}${hoursStr !== '' && minutesStr !== '' ? ':' : ''}${minutesStr}`;

        day.isOverNight = isOverNight;
        day.duration = durationStr;
        day.durationHours = hours;
        day.durationMinutes = minutes;
        $scope.formData.advancedTimeShiftsPermanent[shiftId][dayName] = day;
    };

    const getDateCopyAccordingToHisType = (dateObject) => {
        return moment.isMoment(dateObject) ? dateObject.clone().toDate() : angular.copy(dateObject);
    };

    $scope.handleUpdatePermanentDatePicker = () => {
        // This is done because angular dateRangePicker sets start/end date as moments for the 2nd time
        $scope.formData.scheduledDateTime.settings.startDate = getDateCopyAccordingToHisType($scope.formData.datePicker.date.startDate);
        $scope.formData.scheduledDateTime.settings.endDate = getDateCopyAccordingToHisType($scope.formData.datePicker.date.endDate);
    };

    $scope.handleScheduledTimeChange = (data) => {
        $scope.formData.scheduledDateTime.data = data;
    };

    $scope.handleUnscheduleCaregiverFromTask = () => {
        $scope.formData.scheduledDateTime.data = null;
        $scope.$broadcast("cleanSelection");
    };

    $scope.closeModal = function (data) {
        $uibModalInstance.close(data);
    };

    $scope.$watch('formData.caregiver', function () {
        if ($scope.formData.caregiver === null) {
            $scope.$broadcast("cleanSelection");
        }
    }, true);

    $scope.toggleAllDays = false;

    const getPatientDocuments = () => {
        return DatabaseApi.patientDocumentTypes().filter((form) => {
            return form.versions.filter(ver => ver.isPublished).length !== 0;
        });
    };

    $scope.isCms485Selected = () => {
        if (!$scope.newPatientTaskModel.taskTemplate.id) {
            return false;
        }

        const selected = $scope.newPatientTaskModel.documents;
        const selectedIds = selected.map(({ id }) => id);
        const cms485Documents = getPatientDocuments().filter(document =>
            selectedIds.indexOf(document.id) > -1 && document.type === "CMS485"
        );

        return cms485Documents.length > 0;
    };


    initialize();
};
