import {
  Button,
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Heading,
  Tab,
  TabList,
  Tabs,
  useDisclosure,
  UseDisclosureReturn,
  Wrap,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { uniqBy } from "lodash";
import React from "react";
import { match } from "ts-pattern";
import { Messages, QueryParamsOf } from "../../../../core/api";
import RangeDatePicker from "../../../../shared/components/DatePicker/RangeDatePicker";
import MultiSelectMenu from "../../../../shared/components/MultiSelectMenu";
import Page from "../../../../shared/components/Page";
import { caregiverStatus } from "../../../../shared/consts/caregiver-status";
import { createFilters } from "../../../../shared/hooks/useFilters";
import AddRoundedIcon from "../../../../shared/icons/AddRoundedIcon";
import {
  AgencyMemberId,
  CommCenterLabelId,
  CommCenterTicketId,
} from "../../../../shared/schema/schema";
import NewTicket from "../../components/NewTicket";
import TicketsStats from "../../components/TicketsStats";
import TicketsTable from "../../components/TicketsTable";
import { NewTicketRequestBody } from "../../utils/communication-utils";
import DialpadIcon from "../../../../shared/icons/DialpadIcon";
import { FilterProps } from "../../../../shared/utils/filter-props";
import useAuthData from "../../../../shared/hooks/useAuthInfo";
import DialerTabs from "../../components/DialerTabs";
import { defaultDialerPadContext } from "../../hooks/useDialerTabsContext";
import useLocalFilters from "../../../../shared/hooks/useLocalFilters";
import { CommCenterLocalFilters } from "./OpenCommunicationsRoute";
import FreeTextSearch from "../../../../shared/components/FreeTextSearch";

const indexToTab: Record<number, "Unassigned" | "My" | "MyTeam" | "EntireAgency"> = {
  0: "Unassigned",
  1: "My",
  2: "MyTeam",
  3: "EntireAgency",
};

const tabToIndex: Record<"Unassigned" | "My" | "MyTeam" | "EntireAgency", number> = {
  Unassigned: 0,
  My: 1,
  MyTeam: 2,
  EntireAgency: 3,
};

interface Props extends FilterProps<"./comm_center/tickets"> {
  tickets: Messages["CommCenterTicket"][];
  teams: Messages["CommCenterTeamWithMembers"][];
  stages: Messages["HRSectionStage"][];
  labels: Messages["CommCenterLabel"][];
  ticketSources: Messages["CommCenterTicketSourceLabel"][];
  initialLabelId: CommCenterLabelId | null;
  stats: {
    unassigned: number;
    myTickets: number;
    myTeamTicket: number;
    allTickets: number;
  };
  isRefetching: boolean;
  liveCallTicketIds: CommCenterTicketId[];
  isCalling: boolean;
  dialDisclosure: UseDisclosureReturn;
  localFiltersParams: ReturnType<
    typeof useLocalFilters<Messages["CommCenterTicket"], CommCenterLocalFilters>
  >;
  onClickDial: (E164number: string) => void;
  onClickAgencyMember: (agencyMemberId: AgencyMemberId) => void;
  onCreateNewTicket: (newTicket: NewTicketRequestBody) => void;
}

const OpenCommunicationsPage = (props: Props) => {
  const createTicketDisclosure = useDisclosure();
  const { agencyMember } = useAuthData();

  const [, setLocalFilter, localFilters] = props.localFiltersParams;

  const { createMultiSelectFilter, createRangeDatePickerFilter } =
    createFilters<QueryParamsOf<"get", "./comm_center/tickets">>();

  const fromToFilter = createRangeDatePickerFilter({
    label: "Date from to",
    startDate: {
      name: "createdAtFrom",
      value: props.filters.getValueOrNull("createdAtFrom"),
    },
    endDate: {
      name: "createdAtTo",
      value: props.filters.getValueOrNull("createdAtTo"),
    },
    onChange: props.onChangeFilter,
  });

  const caregiverStatusFilter = createMultiSelectFilter({
    name: "caregiverStatus",
    label: "Caregiver status",
    options: caregiverStatus.dropdown,
    value: props.filters.getValueOrNull("caregiverStatus"),
    onChange: props.onChangeFilter,
  });

  const ticketStatusFilter = createMultiSelectFilter({
    name: "ticketStatus",
    label: "Ticket status",
    options: [
      { label: "New", value: "NEW" },
      { label: "In progress", value: "IN PROGRESS" },
      { label: "Resolved", value: "RESOLVED" },
    ],
    value: props.filters.getValueOrNull("ticketStatus"),
    onChange: props.onChangeFilter,
  });

  const onboardingStageFilter = createMultiSelectFilter({
    name: "onboardingStage",
    label: "Onboarding stage",
    options: props.stages
      .map((stage) => ({
        label: `${stage.certification} - ${stage.label}`,
        value: stage.id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)),
    value: props.filters.getValueOrNull("onboardingStage"),
    onChange: props.onChangeFilter,
  });

  const priorityFilter = createMultiSelectFilter({
    name: "priority",
    label: "Priority",
    options: [
      { label: "Low", value: "LOW" },
      { label: "Medium", value: "MEDIUM" },
      { label: "High", value: "HIGH" },
    ],
    value: props.filters.getValueOrNull("priority"),
    onChange: props.onChangeFilter,
  });

  const labelFilter = createMultiSelectFilter({
    name: "labelId",
    label: "Label",
    options: props.labels
      .map((label) => ({ label: label.name, value: label.id }))
      .sort((a, b) => a.label.localeCompare(b.label)),
    value: props.filters.getValueOrNull("labelId"),
    onChange: props.onChangeFilter,
  });

  const assigneeFilter = createMultiSelectFilter({
    name: "assignee",
    label: "Assignee",
    options: uniqBy(
      props.teams
        .flatMap((team) => team.members)
        .map((member) => ({
          label: [member.firstName, member.lastName].join(" "),
          value: member.agencyMemberId,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
      (x) => x.value
    ),
    value: props.filters.getValueOrNull("assignee"),
    onChange: props.onChangeFilter,
  });

  const teamFilter = createMultiSelectFilter({
    name: "team",
    label: "Team",
    options: props.teams
      .map((team) => ({ label: team.name, value: team.id }))
      .sort((a, b) => a.label.localeCompare(b.label)),
    value: props.filters.getValueOrNull("team"),
    onChange: props.onChangeFilter,
  });

  const ticketSourceFilter = createMultiSelectFilter({
    name: "ticketSource",
    label: "Source",
    onChange: props.onChangeFilter,
    value: props.filters.getValueOrNull("ticketSource"),
    options: props.ticketSources.map((source) => ({
      label: source.label,
      value: source.source,
    })),
  });

  const getMyTeam = () => {
    return props.teams.find((team) =>
      team.members.some((member) => member.agencyMemberId === agencyMember.id)
    );
  };

  const handleChangeTabIndex = (index: number) => {
    const tab = index in indexToTab ? indexToTab[index] : "Unassigned";

    return match(tab)
      .with("Unassigned", () => {
        props.onChangeFilters({ unassigned: true, assignee: undefined, team: undefined });
      })
      .with("My", () => {
        props.onChangeFilters({
          unassigned: undefined,
          assignee: [agencyMember.id],
          team: undefined,
        });
      })
      .with("MyTeam", () => {
        const team = getMyTeam();

        if (team !== undefined) {
          props.onChangeFilters({
            unassigned: undefined,
            assignee: undefined,
            team: [team.id],
          });
        }
      })
      .with("EntireAgency", () => {
        props.onChangeFilters({
          unassigned: undefined,
          assignee: undefined,
          team: undefined,
        });
      })
      .exhaustive();
  };

  const getActiveTabIndex = () => {
    const assignee = props.filters.getValueOrNull("assignee");
    const team = props.filters.getValueOrNull("team");
    const unassigned = props.filters.getValueOrNull("unassigned");

    if (unassigned === true) {
      return tabToIndex["Unassigned"];
    }

    if (assignee !== null && assignee.length === 1 && assignee[0] === agencyMember.id) {
      return tabToIndex["My"];
    }

    if (team !== null && team.length === 1 && getMyTeam()?.id === team[0]) {
      return tabToIndex["MyTeam"];
    }

    return tabToIndex["EntireAgency"];
  };

  return (
    <Page isLoading={props.isRefetching}>
      <Page.Header>
        <Flex gap={8} wrap="wrap" justifyContent="space-between">
          <Heading size="lg">Open Conversations ({props.tickets.length})</Heading>
          <Tabs
            index={getActiveTabIndex()}
            onChange={handleChangeTabIndex}
            variant="soft-rounded"
            colorScheme="blue"
          >
            <TabList>
              <Wrap>
                <Tab>Unassigned Conversations ({props.stats.unassigned})</Tab>
                <Tab>My Conversations ({props.stats.myTickets})</Tab>
                <Tab>My Team Conversations ({props.stats.myTeamTicket})</Tab>
                <Tab>The entire agency ({props.stats.allTickets})</Tab>
              </Wrap>
            </TabList>
          </Tabs>
        </Flex>
      </Page.Header>

      <TicketsStats tickets={props.tickets} />

      <Page.Filters>
        <Flex direction="column" gap={3}>
          <Heading size={"sm"}>Filters</Heading>
          <Flex wrap="wrap" direction={"row"} gap={3}>
            <RangeDatePicker {...fromToFilter} />
            <MultiSelectMenu {...caregiverStatusFilter} />
            <MultiSelectMenu {...ticketStatusFilter} />
            <MultiSelectMenu {...onboardingStageFilter} />
            <MultiSelectMenu {...priorityFilter} />
            <MultiSelectMenu {...labelFilter} />
            <MultiSelectMenu {...assigneeFilter} />
            <MultiSelectMenu {...teamFilter} />
            <MultiSelectMenu {...ticketSourceFilter} />
            <FreeTextSearch
              inputProps={{ w: "auto" }}
              debounce={300}
              onChange={(e) => setLocalFilter("caregiver", e ?? "")}
              value={localFilters.caregiver.value}
              placeholder={"Caregiver Name, Id..."}
            />
            <Flex ms={"auto"} gap={2}>
              <Button
                colorScheme="blue"
                leftIcon={<AddRoundedIcon />}
                onClick={createTicketDisclosure.onOpen}
              >
                New
              </Button>
              <Button
                colorScheme={"blue"}
                leftIcon={<DialpadIcon />}
                onClick={props.dialDisclosure.onOpen}
              >
                Dial
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Page.Filters>

      <Divider />

      <Page.Content>
        <TicketsTable tickets={props.tickets} liveCallTicketIds={props.liveCallTicketIds} />
        <Drawer {...createTicketDisclosure} size="xl">
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerBody>
              <Center h="full">
                <NewTicket
                  labels={props.labels}
                  initialLabelId={props.initialLabelId}
                  defaultValues={{ caregiverId: null, patientId: null, topic: "Caregiver" }}
                  onCreateTicket={props.onCreateNewTicket}
                />
              </Center>
            </DrawerBody>
          </DrawerContent>
          <DrawerOverlay />
        </Drawer>
        <Drawer {...props.dialDisclosure} size="lg">
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerBody>
              <Center>
                <DialerTabs
                  flexProps={{
                    flex: "1",
                    alignItems: "center",
                    mt: 10,
                  }}
                  context={defaultDialerPadContext}
                  isCalling={props.isCalling}
                  onClickDial={props.onClickDial}
                  onClickAgencyMember={props.onClickAgencyMember}
                />
              </Center>
            </DrawerBody>
          </DrawerContent>
          <DrawerOverlay />
        </Drawer>
      </Page.Content>
    </Page>
  );
};

OpenCommunicationsPage.Root = styled(Page.Root)``;

export default OpenCommunicationsPage;
