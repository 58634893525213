import { LocalDate, LocalDateTime } from "@js-joda/core";
import React from "react";
import BaseDatePicker, { BaseDatePickerProps } from "./BaseDatePicker";
import { match } from "ts-pattern";
import { Button, Flex } from "@chakra-ui/react";
import styled from "@emotion/styled";
import useSingleDatePicker from "./useSingleDatePicker";

const Calendar = styled.div`
  display: flex;
  width: fit-content;
  gap: 12px;
`;

const Actions = styled.div`
  border-top: 1px solid var(--chakra-colors-gray-200);
  padding-top: 8px;
  margin-top: 8px;
`;

export interface SingleDatePickerProps
  extends Omit<BaseDatePickerProps, "monthsShown" | "onChange"> {
  onChange: (
    date: LocalDate | null,
    event: React.SyntheticEvent<unknown, Event> | undefined
  ) => void;
}

const SingleDatePicker = (props: SingleDatePickerProps) => {
  const { pickerProps } = useSingleDatePicker();

  const handleChange = (
    date: LocalDateTime | [LocalDate, LocalDate | null] | null,
    event: React.SyntheticEvent<unknown, Event> | undefined
  ) => {
    if (Array.isArray(date)) {
      throw new Error("SingleDatePicker.onChange should not return a range value");
    }

    if (date instanceof LocalDateTime || date === null) {
      const localDate =
        date === null ? null : LocalDate.of(date.year(), date.month(), date.dayOfMonth());
      return props.onChange(localDate, event);
    }

    match(date).exhaustive();
  };

  const handleSetClear = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    return props.onChange(null, e);
  };

  const handleSetToday = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    return props.onChange(LocalDate.now(), e);
  };

  return (
    <BaseDatePicker
      {...props}
      {...pickerProps}
      monthsShown={1}
      onChange={handleChange}
      calendarContainer={({ children }) => {
        return (
          <>
            <Calendar>{children}</Calendar>
            <Actions>
              <Flex alignItems="center" justifyContent="space-between" gap={8}>
                <Button variant="text" size="sm" color="negative" onClick={handleSetClear}>
                  Clear
                </Button>
                <Button variant="text" size="sm" onClick={handleSetToday}>
                  Today
                </Button>
              </Flex>
            </Actions>
          </>
        );
      }}
    />
  );
};

export default SingleDatePicker;
