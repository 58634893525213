import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  List,
  ListItem,
  Select,
  Spinner,
  Text,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Messages } from "../../../core/api";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { zj } from "zod-joda";
import { assertObjectPropsNonNulls, fmap } from "../../../shared/utils";
import useGoogleMapsAddress from "../../../shared/hooks/useGoogleMapsAddress";
import { fromPlaceId, toMfAddressComponents } from "mf-google-geocoder";
import { GOOGLE_MAPS_API_KEY } from "../../../shared/consts";
import SingleDatePicker from "../../../shared/components/DatePicker/SingleDatePicker";
import PhoneNumberInput from "../../../shared/components/PhoneNumberInput";
import { dateFormatter } from "../../../shared/utils/date-formatter";
import { isValidNumber } from "../../../shared/utils/phone-number";
import { LocalDate } from "@js-joda/core";

const Location = z.object({
  lat: z.number(),
  lng: z.number(),
});

const FormatedAddressDetails = z.object({
  location: Location,
  address1: z.string(),
  address2: z.string().nullish(),
  country: z.string(),
  state: z.string(),
  county: z.string(),
  fullAddress: z.string(),
  city: z.string().nullish(),
  zip5: z.string().nullish(),
  zip4: z.string().nullish(),
  street: z.string().nullish(),
  streetNumber: z.string().nullish(),
  crossStreet: z.string().nullish(),
});

const AddressComponent = z.object({
  location: Location,
  country: z.string(),
  administrativeAreaLevel1: z.string(),
  administrativeAreaLevel2: z.string(),
  sublocalityLevel1: z.string().nullish(),
  neighborhood: z.string().nullish(),
  locality: z.string().nullish(),
  formatedAddressDetails: FormatedAddressDetails.optional(),
});

const PatientAddressComponentsSchema = z.object({
  text: z.string().min(1),
  timezone: z.string().min(1),
  components: AddressComponent,
});

const emptyStringTransform = <T extends string>(val: T | null) => (val?.trim() === "" ? null : val);

const schema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  phoneNumber: z.string().min(1).refine(isValidNumber),
  address2: z.string().nullable().transform(emptyStringTransform),
  middleName: z.string().nullable().transform(emptyStringTransform),
  medicaidId: z.string().nullable().transform(emptyStringTransform),
  familyFirstName: z.string().nullable().transform(emptyStringTransform),
  familyLastName: z.string().nullable().transform(emptyStringTransform),
  familyRelationship: z.string().nullable().transform(emptyStringTransform),
  familyPhoneNumber: z
    .string()
    .nullable()
    .transform(emptyStringTransform)
    .refine((a) => a === null || isValidNumber(a)),
  dateOfBirth: zj.localDate().nullable(),
  gender: z.enum(["M", "F"]).nullable().transform(emptyStringTransform),
  addressComponents: PatientAddressComponentsSchema.nullable(),
  address: z.string().nullable(),
});

type FormSchemaData = z.infer<typeof schema>;

interface Props {
  onCreateNewIntakePatient: (
    data: FormSchemaData & { familyInformation: Messages["PatientFamilyInformation"] | null }
  ) => void;
}

const NewIntakePatient = (props: Props) => {
  const { register, control, handleSubmit, setValue, formState } = useForm<FormSchemaData>({
    resolver: zodResolver(schema),
    defaultValues: {
      address: null,
      address2: null,
      addressComponents: null,
      dateOfBirth: null,
      gender: null,
      medicaidId: null,
      middleName: null,
      phoneNumber: "",
      familyPhoneNumber: null,
      familyFirstName: null,
      familyLastName: null,
      familyRelationship: null,
      firstName: "",
      lastName: "",
    },
  });

  const {
    handleChange: handleChangeAddress,
    placePredictions,
    getDetails: getAddressDetails,
    showPredictions,
    setShowPredictions,
  } = useGoogleMapsAddress();

  const onSubmit: SubmitHandler<FormSchemaData> = (data) => {
    const formattedData: FormSchemaData & {
      familyInformation: Messages["PatientFamilyInformation"] | null;
    } = {
      ...data,
      familyInformation: assertObjectPropsNonNulls({
        firstName: data.familyFirstName,
        lastName: data.familyLastName,
        relationship: data.familyRelationship,
        homePhoneNumber: data.familyPhoneNumber,
        mobilePhoneNumber: data.familyPhoneNumber,
      }),
    };

    props.onCreateNewIntakePatient(formattedData);
  };

  const handleClickAddressPrediction = async (
    prediction: google.maps.places.AutocompletePrediction
  ) => {
    setShowPredictions(false);
    setValue("address", null);
    setValue("addressComponents", null);
    try {
      const result = await getAddressDetails(prediction);
      const placeId = result?.place_id;

      if (placeId === undefined) {
        return;
      }

      const addressDetails = await fromPlaceId(placeId, {
        apiKey: GOOGLE_MAPS_API_KEY,
        mfAutoFix: true,
      });
      setValue("addressComponents", {
        components: toMfAddressComponents(addressDetails),
        text: addressDetails.fullAddress,
        timezone: "America/New_York",
      });
      setValue("address", prediction.description);
    } catch (error) {
      console.error("Error getting address details", error);
      setValue("address", null);
      setValue("addressComponents", null);
    }
  };

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeAddress(event);
    setValue("addressComponents", null);
  };

  return (
    <Flex direction="column" w="full" bg="white" gap={2}>
      <Heading fontSize={"md"}>General Information</Heading>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="do-not-autofill">
        <Flex direction={"column"} gap={5} justifyContent="space-around">
          <FormPair>
            <FormControl isRequired>
              <FormLabel>First Name</FormLabel>
              <Input autoComplete="new-pasword" {...register("firstName")} isRequired />
              <Text color={"red"}>{formState.errors.firstName?.message ?? null}</Text>
            </FormControl>
            <FormControl>
              <FormLabel>Middle Name</FormLabel>
              <Input autoComplete="new-pasword" {...register("middleName")} />
              <Text color={"red"}>{formState.errors.middleName?.message ?? null}</Text>
            </FormControl>
          </FormPair>
          <FormPair>
            <FormControl isRequired>
              <FormLabel>Last Name</FormLabel>
              <Input autoComplete="new-pasword" {...register("lastName")} isRequired />
              <Text color={"red"}>{formState.errors.lastName?.message ?? null}</Text>
            </FormControl>
            <FormControl>
              <FormLabel>Gender</FormLabel>
              <Select {...register("gender")} defaultValue="">
                <option value={""} disabled>
                  Select
                </option>
                <option value={"M"}>Male</option>
                <option value={"F"}>Female</option>
              </Select>
              <Text color={"red"}>{formState.errors.gender?.message ?? null}</Text>
            </FormControl>
          </FormPair>
          <FormPair>
            <FormControl isRequired>
              <FormLabel>Phone Number</FormLabel>
              <Controller
                render={({ field }) => (
                  <InputGroup>
                    <InputLeftAddon children="+1" />
                    <PhoneNumberInput
                      autoComplete="new-pasword"
                      borderLeftRadius={"0px"}
                      {...field}
                    />
                  </InputGroup>
                )}
                control={control}
                name="phoneNumber"
              />
              <Text color={"red"}>{formState.errors.phoneNumber?.message ?? null}</Text>
            </FormControl>
            <FormControl>
              <FormLabel>Date Of Birth</FormLabel>
              <Controller
                control={control}
                name="dateOfBirth"
                render={(field) => (
                  <SingleDatePicker
                    {...field}
                    pickerYears={[1910, LocalDate.now().year()]}
                    autoComplete="new-pasword"
                    selected={field.field.value}
                    value={fmap(field.field.value, dateFormatter.toDate) ?? undefined}
                    onChange={(date) => {
                      setValue("dateOfBirth", date);
                    }}
                  />
                )}
              />
              <Text color={"red"}>{formState.errors.dateOfBirth?.message ?? null}</Text>
            </FormControl>
          </FormPair>
          <FormPair>
            <FormControl>
              <FormLabel>Address</FormLabel>
              <Input
                autoComplete="new-pasword"
                {...register("address", {
                  onChange: handleAddressChange,
                })}
              />
              {showPredictions ? (
                <StylePredisctionList
                  placePredictions={placePredictions}
                  onClickAddressPrediction={handleClickAddressPrediction}
                />
              ) : null}
              <Text color={"red"}>{formState.errors.addressComponents?.message ?? null}</Text>
            </FormControl>
          </FormPair>
          <FormPair>
            <FormControl>
              <FormLabel>Address 2</FormLabel>
              <Input type="text" {...register("address2")} />
            </FormControl>
            <FormControl>
              <FormLabel>Medicaid ID</FormLabel>
              <Input autoComplete="new-pasword" {...register("medicaidId")} />
            </FormControl>
          </FormPair>
          <Heading fontSize={"md"}>Additional Contact Information</Heading>
          <FormPair>
            <FormControl>
              <FormLabel>First Name</FormLabel>
              <Input autoComplete="new-pasword" {...register("familyFirstName")} />
              <Text color={"red"}>{formState.errors.familyFirstName?.message ?? null}</Text>
            </FormControl>
            <FormControl>
              <FormLabel>Last Name</FormLabel>
              <Input autoComplete="new-pasword" {...register("familyLastName")} />
              <Text color={"red"}>{formState.errors.familyLastName?.message ?? null}</Text>
            </FormControl>
          </FormPair>
          <FormPair>
            <FormControl>
              <FormLabel>Phone Number</FormLabel>
              <Controller
                render={({ field }) => (
                  <InputGroup>
                    <InputLeftAddon children="+1" />
                    <PhoneNumberInput
                      autoComplete="new-pasword"
                      borderLeftRadius={"0px"}
                      {...field}
                      value={field.value ?? ""}
                    />
                  </InputGroup>
                )}
                control={control}
                name="familyPhoneNumber"
              />
              <Text color={"red"}>{formState.errors.familyPhoneNumber?.message ?? null}</Text>
            </FormControl>
            <FormControl>
              <FormLabel>Relationship</FormLabel>
              <Select {...register("familyRelationship")}>
                <option value="Son">Son</option>
                <option value="Daughter">Daughter</option>
                <option value="Wife">Wife</option>
                <option value="Husband">Husband</option>
                <option value="Grandchild">Grandchild</option>
                <option value="Brother">Brother</option>
                <option value="Sister">Sister</option>
                <option value="Mother">Mother</option>
                <option value="Father">Father</option>
                <option value="Mother-in-law">Mother-in-law</option>
                <option value="Father-in-law">Father-in-law</option>
                <option value="Legal guardian">Legal guardian</option>
                <option value="Other">Other</option>
              </Select>
            </FormControl>
          </FormPair>
          <FormControl>
            <Button isDisabled={formState.isSubmitting} type="submit">
              {formState.isSubmitting ? <Spinner size="sm" /> : "Save"}
            </Button>
          </FormControl>
        </Flex>
      </form>
    </Flex>
  );
};

const StylePredisctionList = (props: {
  placePredictions: google.maps.places.AutocompletePrediction[];
  onClickAddressPrediction: (
    prediction: google.maps.places.AutocompletePrediction
  ) => Promise<void>;
}) => (
  <Box
    padding={3}
    border="1px"
    borderColor={"chakra-border-color"}
    position={"fixed"}
    bg="white"
    zIndex={1}
    width="xl"
  >
    <List display="flex" gap={4} flexDirection="column">
      {props.placePredictions.map((prediction) => (
        <ListItem
          borderBottom={"1px"}
          borderColor={"chakra-border-color"}
          key={prediction.place_id}
          onClick={() => props.onClickAddressPrediction(prediction)}
          cursor={"pointer"}
        >
          {prediction.description}
        </ListItem>
      ))}
    </List>
  </Box>
);

const FormPair = (props: { children: ReactNode }) => {
  return (
    <Flex direction={"row"} gap={20} justifyContent="space-between">
      {props.children}
    </Flex>
  );
};
export default NewIntakePatient;
