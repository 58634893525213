import { Flex, Heading } from "@chakra-ui/react";
import React from "react";
import { z } from "zod";
import { phoneFormatter } from "../../../shared/utils/phone-formatter";
import IntakePatientProfileDropdown from "./IntakePatientProfileDropdown";
import IntakePatientProfileInput from "./IntakePatientProfileInput";

const relationshipsOptions = [
  null,
  "Father",
  "Mother",
  "Son",
  "Daughter",
  "Brother",
  "Sister",
  "Wife",
  "Husband",
  "Grandchild",
  "Mother-in-law",
  "Father-in-law",
  "Legal guardian",
  "Other",
];

const phoneNumberSchema = z
  .string()
  .min(10, { message: "Phone number is too short" })
  .max(15, { message: "Phone number is too long" });

type props = {
  isEditMode: boolean;
  patientPhoneNumber: string;
  emergencyContactInfo: {
    homePhoneNumber: string;
    mobilePhoneNumber: string;
    firstName: string;
    lastName: string;
    relationship: string;
  };
  onChangeEmergencyContact: (
    field: "firstName" | "lastName" | "mobilePhoneNumber" | "homePhoneNumber" | "relationship",
    value: string
  ) => void;
  onChangePatientPhoneNumber: (value: string) => void;
};

const IntakeProfileContactInfoSection = (props: props) => {
  return (
    <Flex direction="column" gap={4}>
      <Heading as="h1" size="md">
        {" "}
        Contact Information
      </Heading>
      <IntakePatientProfileInput
        isEditable={props.isEditMode}
        isRequired={true}
        minLength={10}
        maxLength={10}
        value={phoneFormatter.formatNational(props.patientPhoneNumber)}
        label="Patient phone number"
        validateSchema={phoneNumberSchema}
        onChange={(value) => {
          props.onChangePatientPhoneNumber(value);
        }}
      />
      <Heading as="h1" size="sm">
        Family Contact Info
      </Heading>
      <Flex direction="row" gap={4}>
        <IntakePatientProfileInput
          isEditable={props.isEditMode}
          isRequired={false}
          value={props.emergencyContactInfo.firstName}
          label="First name"
          onChange={(value) => {
            props.onChangeEmergencyContact("firstName", value);
          }}
        />
        <IntakePatientProfileInput
          isEditable={props.isEditMode}
          isRequired={false}
          value={props.emergencyContactInfo.lastName}
          label="Last name"
          onChange={(value) => {
            props.onChangeEmergencyContact("lastName", value);
          }}
        />
      </Flex>
      <Flex direction="row" gap={4}>
        <IntakePatientProfileInput
          isEditable={props.isEditMode}
          isRequired={false}
          value={phoneFormatter.formatNational(props.emergencyContactInfo.homePhoneNumber)}
          label="Home number"
          validateSchema={phoneNumberSchema}
          onChange={(value) => {
            props.onChangeEmergencyContact("homePhoneNumber", value);
          }}
        />
        <IntakePatientProfileInput
          isEditable={props.isEditMode}
          isRequired={false}
          value={phoneFormatter.formatNational(props.emergencyContactInfo.mobilePhoneNumber)}
          label="Mobile number"
          validateSchema={phoneNumberSchema}
          onChange={(value) => {
            props.onChangeEmergencyContact("mobilePhoneNumber", value);
          }}
        />
      </Flex>
      <IntakePatientProfileDropdown
        isEditable={props.isEditMode}
        isRequired={false}
        currValue={{
          label: props.emergencyContactInfo.relationship,
          value: props.emergencyContactInfo.relationship,
        }}
        options={relationshipsOptions.map((val) => ({
          label: val ?? "Unknown",
          value: val,
        }))}
        label="Relationship with the patient"
        onChange={(value) => {
          props.onChangeEmergencyContact("relationship", value ?? "");
        }}
      />
    </Flex>
  );
};

export default IntakeProfileContactInfoSection;
