import {
  Box,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  IconProps,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useInterval,
} from "@chakra-ui/react";
import { ChronoUnit, Duration, Instant } from "@js-joda/core";
import React, { useState } from "react";
import { Messages } from "../../../../core/api";
import { durationFormatter } from "../../../../shared/utils/duration-formatter";
import { phoneFormatter } from "../../../../shared/utils/phone-formatter";
import CallTicketEventCard from "./helpers/CallTicketEventCard/CallTicketEventCard";
import CallTicketParticipantCard from "../CallTicketParticipantCard";
import CallTicketColumn from "./helpers/CallTicketColumn";
import CallTicketRow from "./helpers/CallTicketRow";
import LiveBadge from "../LiveBadge";
import HeadphonesIcon from "../../../../shared/icons/HeadphonesIcon";
import BaselinePersonAddAlt1Icon from "../../../../shared/icons/BaselinePersonAddAlt1Icon";
import MicMuteFillIcon from "../../../../shared/icons/MicMuteFillIcon";
import DialpadIcon from "../../../../shared/icons/DialpadIcon";
import PhoneHangupIcon from "../../../../shared/icons/PhoneHangupIcon";
import ShareIcon from "../../../../shared/icons/ShareIcon";
import useActiveCall from "../../../../shared/hooks/useActiveCall";
import CallNotesSection from "./CallNotesSection";
import Dialpad from "../../../../shared/components/DialPad";
import useSingleDigitDialPad from "../../../../shared/hooks/useSingleDigitDialPad";
import useCallTicketNotes from "./hooks/useCallTicketNotes";
import CallNoteCard from "./CallNoteCard";
import { NoteId } from "../../../../shared/schema/schema";
import { sendDtmfTone } from "../../../../shared/utils/call-center";
import DialerTabs from "../DialerTabs";
import useDialerTabsContext from "../../hooks/useDialerTabsContext";

const ICON_PROPS: IconProps = {
  cursor: "var(--icon-cursor)",
  boxSize: "var(--icon-box-size)",
  color: "var(--icon-color)",
};

interface Props {
  activeTicket: Messages["CommCenterTicket"] & {
    callSessionDetails: Messages["CallSessionDetails"];
  };
  onClickHangup: () => void;
  notesSectionData: ReturnType<typeof useCallTicketNotes>;
}

const isEnterEvent = (
  x: Messages["CallSessionParticipantEvent"]
): x is Messages["CallSessionParticipantEvent"] & { data: Messages["Enter"] } =>
  x.data.eventStatus === "ENTER";

function getCallDurationSeconds(callSessionDetails: Messages["CallSessionDetails"]): number {
  if (callSessionDetails.sessionParticipantEvents.length === 0) {
    return 0;
  }
  const firstEventUntil = callSessionDetails.sessionParticipantEvents[0].createdAt.until(
    Instant.now(),
    ChronoUnit.SECONDS
  );
  if (callSessionDetails.direction === "OUTBOUND") {
    return firstEventUntil;
  }
  const firstEnterEvent = callSessionDetails.sessionParticipantEvents.find(isEnterEvent);
  if (firstEnterEvent !== undefined) {
    return firstEnterEvent.createdAt.until(Instant.now(), ChronoUnit.SECONDS);
  }
  return firstEventUntil;
}

const LiveCallTicket = (props: Props) => {
  const { activeCall } = useActiveCall();
  const singleDigitDialer = useDisclosure();
  const [durationSeconds, setDurationSeconds] = useState(
    getCallDurationSeconds(props.activeTicket.callSessionDetails)
  );
  const { context: dialerContext, setContext: setDialerContext } = useDialerTabsContext();
  const { state: singleDigitDialPadState } = useSingleDigitDialPad({
    onClickDigit: (digit, acc) => {
      console.log(`got new digit: ${digit}`);
      console.log(`accInput: ${acc}`);
      sendDtmfTone(digit);
    },
  });

  const dialerDisclosure = useDisclosure();

  const { callSessionDetails } = props.activeTicket;

  useInterval(() => {
    setDurationSeconds((prev) => {
      return prev + 1;
    });
  }, 1000);

  const isSessiongJoined = () => {
    return activeCall?.ticketId === props.activeTicket.id;
  };

  const handleClickHangup = () => {
    if (activeCall !== null) {
      props.onClickHangup();
    }
  };

  const handleClickDialer = () => {
    singleDigitDialer.onOpen();
  };

  const handleClickAddToCall = () => {
    setDialerContext({
      hideAgencyMembers: false,
      hideCaregivers: false,
      hidePatients: false,
      hidePhoneNumber: false,
    });
    dialerDisclosure.onOpen();
  };

  const handleClickTransferCall = () => {
    setDialerContext({
      hideAgencyMembers: false,
      hideCaregivers: true,
      hidePatients: true,
      hidePhoneNumber: false,
    });
    dialerDisclosure.onOpen();
  };

  return (
    <Flex
      direction="row"
      justifyContent="space-between"
      backgroundSize="60%"
      borderRadius="0 12px 12px 0"
      height="100%"
      gap={2}
      padding={2}
    >
      <CallTicketColumn>
        <CallTicketRow>
          <Flex py={"10px"} flexDirection={"column"} gap={"50px"}>
            <Flex flexDirection={"column"} gap={4} align={"center"}>
              <Text fontSize={"5xl"}>
                {durationFormatter.hours(Duration.ofSeconds(durationSeconds))}
              </Text>
              <Text>
                {phoneFormatter.isValidNumber(callSessionDetails.externalPhoneNumber)
                  ? phoneFormatter.formatNational(callSessionDetails.externalPhoneNumber)
                  : callSessionDetails.externalPhoneNumber}
              </Text>
              <LiveBadge />
            </Flex>
            <Box
              sx={{ "--icon-box-size": "40px", "--icon-color": "gray", "--icon-cursor": "pointer" }}
            >
              {isSessiongJoined() ? (
                <JoinedCallActions
                  onClickHangup={handleClickHangup}
                  onClickDialer={handleClickDialer}
                  onClickAddToCall={handleClickAddToCall}
                  onClickTransferCall={handleClickTransferCall}
                />
              ) : (
                <NotjoinedCallActions />
              )}
            </Box>
          </Flex>
        </CallTicketRow>
        <CallTicketRow>
          <CallTicketRow.Header>Call Notes</CallTicketRow.Header>
          <CallTicketRow.Content>
            <CallNotesSection {...props.notesSectionData} ticketId={props.activeTicket.id} />
            {callSessionDetails.sessionNotes.map((note) => (
              <CallNoteCard key={NoteId.unwrap(note.id).toString()} note={note} />
            ))}
          </CallTicketRow.Content>
        </CallTicketRow>
      </CallTicketColumn>
      <CallTicketColumn>
        <CallTicketRow>
          <CallTicketRow.Header>Call Parties</CallTicketRow.Header>
          <CallTicketRow.Content>
            {callSessionDetails.sessionParticipantRoles.map((role, i) => (
              <CallTicketRow.ContentRow key={`${role.id}`}>
                <CallTicketParticipantCard entity={role} index={i} />
              </CallTicketRow.ContentRow>
            ))}
          </CallTicketRow.Content>
        </CallTicketRow>
        <CallTicketRow>
          <CallTicketRow.Header>Call Events</CallTicketRow.Header>
          <CallTicketRow.Content>
            {callSessionDetails.sessionParticipantEvents.map((event) => (
              <CallTicketRow.ContentRow key={`${event.id}`}>
                <CallTicketEventCard event={event} />
              </CallTicketRow.ContentRow>
            ))}
          </CallTicketRow.Content>
        </CallTicketRow>
      </CallTicketColumn>

      <Modal isOpen={singleDigitDialer.isOpen} onClose={singleDigitDialer.onClose} isCentered>
        <ModalOverlay zIndex={10001} />
        <ModalContent containerProps={{ zIndex: 10001 }} display={"flex"} paddingY={6}>
          <ModalCloseButton />
          <Center as={Flex} flexDirection={"column"} h={"full"}>
            <ModalHeader>
              <Heading>Enter Input</Heading>
            </ModalHeader>
            <Dialpad state={singleDigitDialPadState} />
          </Center>
        </ModalContent>
      </Modal>
      <Drawer {...dialerDisclosure} size={"lg"}>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <DrawerHeader textAlign={"center"}>
              <Heading>Add To Call</Heading>
            </DrawerHeader>
            <DialerTabs
              context={dialerContext}
              isCalling={false}
              onClickDial={(number) => console.log(`add to call: ${number}`)}
              onClickAgencyMember={(id) => console.log(`add to call agency member id: ${id}`)}
            />
          </DrawerBody>
        </DrawerContent>
        <DrawerOverlay />
      </Drawer>
    </Flex>
  );
};

const JoinedCallActions = (actions: {
  onClickHangup: () => void;
  onClickDialer: () => void;
  onClickAddToCall: () => void;
  onClickTransferCall: () => void;
}) => {
  return (
    <Flex w={"full"} flexDirection={"column"} justifyContent={"center"} gap={2}>
      <Flex direction={"row"} justifyContent={"center"} gap={12}>
        <MicMuteFillIcon {...ICON_PROPS} />
        <Box></Box>
        <DialpadIcon {...ICON_PROPS} onClick={actions.onClickDialer} />
      </Flex>
      <Flex direction={"row"} justifyContent={"center"} gap={6}>
        <BaselinePersonAddAlt1Icon {...ICON_PROPS} onClick={actions.onClickAddToCall} />
        <PhoneHangupIcon {...ICON_PROPS} onClick={actions.onClickHangup} />
        <ShareIcon {...ICON_PROPS} onClick={actions.onClickTransferCall} />
      </Flex>
    </Flex>
  );
};

const NotjoinedCallActions = () => {
  return (
    <Flex w={"full"} flexDirection={"row"} justifyContent={"center"} gap={7}>
      <HeadphonesIcon {...ICON_PROPS} />
      <BaselinePersonAddAlt1Icon {...ICON_PROPS} />
    </Flex>
  );
};

export default LiveCallTicket;
