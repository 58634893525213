import React from "react";
import { createIcon } from "@chakra-ui/react";

const ShareIcon = createIcon({
  displayName: "ShareIcon",
  viewBox: "0 0 24 24",
  path: <path fill="currentColor" d="m21 12l-7-7v4C7 10 4 15 3 20c2.5-3.5 6-5.1 11-5.1V19l7-7Z" />,
});

export default ShareIcon;
