import { phoneFormatter } from "./phone-formatter";

export type CallSessionCallerType =
  | "NotIdentifiedPhoneNumber"
  | "NotIdentifiedPatient"
  | "NotIdentifiedCaregiver"
  | "Caregiver"
  | "Patient"
  | "NotIdentifiedInsuranceProvider";

const Cloudonix = (window as any).Cloudonix;

export function answerIncomingCall(
  destination: string,
  actions: {
    onSessionConnected: (res: any) => void;
    onSessionTerminated: (res: any) => void;
  }
) {
  let answerActionIndicator = false;
  let terminationActionIndicator = false;
  Cloudonix.sessionEvents.onSessionConnected((res: unknown) => {
    if (!answerActionIndicator) {
      answerActionIndicator = true;
      actions.onSessionConnected(res);
    }
  });
  Cloudonix.sessionEvents.onSessionTerminated((res: unknown) => {
    if (!terminationActionIndicator) {
      terminationActionIndicator = true;
      Cloudonix.sipStopCall();
      actions.onSessionTerminated(res);
    }
  });
  Cloudonix.sipStartCall(destination);
}

export async function startOutboundCall(
  destination: string,
  actions: {
    onSessionConnected: (res: unknown) => void;
    onSessionTerminated: (res: unknown) => void;
  }
) {
  let connectedActionRun = false;
  let terminatedActionRun = false;
  Cloudonix.sessionEvents.onSessionConnected((res: unknown) => {
    if (!connectedActionRun) {
      connectedActionRun = true;
      console.log("connected");
      actions.onSessionConnected(res);
    }
  });
  Cloudonix.sessionEvents.onSessionTerminated((res: unknown) => {
    if (!terminatedActionRun) {
      terminatedActionRun = true;
      console.log("terminated");
      actions.onSessionTerminated(res);
    }
  });
  Cloudonix.sipStartCall(`999${destination}`);
}

export function hangupCall(hangupCallback: (res: unknown) => void) {
  let hangupIndicator = false;
  Cloudonix.sessionEvents.onSessionTerminated((res: unknown) => {
    if (!hangupIndicator) {
      hangupIndicator = true;
      hangupCallback(res);
    }
  });
  Cloudonix.sipStopCall();
}

/**
 * @param char A character, representing the DTMF tone to send (0-9#*)
 */
export function sendDtmfTone(char: string) {
  Cloudonix.sipSendDtmfTone(char);
}

export function muteCall(muteCallback: (res: unknown) => void) {
  let muteIndicator = false;
  Cloudonix.sessionEvents.onLocalMuteToggle((res: unknown) => {
    if (!muteIndicator) {
      muteIndicator = true;
      muteCallback(res);
    }
  });
  Cloudonix.sipToggleMute();
}

export function unMuteCall(unMuteCallback: (res: unknown) => void) {
  let unmuteIndicator = false;
  Cloudonix.sessionEvents.onLocalMuteToggle((res: unknown) => {
    if (!unmuteIndicator) {
      unmuteIndicator = true;
      unMuteCallback(res);
    }
  });
  Cloudonix.sipToggleMute();
}

export const getLabelForNotIdentifiedPhoneNumber = (
  notIdentifiedType: CallSessionCallerType | null
) => {
  switch (notIdentifiedType) {
    case "Caregiver":
      return "Caregiver";
    case "NotIdentifiedCaregiver":
      return "Not Identified Caregiver";
    case "NotIdentifiedPatient":
      return "Not Identified Patient";
    case "NotIdentifiedInsuranceProvider":
      return "Not Identified Provider";
    case "Patient":
      return "Patient";
    case "NotIdentifiedPhoneNumber":
    case null:
    default:
      return "Not Identified";
  }
};

export const formatPhoneNumber = (input: string) => {
  if (phoneFormatter.isValidNumber(input)) {
    return phoneFormatter.formatNational(input);
  }

  return input;
};
