import { Input, InputProps } from "@chakra-ui/react";
import React, { ComponentProps } from "react";
import { MaskedInput, createDefaultMaskGenerator } from "react-hook-mask";

const maskGenerator = createDefaultMaskGenerator("(999) 999-9999");

const PhoneNumberInput = (props: InputProps & ComponentProps<typeof MaskedInput>) => {
  return <Input {...props} type="text" as={MaskedInput} maskGenerator={maskGenerator} />;
};

export default PhoneNumberInput;
