import { useToast } from "@chakra-ui/react";
import { Instant } from "@js-joda/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useRouter } from "@uirouter/react";
import React from "react";
import { match, P } from "ts-pattern";
import { z } from "zod";
import { Messages } from "../../../../core/api";
import { Entity, EntityWithStatus } from "../../../../shared/components/EntityCard";
import { withPathParams } from "../../../../shared/consts/hoc";
import useApi from "../../../../shared/hooks/useApi";
import useLiveCallTicketIds from "../../../../shared/hooks/useLiveCallTicketIds";
import useAuthData from "../../../../shared/hooks/useAuthInfo";
import useSocketEvent from "../../../../shared/hooks/useSocketEvent";
import { queryKeys } from "../../../../shared/query-keys";
import {
  CommCenterMessageId,
  CommCenterTeamId,
  CommCenterTeamMemberId,
  CommCenterTicketId,
  NoteSubjectId,
} from "../../../../shared/schema/schema";
import { fmap } from "../../../../shared/utils";
import * as callCenterUtils from "../../../../shared/utils/call-center";
import { formatErrorResponse } from "../../../../shared/utils/format-response-error";
import { getFullName } from "../../../../shared/utils/get-full-name";
import { Loadable, loadable } from "../../../../shared/utils/loadable";
import { optimisticUpdate } from "../../../../shared/utils/optimistic-update";
import useTicketViewMutation from "../../hooks/useTicketViewMutation";
import {
  CommCenterTicketStatus,
  getCommCenterTeamMemberIdByAgencyMemberId,
  getLatestCommCenterMessageByCaregiver,
  getTopicEntityFromTicket,
  NewTicketRequestBody,
  sortTicketsByLastMessage,
} from "../../utils/communication-utils";
import CommunicationCenterTicketPage from "./CommunicationCenterTicketPage";
import useActiveCall from "../../../../shared/hooks/useActiveCall";

const pathParamsSchema = z.object({
  ticketId: z.string().transform((x) => CommCenterTicketId.wrap(parseInt(x))),
});

export type EditTicketRequest = {
  ticketId: CommCenterTicketId;
  editParams: EditTicketFields;
};

export type CreateMessageRequest = {
  ticketId: CommCenterTicketId;
  message: string;
};

export type EditTicketFields = Partial<{
  assignedToId: CommCenterTeamMemberId | null;
  teamId: CommCenterTeamId;
  status: CommCenterTicketStatus;
}>;

type TicketRouteProps = {
  pathParams: z.infer<typeof pathParamsSchema>;
};

const CommunicationCenterTicketRoute = (props: TicketRouteProps) => {
  const { api } = useApi();
  const queryClient = useQueryClient();
  const toast = useToast();
  const { stateService } = useRouter();
  const { agencyMember } = useAuthData();
  const { activeCall, setActiveCall } = useActiveCall();

  const ticket = useQuery({
    queryKey: queryKeys.commCenter.get(props.pathParams.ticketId),
    queryFn: () => {
      return api.get("./comm_center/tickets/:ticketId", {
        path: {
          ticketId: props.pathParams.ticketId,
        },
      });
    },
    onSuccess: ({ ticket }) => {
      const latestReceivedMessage = getLatestCommCenterMessageByCaregiver(ticket.messages);

      if (latestReceivedMessage?.readAt === null) {
        markAsRead.mutate({
          ticketId: ticket.id,
          latestReceivedMessageId: latestReceivedMessage.id,
        });
      }
    },
  });

  const markAsRead = useTicketViewMutation();

  const topicQueryParam = fmap(ticket.data?.ticket, getTopicQueryParam) ?? {};

  const relatedOpenTickets = useQuery({
    enabled: ticket.data !== undefined ? undefined : false,
    queryKey: queryKeys.commCenter.search(topicQueryParam),
    queryFn: () => {
      return api.get("./comm_center/tickets", {
        query: topicQueryParam,
      });
    },
    keepPreviousData: true,
    onError: (error) => {
      toast({
        title: "Error in fetching related tickets",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
    select: (response) => sortTicketsByLastMessage(response.tickets),
  });

  const teams = useQuery({
    queryKey: queryKeys.commCenter.teams(),
    queryFn: () => {
      return api.get("./comm_center/teams", {});
    },
    keepPreviousData: true,
    onError: (error) => {
      toast({
        title: "Error in fetching communication center teams",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const submitMessage = useMutation({
    mutationFn: (createParams: CreateMessageRequest) => {
      return api.post("./comm_center/tickets/:commCenterTicketId/message", {
        path: {
          commCenterTicketId: createParams.ticketId,
        },
        body: {
          message: [
            {
              type: "TEXT",
              message: createParams.message,
            },
          ],
        },
      });
    },
    onMutate: async (createParams) => {
      const tickets = optimisticUpdate<{ tickets: Messages["CommCenterTicket"][] }>({
        queryClient,
        queryKey: queryKeys.commCenter.search.K,
        update: (draft) => {
          const ticket = draft.tickets.find((ticket) => ticket.id === createParams.ticketId);

          if (ticket !== undefined) {
            mutateSubmitMessageTicket({ ticket, body: createParams });
          }
        },
      });

      const ticket = optimisticUpdate<{ ticket: Messages["CommCenterTicket"] }>({
        queryClient,
        queryKey: queryKeys.commCenter.get(createParams.ticketId),
        update: (draft) => mutateSubmitMessageTicket({ ticket: draft.ticket, body: createParams }),
      });

      return { tickets, ticket };
    },
    onError: (error, _newChatMessage, context) => {
      queryClient.setQueriesData(queryKeys.commCenter.search.K, context?.tickets);
      queryClient.setQueriesData(
        queryKeys.commCenter.get(props.pathParams.ticketId),
        context?.ticket
      );

      toast({
        title: "Error submitting message",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const editTicket = useMutation({
    mutationFn: (params: Messages["Partial<EditCommCenterTicketParams>"]) => {
      return api.patch("./comm_center/tickets/:ticketId/edit", {
        path: {
          ticketId: props.pathParams.ticketId,
        },
        body: { params },
      });
    },
    onMutate: async (params) => {
      const { previousValue: previousTickets } = optimisticUpdate<{
        tickets: Messages["CommCenterTicket"][];
      }>({
        queryClient: queryClient,
        queryKey: queryKeys.commCenter.search.K,
        update: (draft) => {
          const ticket = draft.tickets.find((ticket) => ticket.id === props.pathParams.ticketId);

          if (ticket !== undefined) {
            mutateEditTicket({ ticket, body: params });
          }
        },
      });

      const { previousValue: previousTicket } = optimisticUpdate<{
        ticket: Messages["CommCenterTicket"];
      }>({
        queryClient: queryClient,
        queryKey: queryKeys.commCenter.get(props.pathParams.ticketId),
        update: (draft) => mutateEditTicket({ ticket: draft.ticket, body: params }),
      });

      return { previousTicket, previousTickets };
    },
    onSuccess: () => {
      toast({ title: "Ticket updated", status: "success", position: "top-right", duration: 2000 });
    },
    onError: (error, _, context) => {
      queryClient.setQueryData(
        queryKeys.commCenter.get(props.pathParams.ticketId),
        context?.previousTicket
      );

      queryClient.setQueriesData(queryKeys.commCenter.search.K, context?.previousTickets);

      toast({
        title: "Error updating ticket",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.commCenter.get(props.pathParams.ticketId));
      queryClient.invalidateQueries(queryKeys.commCenter.search.K);
    },
  });

  const labels = useQuery({
    queryKey: queryKeys.commCenter.labels(),
    queryFn: () => {
      return api.get("./comm_center/labels", {});
    },
    keepPreviousData: true,
    select: (response) => response.labels,
    onError: (error) => {
      toast({
        title: "Error in fetching communication center labels",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  useSocketEvent({
    key: "CommCenterTicketUpdated",
    onEvent: (event) => {
      if (event.ticketId === props.pathParams.ticketId) {
        queryClient.invalidateQueries(queryKeys.commCenter.get(props.pathParams.ticketId));
        queryClient.invalidateQueries(queryKeys.commCenter.search.K);
      }
    },
  });

  useSocketEvent({
    key: "CallCenterCallParticipantEvent",
    onEvent: (event) => {
      if (event.ticketId === props.pathParams.ticketId) {
        queryClient.invalidateQueries(queryKeys.commCenter.get(props.pathParams.ticketId));
      }
    },
  });

  useSocketEvent({
    key: "CallCenterCallEnd",
    onEvent: (event) => {
      if (event.ticketId === props.pathParams.ticketId) {
        queryClient.invalidateQueries(queryKeys.commCenter.get(props.pathParams.ticketId));
      }
    },
  });

  useSocketEvent({
    key: "CallCenterCallStart",
    onEvent: (event) => {
      if (event.ticketId === props.pathParams.ticketId) {
        queryClient.invalidateQueries(queryKeys.commCenter.get(props.pathParams.ticketId));
      }
    },
  });

  const markAsUnread = useMutation({
    mutationFn: (ticketId: CommCenterTicketId) => {
      return api.post("./comm_center/tickets/:ticketId/unread", {
        path: {
          ticketId: ticketId,
        },
      });
    },
    onMutate: () => {
      return optimisticUpdate<{ tickets: Messages["CommCenterTicket"][] }>({
        queryClient,
        queryKey: queryKeys.commCenter.search.K,
        update: (draft) => {
          draft.tickets
            .find((ticket) => ticket.id === props.pathParams.ticketId)
            ?.messages.forEach((message) => (message.readAt = null));
        },
      });
    },
    onError: (error, _, context) => {
      queryClient.setQueryData(queryKeys.commCenter.search.K, context?.previousValue);
      toast({
        title: "Error marking ticket as unread",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
    onSuccess: () => {
      stateService.go("app.commcenter");

      setTimeout(() => {
        queryClient.invalidateQueries(queryKeys.commCenter.get(props.pathParams.ticketId));
        queryClient.invalidateQueries(queryKeys.commCenter.search.K);
      }, 500);
    },
  });

  const createTicket = useMutation({
    mutationFn: (newTicketRequest: NewTicketRequestBody) => {
      return api.post(
        "/agencies/:agencyId/comm_center_team_members/:commCenterTeamMemberId/comm_center/tickets",
        {
          path: {
            commCenterTeamMemberId:
              getCommCenterTeamMemberIdByAgencyMemberId(teams.data?.teams ?? [], agencyMember.id) ??
              CommCenterTeamMemberId.wrap(0),
          },
          body: newTicketRequest,
        }
      );
    },
    onSuccess: (response) => {
      stateService.go("app.commcenter_ticket", { ticketId: response.ticketId });
    },
    onError: (error) => {
      toast({
        title: "Could not create new ticket",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const noteSettings = useQuery({
    queryKey: ["note_settings"],
    queryFn: () => api.get("./notes_settings", {}),
  });

  const liveCallTickets = useLiveCallTicketIds();

  const noteSubjects: { label: string; value: NoteSubjectId }[] =
    noteSettings.data?.agencyNoteSubjects?.map((x) => ({
      label: x.text,
      value: x.id,
    })) ?? [];

  const handleChangeSetting = (
    _ticketId: CommCenterTicketId,
    params: Messages["Partial<EditCommCenterTicketParams>"]
  ) => {
    editTicket.mutate(params);
  };

  const handleClickTicket = (newTicketId: CommCenterTicketId) => {
    stateService.go("app.commcenter_ticket", { ticketId: newTicketId });
  };

  const handleSubmitNewMessage = (ticketId: CommCenterTicketId, message: string) => {
    submitMessage.mutate({ ticketId, message });
  };

  const handleCreateNewTicket = (newTicketRequest: NewTicketRequestBody) => {
    createTicket.mutate(newTicketRequest);
  };

  const entityToDisplay: Loadable<EntityWithStatus<Entity>> = match(ticket)
    .with({ data: P.not(P.nullish) }, ({ data }) => {
      return loadable.resolve(getTopicEntityFromTicket(data.ticket));
    })
    .otherwise(() => loadable.loading());

  function mutateSubmitMessageTicket(params: {
    ticket: Messages["CommCenterTicket"];
    body: CreateMessageRequest;
  }) {
    const { ticket, body } = params;

    ticket.messages.push({
      id: CommCenterMessageId.wrap(Instant.now().toEpochMilli() * -1),
      createdBy: {
        type: "Agency Member",
        id: agencyMember.id,
        name: getFullName(agencyMember),
        photoUrl: agencyMember.photoUrl,
      },
      payload: [{ type: "TEXT", message: body.message }],
      labelId: null,
      readAt: null,
      messageActionId: null,
      ticketId: body.ticketId,
      createdAt: Instant.now(),
    });
  }

  function mutateEditTicket(params: {
    ticket: Messages["CommCenterTicket"];
    body: Messages["Partial<EditCommCenterTicketParams>"];
  }) {
    const { body, ticket } = params;

    if (body.teamId !== undefined) {
      ticket.relatedTeam.id = body.teamId;
      ticket.assignedTo = null;
    }

    if (body.assignedToId !== undefined) {
      ticket.assignedTo = {
        id: body.assignedToId,
        name: (() => {
          const assignee = teams.data?.teams
            .flatMap((team) => team.members)
            .find((member) => member.id === body.assignedToId);

          return fmap(assignee, getFullName) ?? "Loading...";
        })(),
      };
    }

    if (body.status !== undefined) {
      ticket.status = body.status;
    }

    if (body.patientId !== undefined) {
      ticket.relatedPatient =
        body.patientId === null
          ? null
          : {
              id: body.patientId,
              displayId: null,
              gender: null,
              name: "Loading...",
              status: "ACTIVE",
            };
    }

    if (body.caregiverId !== undefined) {
      ticket.relatedCaregiver =
        body.caregiverId === null
          ? null
          : {
              id: body.caregiverId,
              displayId: null,
              onboardingStageDetails: null,
              photoUrl: null,
              name: "Loading...",
              status: "ACTIVE",
            };
    }
  }

  const handleClickHangup = () => {
    callCenterUtils.hangupCall(() => {
      if (activeCall !== null) {
        setActiveCall({ ...activeCall, status: "Completed" });
      }
      console.log("hang up success");
    });
  };

  return (
    <CommunicationCenterTicketPage
      liveCallTicketIds={liveCallTickets.data ?? []}
      noteSubjects={noteSubjects}
      labels={labels.data?.filter((label) => label.active) ?? []}
      initialLabelId={
        labels.data?.find((label) => label.parent === null && !label.active)?.id ?? null
      }
      ticket={ticket.data?.ticket ?? null}
      entityToDisplay={entityToDisplay}
      teams={teams.data?.teams ?? []}
      relatedTickets={loadable.fromUndefined(relatedOpenTickets.data)}
      onEditTicket={handleChangeSetting}
      onClickMarkAsUnread={markAsUnread.mutate}
      onClickTicket={handleClickTicket}
      onSubmitNewMessage={handleSubmitNewMessage}
      onCreateNewTicket={handleCreateNewTicket}
      onClickHangup={handleClickHangup}
    />
  );
};

function getTopicQueryParam(ticket: Messages["CommCenterTicket"]) {
  return match(ticket)
    .with({ topic: "Caregiver", relatedCaregiver: P.not(null) }, (t) => ({
      caregiverId: [t.relatedCaregiver.id],
    }))
    .with({ topic: "Patient", relatedPatient: P.not(null) }, (t) => ({
      patientId: [t.relatedPatient.id],
    }))
    .with(
      { topic: "NotIdentifiedPhoneNumber", relatedNotIdentifiedPhoneNumber: P.not(null) },
      ({ relatedNotIdentifiedPhoneNumber }) => ({
        relatedNotIdentifiedPhoneNumber: [relatedNotIdentifiedPhoneNumber],
      })
    )
    .otherwise(() => {
      throw new Error(`Invalid ticket topic: ${ticket.id}`);
    });
}

export default withPathParams(CommunicationCenterTicketRoute, pathParamsSchema);
