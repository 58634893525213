import { Button, Flex, FlexProps, Input } from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import BackspaceIcon from "../icons/BackspaceIcon";
import { phoneFormatter } from "../utils/phone-formatter";
import PhoneNumberInput from "./PhoneNumberInput";
import SelectMenu from "./SelectMenu";

export interface PhoneNumberDialPadState {
  type: "PhoneNumberDialer";
  dialButtonConent: React.ReactNode;
  isCalling: boolean;
  dialButtonLeftIcon?: React.ReactElement;
  predefinedPhoneNumber?: string;
  onDialNumber: (E164number: string) => void;
}

export interface SingleDigitDialPadState {
  type: "SingleDigitDialer";
  currentState?: string;
  onClickDigit: (digit: string, accumulator: string) => void;
}

type DialPadState = PhoneNumberDialPadState | SingleDigitDialPadState;

interface Props {
  state: DialPadState;
  flexProps?: FlexProps;
}

const Dialpad = (props: Props) => {
  switch (props.state.type) {
    case "PhoneNumberDialer":
      return <PhoneNumberDialPad state={props.state} flexProps={props.flexProps} />;
    case "SingleDigitDialer":
      return <SingleDigitDialPad state={props.state} flexProps={props.flexProps} />;
    default:
      return null;
  }
};

const SingleDigitDialPad = ({
  state,
  flexProps,
}: {
  flexProps?: FlexProps;
  state: SingleDigitDialPadState;
}) => {
  const [accInput, setAccInput] = useState(state.currentState ?? "");
  const handlePressDigit = (val: number | string) => {
    state.onClickDigit(`${val}`, `${accInput}${val}`);
    setAccInput((curr) => `${curr}${val}`);
  };
  return (
    <Flex direction={"column"} gap={4} w={"60%"} {...flexProps}>
      <Input readOnly type={"text"} value={accInput} placeholder={"Press Digit..."} />
      <DialPadNumbers onClick={handlePressDigit} />
    </Flex>
  );
};

const PhoneNumberDialPad = ({
  flexProps,
  state,
}: {
  flexProps?: FlexProps;
  state: PhoneNumberDialPadState;
}) => {
  const [phonenumber, setPhoneNumber] = useState(state.predefinedPhoneNumber ?? "");
  const [prefix, setPrefix] = useState("+1");
  const addToNumber = (value: number | string) => {
    if (phonenumber.length >= 10) {
      return;
    }
    setPhoneNumber((curr) => `${curr}${value}`);
  };
  const removeFromNumber = () => {
    setPhoneNumber((curr) => curr.slice(0, curr.length - 1));
  };
  return (
    <Flex direction={"column"} gap={4} {...flexProps}>
      <Flex gap={2}>
        <SelectMenu
          label="Prefix"
          onChange={(e) => {
            if (e !== undefined) {
              setPrefix(e);
            }
          }}
          options={[
            { label: "+1", value: "+1" },
            { label: "+972", value: "+972" },
          ]}
          value={prefix}
          closeOnSelect={true}
        />
        <PhoneNumberInput
          value={phonenumber}
          textAlign={"left"}
          onChange={(e) => setPhoneNumber(e as string)}
          placeholder={"Enter number or paste..."}
          autoFocus={true}
          borderWidth={0}
        />
      </Flex>
      <DialPadNumbers onClick={addToNumber} />
      <DialPadNumber value={""} onClick={removeFromNumber}>
        <BackspaceIcon boxSize={6} />
      </DialPadNumber>
      <Button
        leftIcon={state.dialButtonLeftIcon}
        colorScheme={"blue"}
        isLoading={state.isCalling}
        disabled={state.isCalling || !phoneFormatter.isValidNumber(`${prefix}${phonenumber}`)}
        onClick={() => state.onDialNumber(`${prefix}${phonenumber}`)}
      >
        {state.dialButtonConent}
      </Button>
    </Flex>
  );
};

const DialPadNumbers = (props: { onClick: (val: string | number) => void }) => {
  return (
    <>
      <Flex direction={"row"} justifyContent={"space-around"}>
        <DialPadNumber onClick={props.onClick} value={1} />
        <DialPadNumber onClick={props.onClick} value={2} />
        <DialPadNumber onClick={props.onClick} value={3} />
      </Flex>
      <Flex direction={"row"} justifyContent={"space-around"}>
        <DialPadNumber onClick={props.onClick} value={4} />
        <DialPadNumber onClick={props.onClick} value={5} />
        <DialPadNumber onClick={props.onClick} value={6} />
      </Flex>
      <Flex direction={"row"} justifyContent={"space-around"}>
        <DialPadNumber onClick={props.onClick} value={7} />
        <DialPadNumber onClick={props.onClick} value={8} />
        <DialPadNumber onClick={props.onClick} value={9} />
      </Flex>
      <Flex direction={"row"} justifyContent={"space-around"}>
        <DialPadNumber onClick={props.onClick} value={"*"} />
        <DialPadNumber onClick={props.onClick} value={0} />
        <DialPadNumber onClick={props.onClick} value={"#"} />
      </Flex>
    </>
  );
};

const DialPadNumber = (props: {
  onClick: (value: number | string) => void;
  value: number | string;
  children?: React.ReactNode;
}) => {
  return (
    <Button
      height={"50px"}
      background={"white"}
      flexGrow={1}
      onClick={() => props.onClick(props.value)}
    >
      {props.value}
      {props.children}
    </Button>
  );
};

export default Dialpad;
