import React from "react";
import { createIcon } from "@chakra-ui/react";

const BaselinePersonAddAlt1Icon = createIcon({
  displayName: "BaselinePersonAddAlt1Icon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M13 8c0-2.21-1.79-4-4-4S5 5.79 5 8s1.79 4 4 4s4-1.79 4-4zm2 2v2h3v3h2v-3h3v-2h-3V7h-2v3h-3zM1 18v2h16v-2c0-2.66-5.33-4-8-4s-8 1.34-8 4z"
    />
  ),
});

export default BaselinePersonAddAlt1Icon;
