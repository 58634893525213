import { Box, Input, Spinner, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { match } from "ts-pattern";
import { loadable } from "../utils/loadable";
import EntityCard from "./EntityCard";
import { AgencyMemberId } from "../schema/schema";
import useAgencyMembersQuery from "../../modules/agencyMembers/hooks/useAgencyMembersQuery";

interface Props {
  onClickAgencyMember: (agencyMemberId: AgencyMemberId) => void;
}

const DialerAgencyMembersSearch = (props: Props) => {
  const [agencyMemberSearch, setAgencyMemberSearch] = useState("");
  const agencyMembers = useAgencyMembersQuery({ searchTerm: agencyMemberSearch });

  return (
    <Box>
      <Input
        type={"text"}
        value={agencyMemberSearch}
        onChange={(e) => setAgencyMemberSearch(e.currentTarget.value)}
        mb={2}
      />
      {match(loadable.fromQuery(agencyMembers))
        .with({ type: "Loading" }, () => <Spinner />)
        .with({ type: "Rejected" }, () => <Text>Error loading agency members</Text>)
        .with({ type: "Resolved" }, ({ value }) =>
          value.map((agencyMember) => (
            <EntityCard
              key={`${agencyMember.id}`}
              boxProps={{
                onClick: () => props.onClickAgencyMember(agencyMember.id),
                cursor: "pointer",
                mb: 2,
                borderBottom: "1px",
                borderColor: "gray.100",
              }}
              entity={{
                type: "Agency Member",
                fullName: `${agencyMember.firstName} ${agencyMember.lastName}`,
                ...agencyMember,
              }}
            />
          ))
        )
        .exhaustive()}
    </Box>
  );
};

export default DialerAgencyMembersSearch;
