import { Flex, Text } from "@chakra-ui/react";
import { LocalDateTime } from "@js-joda/core";
import React from "react";
import { match, P } from "ts-pattern";
import { Messages } from "../../../core/api";
import {
  CaregiverEntity,
  Entity,
  EntityWithStatus,
  NotIdentifiedPhoneNumberEntity,
  PatientEntity,
} from "../../../shared/components/EntityCard";
import PhotoFillIcon from "../../../shared/icons/PhotoFillIcon";
import {
  AgencyMemberId,
  CaregiverId,
  CommCenterLabelId,
  CommCenterTeamMemberId,
  PatientId,
  VisitBroadcastId,
  VisitInstanceId,
} from "../../../shared/schema/schema";
import { dateFormatter } from "../../../shared/utils/date-formatter";

export type NewTicketRequestBody = {
  labelId: CommCenterLabelId;
  caregiverId: CaregiverId | null;
  patientId: PatientId | null;
  message: string;
  topic: "Patient" | "Caregiver" | "NotIdentifiedPhoneNumber";
};

export const getTopicEntityFromTicket = (
  ticket: Messages["CommCenterTicket"]
): EntityWithStatus<Entity> => {
  return match(ticket)
    .with(
      { topic: "Caregiver", relatedCaregiver: P.not(null) },
      ({ relatedCaregiver }): EntityWithStatus<CaregiverEntity> => ({
        type: "Caregiver",
        displayId: relatedCaregiver.displayId,
        fullName: relatedCaregiver.name,
        id: relatedCaregiver.id,
        status: relatedCaregiver.status,
        photoUrl: relatedCaregiver.photoUrl,
      })
    )
    .with(
      { topic: "Patient", relatedPatient: P.not(null) },
      ({ relatedPatient }): EntityWithStatus<PatientEntity> => ({
        type: "Patient",
        displayId: relatedPatient.displayId,
        fullName: relatedPatient.name,
        id: relatedPatient.id,
        status: relatedPatient.status,
        gender: relatedPatient.gender,
      })
    )
    .with(
      { topic: "NotIdentifiedPhoneNumber", relatedNotIdentifiedPhoneNumber: P.not(null) },
      ({ relatedNotIdentifiedPhoneNumber }): EntityWithStatus<NotIdentifiedPhoneNumberEntity> => ({
        phoneNumber: relatedNotIdentifiedPhoneNumber,
        type: "NotIdentifiedPhoneNumber",
        status: null,
      })
    )
    .otherwise((state) => {
      throw new Error(`Invalid state ${JSON.stringify(state)}`);
    });
};

export const getRelatedEntityFromTicket = (
  ticket: Messages["CommCenterTicket"]
): EntityWithStatus<Entity> | null => {
  return match(ticket)
    .with(
      { topic: "Patient", relatedCaregiver: ticket.relatedCaregiver },
      ({ relatedCaregiver }): EntityWithStatus<CaregiverEntity> | null =>
        relatedCaregiver === null
          ? null
          : {
              type: "Caregiver",
              displayId: relatedCaregiver.displayId,
              fullName: relatedCaregiver.name,
              id: relatedCaregiver.id,
              status: relatedCaregiver.status,
              photoUrl: relatedCaregiver.photoUrl,
            }
    )
    .with(
      { topic: "Caregiver", relatedPatient: ticket.relatedPatient },
      ({ relatedPatient }): EntityWithStatus<PatientEntity> | null =>
        relatedPatient === null
          ? null
          : {
              type: "Patient",
              displayId: relatedPatient.displayId,
              fullName: relatedPatient.name,
              id: relatedPatient.id,
              status: relatedPatient.status,
              gender: relatedPatient.gender,
            }
    )
    .with(
      { topic: "NotIdentifiedPhoneNumber", relatedNotIdentifiedPhoneNumber: P.not(null) },
      ({
        relatedNotIdentifiedPhoneNumber,
      }): EntityWithStatus<NotIdentifiedPhoneNumberEntity> | null => ({
        type: "NotIdentifiedPhoneNumber",
        status: null,
        phoneNumber: relatedNotIdentifiedPhoneNumber,
      })
    )
    .otherwise((state) => {
      throw new Error(`Invalid state ${JSON.stringify(state)}`);
    });
};

export type CommCenterRelatedVisit =
  | {
      type: "VisitBroadcast";
      id: VisitBroadcastId;
      startTime: LocalDateTime;
      endTime: LocalDateTime;
      relatedPatient: NonNullable<Messages["CommCenterTicket"]["relatedPatient"]>;
    }
  | {
      type: "VisitInstance";
      id: VisitInstanceId;
      startTime: LocalDateTime;
      endTime: LocalDateTime;
      relatedPatient: NonNullable<Messages["CommCenterTicket"]["relatedPatient"]>;
    };

export function getRelatedVisitFromTicket(
  activeTicket: Messages["CommCenterTicket"] | null
): CommCenterRelatedVisit | null {
  const relatedPatient = activeTicket?.relatedPatient ?? null;
  return match({ activeTicket, relatedPatient })
    .with(
      { activeTicket: { relatedVisitBroadcast: P.not(null) }, relatedPatient: P.not(null) },
      ({ activeTicket, relatedPatient }) =>
        ({
          type: "VisitBroadcast",
          relatedPatient: relatedPatient,
          ...activeTicket.relatedVisitBroadcast,
        } as const)
    )
    .with(
      { activeTicket: { relatedVisitInstance: P.not(null) }, relatedPatient: P.not(null) },
      ({ activeTicket, relatedPatient }) =>
        ({
          type: "VisitInstance",
          relatedPatient: relatedPatient,
          ...activeTicket.relatedVisitInstance,
        } as const)
    )
    .otherwise(() => null);
}

export function getTextFormatForRelatedVisit(props: CommCenterRelatedVisit): React.ReactNode {
  return match(props)
    .with(
      { type: "VisitBroadcast" },
      (visitBroadcast) =>
        `This ticket is related to a Visit Broadcast (${
          visitBroadcast.id
        }) From ${dateFormatter.toDate(visitBroadcast.startTime)} - ${dateFormatter.toDate(
          visitBroadcast.endTime
        )}, patient: ${visitBroadcast.relatedPatient.name}`
    )
    .with(
      { type: "VisitInstance" },
      (visitInstance) =>
        `This ticket is related to a Visit Instance (${
          visitInstance.id
        }) on ${dateFormatter.toDateTime(visitInstance.startTime)}, patient: ${
          visitInstance.relatedPatient.name
        }`
    )
    .exhaustive();
}

export const getCommCenterTeamMemberIdByAgencyMemberId = (
  teams: Messages["CommCenterTeamWithMembers"][],
  agencyMemberId: AgencyMemberId
): CommCenterTeamMemberId | null => {
  const teamMember = teams
    .flatMap((team) => team.members)
    .find((member) => member.agencyMemberId === agencyMemberId);

  return teamMember?.id ?? null;
};

export function getCommCenterMessageCreatorName(
  message: Pick<Messages["CommCenterMessage"], "createdBy">
) {
  return match(message.createdBy)
    .with({ type: "Agency Member" }, (agencyMember) => agencyMember.name)
    .with({ type: "Caregiver" }, (caregiver) => caregiver.name)
    .with({ type: "System" }, () => "Medflyt Support")
    .exhaustive();
}

export function isCommCenterMessageAuthorTheSame(params: {
  previous: Pick<Messages["CommCenterMessage"], "createdBy"> | undefined;
  next: Pick<Messages["CommCenterMessage"], "createdBy"> | undefined;
}) {
  if (params.previous === undefined || params.next === undefined) {
    return false;
  }

  return match({ a: params.previous.createdBy, b: params.next.createdBy })
    .with({ a: { type: "Agency Member" }, b: { type: "Agency Member" } }, (agencyMembers) => {
      return agencyMembers.a.id === agencyMembers.b.id;
    })
    .with({ a: { type: "Caregiver" }, b: { type: "Caregiver" } }, (caregivers) => {
      return caregivers.a.id === caregivers.b.id;
    })
    .with({ a: { type: "System" }, b: { type: "System" } }, () => true)
    .otherwise(() => false);
}

export function getMessagePreview(message: Messages["CommCenterMessage"]) {
  const latestMessage = message.payload.at(-1);

  if (latestMessage === undefined) {
    return null;
  }

  return match(latestMessage)
    .with({ type: "TEXT" }, ({ message }) => message)
    .with({ type: "IMAGE" }, () => (
      <Flex gap={2} align="center">
        <PhotoFillIcon />
        <Text>Photo</Text>
      </Flex>
    ))
    .with({ type: "ACTION" }, () => <></>)
    .otherwise(() => <></>);
}

const CommCenterTicketStatus = ["NEW", "IN PROGRESS", "RESOLVED"] as const;
export type CommCenterTicketStatus = typeof CommCenterTicketStatus[number];

export function getLatestCommCenterMessageByCaregiver(messages: Messages["CommCenterMessage"][]) {
  return messages.filter((message) => message.createdBy.type === "Caregiver").at(-1);
}

export function sortTicketsByLastMessage(tickets: Messages["CommCenterTicket"][]) {
  return [...tickets].sort((a, b) => {
    const aLastMessage = a.messages[a.messages.length - 1];
    const bLastMessage = b.messages[b.messages.length - 1];

    if (aLastMessage === undefined) {
      return -1;
    }

    if (bLastMessage === undefined) {
      return 1;
    }

    return aLastMessage.createdAt.isBefore(bLastMessage.createdAt) ? 1 : -1;
  });
}

export function isChatMessagesTicket(ticket: Messages["CommCenterTicket"]) {
  const chatMessagesTicketSources: Messages["CommCenterTicketSource"][] = [
    "MANUAL",
    "MOBILE_CHAT",
    "SYSTEM_TRIGGER",
  ];
  return chatMessagesTicketSources.includes(ticket.source);
}

export function isPhoneCallTicket(
  ticket: Messages["CommCenterTicket"]
): ticket is Messages["CommCenterTicket"] & { callSessionDetails: Messages["CallSessionDetails"] } {
  const phoneCallTicketSources: Messages["CommCenterTicketSource"][] = ["PHONE_CALL"];
  return phoneCallTicketSources.includes(ticket.source);
}
