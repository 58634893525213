import styled from "@emotion/styled";
import React from "react";
import Viewer from "react-viewer";

interface Props {
  src: string;
}

function DocumentViewer(props: Props) {
  const [ref, setRef] = React.useState<HTMLElement | null>(null);

  return (
    <>
      <Container ref={(newRef) => setRef(newRef)}></Container>
      {ref && (
        <Viewer
          container={ref}
          images={[{ src: props.src, alt: "" }]}
          visible={true}
          noNavbar={true}
          changeable={false}
        />
      )}
    </>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export default DocumentViewer;
