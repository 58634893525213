import { LocalDate, nativeJs } from "js-joda";

//! @ngInject
export function newPatientSurplusModalCtrl(
  $scope,
  DatabaseApi,
  patientService,
  PatientModalService,
  $filter,
  $uibModalInstance,
  toaster,
  patientId
) {
  $scope.patientId = patientId;

  // $scope.patientPhysicians = patientPhysicians;

  function initialize(patientId) {
    $scope.patientContracts = PatientModalService.getPatientContractBasic(patientId, true).then(
      (contracts) => {
        $scope.patientContracts = contracts.map((contract) => ({
          ...contract,
          label: $filter("formatPatientContract")(contract),
        }));
      }
    );
    $scope.form = {};
    $scope.surplus = {
      patientContractId: null,
      amountInCents: 0,
      fromDate: null,
      toDate: null,
    };
  }

  $scope.save = function () {
    if ($scope.form.$invalid) {
      return;
    }
    console.log($scope.surplus);
    $scope.surplus.fromDate = LocalDate.from(nativeJs($scope.surplus.fromDate));
    if($scope.surplus.toDate !== undefined && $scope.surplus.toDate !== null){
      $scope.surplus.toDate = LocalDate.from(nativeJs($scope.surplus.toDate));
    }
    patientService
      .createPatientSurplus(1 * $scope.patientId, $scope.surplus)
      .then((res) => {
        toaster.pop("success", "surplus created");
        console.log(res);
        $scope.closeModal("");
      })
      .catch((err) => {
        console.error(err);
        toaster.pop("error", "Something went wrong");
      })
      .finally(() => ($scope.isLoading = false));
  };

  $scope.closeModal = function () {
    $uibModalInstance.close("ok");
  };

  initialize(patientId);
}
