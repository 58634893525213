import { QueryKey, UseQueryOptions } from "@tanstack/react-query";
import { createApi } from "../api";

function createQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  options: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, "initialData"> & {
    initialData?: () => undefined;
  }
) {
  return options;
}

export function createQueries(params: { api: ReturnType<typeof createApi> }) {
  const { api } = params;

  return {
    example: createQuery({
      queryKey: ["certification_period"],
      queryFn: () => api.get("./certification_periods", {}),
    }),
  };
}
