import { Badge, Center } from "@chakra-ui/react";
import React from "react";
import { ReactNode } from "react";

const CallTicketIndexBadge = ({ children }: { children?: ReactNode }) => {
  return (
    <Badge
      borderWidth={1}
      borderColor={"black"}
      fontWeight={"medium"}
      display={"flex"}
      as={"div"}
      alignItems="center"
      justifyContent={"center"}
      boxSize={10}
      fontSize={"lg"}
      rounded={"full"}
    >
      <Center>{children}</Center>
    </Badge>
  );
};

export default CallTicketIndexBadge;
