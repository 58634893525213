import { Box, Flex, SlideFade, Text } from "@chakra-ui/react";
import React from "react";

type Props = {
  isOpen: boolean;
};

const ClosedChatMessage = (props: Props) => {
  return (
    <SlideFade in={props.isOpen} style={{ zIndex: 10 }}>
      <Box opacity="80%" p="30px" color="black" bg="gray.100" rounded="md" shadow="md">
        <Flex direction="column" justifyContent="center" alignItems="center">
          <Text fontSize="18px">This chat was closed.</Text>
          <Text fontSize="16px">
            If you want to continue the conversation, please start a new one.
          </Text>
        </Flex>
      </Box>
    </SlideFade>
  );
};

export default ClosedChatMessage;
