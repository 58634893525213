import { LocalDateTime, Instant, ZoneId } from "js-joda";
import { isDefined } from "../utils/generalUtils";

//! @ngInject
export function visitInstanceService(visitConsts,Consts,$http,$rootScope) {

    const getVisitInstanceBroadcastStatus = (visitInstance) => {
        if (visitInstance.visitBroadcast === null || visitInstance.visit.caregiverId !== null) {
          return undefined;
        }
  
        const startBroadcastLocalDateTime = LocalDateTime.parse(visitInstance.visitBroadcast.startDateTime);

        // BROADCASTED IN THE PAST - It was a part of a broadcasted badge, but it's time had passed. 
        // Currently should look like unstaffed visit - without a badge.
        const instanceStartDate = LocalDateTime.parse(visitInstance.visit.startTime);

        if(instanceStartDate.isBefore(startBroadcastLocalDateTime)){
          return undefined;
        }

        // PENDING_CONFIRMATION
        const isPendingConfirmationUntilInFuture = (pendingConfirmationUntil) =>isDefined(pendingConfirmationUntil) && Instant.now().isBefore(Instant.parse(`${pendingConfirmationUntil}`));
        if (isPendingConfirmationUntilInFuture(visitInstance.pendingConfirmationUntil)) {
          return "PENDING_CONFIRMATION";
        }

        // AWAITING_ASSIGNMENT
        if (visitInstance.visitBroadcast.requests > 0) {
          return "AWAITING_ASSIGNMENT";
        }
  
        // UNSTAFFED
        const now = Instant.now();
        const broadcastStartAtVisitTimezone = startBroadcastLocalDateTime.atZone(ZoneId.of(visitInstance.visit.address.timezone)).toInstant();

        if (now.isAfter(broadcastStartAtVisitTimezone)) {
          return "UNSTAFFED";
        }
  
        // REQUIRE_CHANGES
        // if (now.isAfter(startBroadcastLocalDateTime.plusMinutes(10))) {
        //   return "REQUIRE_CHANGES";
        // }
  
        //PENDING_REQUESTS
        return "PENDING_REQUESTS";
    };

    const getVisitInstanceIssues = (visitInstance, billingIssuesSettings) => {
      const officeId = visitInstance.visit.officeId;
      const contractId = visitInstance.visit.contractId
      const issuesMap = {};
      const settingsNotDefined = (
        billingIssuesSettings[officeId] === undefined ||
        billingIssuesSettings[officeId][contractId] === undefined
      );
      visitConsts.allValidatorIds.forEach(validator => {
        const issueValidator = `issue_${validator}`;
        const isBillingIssue = settingsNotDefined || billingIssuesSettings[officeId][contractId]["billing_" + issueValidator];
        const isPayrollIssue = settingsNotDefined || billingIssuesSettings[officeId][contractId]["payroll_" + issueValidator];
        if (visitInstance[issueValidator] && (isBillingIssue || isPayrollIssue)) {
          issuesMap[validator] = {
            issueValidator: issueValidator,
            isBillingIssue: isBillingIssue,
            isPayrollIssue: isPayrollIssue
          };
        }
      });
      return issuesMap;
    };
    const getVisitInstanceAdjustmentApprovals = (visitInstance) => {
      
      return $http.get(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/adjustment_approvals?visits=${visitInstance}`);        
    };
    return  {
      getVisitInstanceBroadcastStatus,
      getVisitInstanceIssues,
      getVisitInstanceAdjustmentApprovals
    };
};