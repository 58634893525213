import { PhoneIcon } from "@chakra-ui/icons";
import { Flex, Text } from "@chakra-ui/react";
import { Duration } from "@js-joda/core";
import React from "react";
import { FaHourglassHalf } from "react-icons/fa";
import { match } from "ts-pattern";
import { Messages } from "../../../../core/api";
import { fmap } from "../../../../shared/utils";
import { dateFormatter } from "../../../../shared/utils/date-formatter";
import { durationFormatter } from "../../../../shared/utils/duration-formatter";
import { phoneFormatter } from "../../../../shared/utils/phone-formatter";
import useCommCenterCallRecording from "../../hooks/useCommCenterCallRecording";
import CallTicketEventCard from "./helpers/CallTicketEventCard/CallTicketEventCard";
import CallTicketRecording from "./helpers/CallTicketEventCard/CallTicketRecording";
import CallTicketParticipantCard from "../CallTicketParticipantCard";
import CallTicketColumn from "./helpers/CallTicketColumn";
import CallTicketRow from "./helpers/CallTicketRow";
import PhoneCallStatus from "../PhoneCallStatus";
import CallNotesSection from "./CallNotesSection";
import useCallTicketNotes from "./hooks/useCallTicketNotes";
import CallNoteCard from "./CallNoteCard";
import { NoteId } from "../../../../shared/schema/schema";

interface Props {
  activeTicket: Messages["CommCenterTicket"] & {
    callSessionDetails: Messages["CallSessionDetails"];
  };
  notesSectionState: ReturnType<typeof useCallTicketNotes>;
}

const CompletedCallTicket = (props: Props) => {
  const recordingState = useCommCenterCallRecording({
    callSessionDetails: props.activeTicket.callSessionDetails,
    ticketId: props.activeTicket.id,
  });
  const { callSessionDetails } = props.activeTicket;

  return (
    <Flex
      direction="row"
      justifyContent="space-between"
      backgroundSize="60%"
      borderRadius="0 12px 12px 0"
      height="100%"
      gap={2}
      padding={2}
    >
      <CallTicketColumn>
        <CallTicketRow>
          <CallTicketRow.Header>Call Details</CallTicketRow.Header>
          <CallTicketRow.Content>
            <CallTicketRow.ContentRow>
              <PhoneIcon color={"green.400"} />
              <Text>{formatDirection(callSessionDetails.direction)}</Text>
              <Text>{fmap(callSessionDetails.createdAt, (v) => dateFormatter.toDateTime(v))}</Text>
            </CallTicketRow.ContentRow>
            <CallTicketRow.ContentRow>
              <FaHourglassHalf size={"14px"} />
              <Text>
                {durationFormatter.hours(Duration.ofSeconds(callSessionDetails.durationSeconds))}
              </Text>
            </CallTicketRow.ContentRow>
            <CallTicketRow.ContentRow>
              <PhoneIcon />
              <Text>
                {phoneFormatter.formatNationalIfValid(callSessionDetails.externalPhoneNumber)}
              </Text>
            </CallTicketRow.ContentRow>

            <CallTicketRow.ContentRow>
              <CallTicketRecording state={recordingState} />
            </CallTicketRow.ContentRow>

            <CallTicketRow.ContentRow>
              <PhoneCallStatus call={callSessionDetails} recordingState={{ type: "NoRecording" }} />
            </CallTicketRow.ContentRow>
          </CallTicketRow.Content>
        </CallTicketRow>
        <CallTicketRow>
          <CallTicketRow.Header>Call Notes</CallTicketRow.Header>
          <CallTicketRow.Content gap={4}>
            <CallNotesSection {...props.notesSectionState} ticketId={props.activeTicket.id} />
            {callSessionDetails.sessionNotes.map((note) => (
              <CallNoteCard key={NoteId.unwrap(note.id).toString()} note={note} />
            ))}
          </CallTicketRow.Content>
        </CallTicketRow>
      </CallTicketColumn>
      <CallTicketColumn>
        <CallTicketRow>
          <CallTicketRow.Header>Call Parties</CallTicketRow.Header>
          <CallTicketRow.Content>
            {callSessionDetails.sessionParticipantRoles.map((role, i) => (
              <CallTicketRow.ContentRow key={`${role.id}`}>
                <CallTicketParticipantCard entity={role} index={i} />
              </CallTicketRow.ContentRow>
            ))}
          </CallTicketRow.Content>
        </CallTicketRow>
        <CallTicketRow>
          <CallTicketRow.Header>Call Events</CallTicketRow.Header>
          <CallTicketRow.Content>
            {callSessionDetails.sessionParticipantEvents.map((event) => (
              <CallTicketRow.ContentRow key={`${event.id}`}>
                <CallTicketEventCard event={event} />
              </CallTicketRow.ContentRow>
            ))}
          </CallTicketRow.Content>
        </CallTicketRow>
      </CallTicketColumn>
    </Flex>
  );
};

const formatDirection = (direction: "INBOUND" | "OUTBOUND"): string => {
  return match(direction)
    .with("INBOUND", () => "Inbound")
    .with("OUTBOUND", () => "Outgoing")
    .exhaustive();
};

export default CompletedCallTicket;
