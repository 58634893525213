import { ChatIcon, PhoneIcon } from "@chakra-ui/icons";
import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { match, P } from "ts-pattern";
import { Messages } from "../../../core/api";

interface Props {
  source: Messages["CommCenterTicket"]["source"];
  callSessionDetails: Messages["CommCenterTicket"]["callSessionDetails"];
}

const callDirectionTextMap: Record<Messages["CallSessionDetails"]["direction"], string> = {
  INBOUND: "In",
  OUTBOUND: "Out",
};

const TicketSourceCell = (props: Props) => {
  return match(props.source)
    .with("MANUAL", "MOBILE_CHAT", "SYSTEM_TRIGGER", () => <ChatIcon />)
    .with("PHONE_CALL", () => (
      <Flex gap={1}>
        <PhoneIcon color={"green.400"} />
        {match(props.callSessionDetails)
          .with(P.nullish, () => null)
          .with(P.not(P.nullish), (callDetails) => (
            <Text fontSize={"x-small"}>{callDirectionTextMap[callDetails.direction]}</Text>
          ))
          .exhaustive()}
      </Flex>
    ))
    .exhaustive();
};

export default TicketSourceCell;
