import React from "react";
import { match, P } from "ts-pattern";
import { Messages } from "../../../core/api";
import { CommCenterTicketId } from "../../../shared/schema/schema";
import useCommCenterCallRecording from "../hooks/useCommCenterCallRecording";
import LiveBadge from "./LiveBadge";
import PhoneCallStatus from "./PhoneCallStatus";

interface Props {
  callSessionDetails: Messages["CommCenterTicket"]["callSessionDetails"];
  ticketId: CommCenterTicketId;
  liveCallTicketIds: CommCenterTicketId[];
}

const PhoneCallPreviewCell = (props: Props) => {
  const recordingState = useCommCenterCallRecording({
    callSessionDetails: props.callSessionDetails,
    ticketId: props.ticketId,
  });
  return match(props.callSessionDetails)
    .with(P.nullish, () => <span>No Status</span>)
    .with(P.not(P.nullish), (call) =>
      props.liveCallTicketIds.includes(props.ticketId) ? (
        <LiveBadge />
      ) : (
        <PhoneCallStatus call={call} recordingState={recordingState} />
      )
    )
    .exhaustive();
};

export default PhoneCallPreviewCell;
