import React from "react";
import { createIcon } from "@chakra-ui/react";

const CaregiverIcon = createIcon({
  displayName: "CaregiverIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="m12 21.35l-1.45-1.32C5.4 15.36 2 12.27 2 8.5C2 5.41 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.08C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.41 22 8.5c0 3.77-3.4 6.86-8.55 11.53L12 21.35Z"
    />
  ),
});

export default CaregiverIcon;
