import { Button } from "@chakra-ui/react";
import React from "react";
import { Messages } from "../../../core/api";
import { FaxDetailsId } from "../../../shared/schema/schema";

interface Props {
  fax: Messages["DashboardFax"];
  onUpdateAction: (faxId: FaxDetailsId) => void;
}

const UpdateActionFaxButton = (props: Props) => {
  return (
    <Button
      size="xs"
      colorScheme={props.fax.isActionStatusDone ? "yellow" : "blue"}
      variant="outline"
      w="full"
      onClick={() => props.onUpdateAction(props.fax.id)}
    >
      {props.fax.isActionStatusDone ? "Mark as new" : "Mark as done"}
    </Button>
  );
};

export default UpdateActionFaxButton;
