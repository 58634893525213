import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  ThemingProps,
} from "@chakra-ui/react";
import React from "react";

interface Props<$Option> {
  label: string;
  options: { label: string; value: $Option }[] | ReadonlyArray<{ label: string; value: $Option }>;
  buttonProps?: MenuButtonProps;
  value: $Option | null;
  onChange: (selected: $Option | undefined) => void;
  isLazy?: boolean;
  disabled?: boolean;
  size?: ThemingProps["size"];
  closeOnSelect?: boolean;
}

const SelectMenu = <$Option,>(props: Props<$Option>): JSX.Element => {
  const selectedLabel = props.options.find((x) => x.value === props.value)?.label ?? null;

  return (
    <Menu closeOnSelect={props.closeOnSelect ?? false} isLazy={props.isLazy}>
      <MenuButton
        as={Button}
        disabled={props.disabled}
        variant="outline"
        rightIcon={<ChevronDownIcon />}
        backgroundColor={props.value !== null ? "blue.50" : "transparent"}
        color={props.value !== null ? "blue.500" : "gray.600"}
        borderColor={props.value !== null ? "blue.200" : "gray.300"}
        size={props.size}
        {...props.buttonProps}
      >
        {`${selectedLabel ?? props.label}`}
      </MenuButton>
      <MenuList maxH="40vh" overflowY="auto">
        <MenuOptionGroup
          title={undefined}
          value={`${props.value}`}
          onChange={($value) => {
            const type = typeof props.options[0].value;
            const asSingular = Array.isArray($value) ? $value[0] : $value;
            const values = type === "number" ? Number(asSingular) : asSingular;

            props.onChange(values as unknown as $Option);
          }}
        >
          {props.options.map((option) => {
            return (
              <MenuItemOption
                key={`multiselect-menu-${option.value}`}
                isChecked={props.value === option.value}
                value={`${option.value}`}
                fontSize={props.size}
              >
                {option.label}
              </MenuItemOption>
            );
          })}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};

SelectMenu.displayName = "SelectMenu";

export default SelectMenu;
