import React from "react";
import { createIcon } from "@chakra-ui/react";

const MicMuteFillIcon = createIcon({
  displayName: "MicMuteFillIcon",
  viewBox: "0 0 16 16",
  path: (
    <g fill="currentColor">
      <path d="M13 8c0 .564-.094 1.107-.266 1.613l-.814-.814A4.02 4.02 0 0 0 12 8V7a.5.5 0 0 1 1 0v1zm-5 4c.818 0 1.578-.245 2.212-.667l.718.719a4.973 4.973 0 0 1-2.43.923V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 1 0v1a4 4 0 0 0 4 4zm3-9v4.879L5.158 2.037A3.001 3.001 0 0 1 11 3z" />
      <path d="M9.486 10.607L5 6.12V8a3 3 0 0 0 4.486 2.607zm-7.84-9.253l12 12l.708-.708l-12-12l-.708.708z" />
    </g>
  ),
});

export default MicMuteFillIcon;
