import { IconButton, Popover, PopoverContent, PopoverTrigger } from "@chakra-ui/react";
import EmojiPicker from "emoji-picker-react";
import React from "react";
import SmileyIcon from "../icons/SmileyIcon";

export function EmojiPickerPopover(props: React.ComponentProps<typeof EmojiPicker>) {
  return (
    <Popover placement="top-start">
      <PopoverTrigger>
        <IconButton
          aria-label="emoji"
          size="lg"
          rounded="2xl"
          icon={<SmileyIcon fontSize="2xl" />}
        />
      </PopoverTrigger>
      <PopoverContent border={0} backgroundColor="transparent" minW="lg">
        <EmojiPicker width="auto" {...props} />
      </PopoverContent>
    </Popover>
  );
}
