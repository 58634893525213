import React from "react";
import { createIcon } from "@chakra-ui/react";

const Dot02SIcon = createIcon({
  displayName: "Dot02SIcon",
  viewBox: "0 0 24 24",
  path: <path fill="currentColor" d="M12 15a3 3 0 1 1 0-6a3 3 0 0 1 0 6Z" />,
});

export default Dot02SIcon;
