import { FormControl, FormLabel, Input, Select, StyleProps } from "@chakra-ui/react";
import React from "react";

interface IntakeProfileDropdownProps {
  isEditable: boolean;
  isRequired: boolean;
  options: { value: string | null; label: string; key?: string }[];
  label: string;
  currValue: {
    value: string | null;
    label: string;
  };
  styleProps?: StyleProps;
  onChange: (value: string | null) => void;
}

const IntakePatientProfileDropdown = (props: IntakeProfileDropdownProps) => {
  const [currOption, setCurrOption] = React.useState<{
    value: string | null;
    label: string;
  }>(props.currValue);

  const handleChangeOption = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const chosenOption = event.target.selectedOptions.item(0);

    if (chosenOption === null) {
      setCurrOption({
        value: null,
        label: "Not chosen",
      });
    } else {
      setCurrOption({
        value: chosenOption.value,
        label: chosenOption.label,
      });
    }

    props.onChange(chosenOption?.value ?? null);
  };

  return (
    <FormControl isRequired={props.isEditable ? props.isRequired : false} {...props.styleProps}>
      <FormLabel>{props.label}</FormLabel>
      {props.isEditable ? (
        <Select onChange={(event) => handleChangeOption(event)}>
          <option value={"Not Chosen"}>{"Not chosen"}</option>
          {props.options.map((option) => (
            <option
              key={option.key ?? `option-${option.value}`}
              value={option.value ?? "Unknown"}
              selected={option.value === currOption.value}
            >
              {option.label}
            </option>
          ))}
        </Select>
      ) : (
        <Input isDisabled={!props.isEditable} value={currOption.label} />
      )}
    </FormControl>
  );
};

export default IntakePatientProfileDropdown;
