import { Badge, keyframes } from "@chakra-ui/react";
import React from "react";
import CircleDotIcon from "../../../shared/icons/CircleDotIcon";

const flicker = keyframes`
  50% {
    opacity: 0;
  }
`;

const LiveBadge = () => {
  return (
    <Badge
      paddingX={2}
      rounded={"md"}
      gap={1}
      as={"div"}
      display={"flex"}
      alignItems={"center"}
      bg={"red"}
      color={"white"}
      w={"50px"}
    >
      live
      <CircleDotIcon animation={`${flicker} 1.5s ease-in-out infinite`} />
    </Badge>
  );
};

export default LiveBadge;
