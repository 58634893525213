import { Flex } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { LocalDate } from "@js-joda/core";
import React from "react";
import BaseDatePicker, { BaseDatePickerProps } from "./BaseDatePicker";
import RangeDatePickerRanges from "./RangeDatePickerRanges";
import useRangeDatePicker from "./useRangeDatePicker";

const Calendars = styled.div`
  display: flex;
  width: fit-content;
  gap: 12px;
`;

export interface RangeDatePickerProps extends Omit<BaseDatePickerProps, "onChange"> {
  onChange: (
    date: [LocalDate, LocalDate | null] | null,
    event: React.SyntheticEvent<unknown, Event> | undefined
  ) => void;
}

const RangeDatePicker = (props: RangeDatePickerProps) => {
  const { pickerProps, actions } = useRangeDatePicker();

  const handleChange = (
    date: LocalDate | [LocalDate, LocalDate | null] | null,
    event: React.SyntheticEvent<unknown, Event> | undefined
  ) => {
    if (date instanceof LocalDate) {
      throw new Error("RangeDatePicker.onChange should return a range value or null");
    }

    if (date === null || date[1] !== null) {
      actions.close();
    }

    return props.onChange(date, event);
  };

  return (
    <BaseDatePicker
      {...props}
      {...pickerProps}
      monthsShown={props.monthsShown ?? 2}
      selectsRange={true}
      onChange={handleChange}
      calendarContainer={({ children }) => {
        return (
          <Flex gap={8}>
            <RangeDatePickerRanges
              startDate={props.startDate}
              endDate={props.endDate}
              onClear={(e) => handleChange(null, e)}
              onClick={handleChange}
            />
            <Calendars>{<>{children}</>}</Calendars>
          </Flex>
        );
      }}
    />
  );
};

export default RangeDatePicker;
