import { Flex, Heading } from "@chakra-ui/react";
import { LocalDate, LocalDateTime } from "@js-joda/core";
import React from "react";
import { validateOneOf } from "../../../shared/utils";
import IntakePatientProfileDatepicker from "./IntakePatientProfileDatepicker";
import IntakePatientProfileDropdown from "./IntakePatientProfileDropdown";

interface Props {
  isEditMode: boolean;
  NYIA1Date: LocalDateTime | null;
  NYIA1Status: boolean;
  NYIA2Date: LocalDateTime | null;
  NYIA2Status: boolean;
  NYIAResults: ("Approved" | "Denied") | null;
  onChangeField: (
    field:
      | "NYIA1AppoitmentDate"
      | "NYIA2AppoitmentDate"
      | "NYIA1Completed"
      | "NYIA2Completed"
      | "NYIAResults",
    value: LocalDateTime | LocalDate | boolean | string | null
  ) => void;
}

const NYIAStatusOptions = [
  {
    value: "Completed",
    label: "Completed",
  },
  {
    value: "Not completed",
    label: "Not completed",
  },
];

const NYIAResultsOptions = [
  {
    value: "Approved",
    label: "Approved",
  },
  {
    value: "Denied",
    label: "Denied",
  },
  {
    value: null,
    label: "Unknown",
  },
];

const IntakeProfileNYIAInfo = (props: Props) => {
  return (
    <Flex direction="column" gap={4}>
      <Heading as="h1" size="md">
        {" "}
        NYIA Information
      </Heading>
      <Flex direction="row" gap={4}>
        <IntakePatientProfileDatepicker
          isEditable={props.isEditMode}
          isRequired={false}
          withTime={true}
          label={"NYIA #1 Date"}
          value={props.NYIA1Date}
          placement="right"
          onChange={(value) => props.onChangeField("NYIA1AppoitmentDate", value)}
        />
        <IntakePatientProfileDropdown
          isEditable={props.isEditMode}
          isRequired={false}
          options={NYIAStatusOptions}
          label={"NYIA #1 Status"}
          currValue={{
            value: props.NYIA1Status ? "Completed" : "Not Completed",
            label: props.NYIA1Status ? "Completed" : "Not Completed",
          }}
          onChange={(value) =>
            props.onChangeField("NYIA1Completed", value === "Completed" ? true : false)
          }
        />
      </Flex>
      <Flex direction="row" gap={4}>
        <IntakePatientProfileDatepicker
          isEditable={props.isEditMode}
          isRequired={false}
          withTime={true}
          label={"NYIA #2 Date"}
          value={props.NYIA2Date}
          placement="right"
          onChange={(value) => props.onChangeField("NYIA2AppoitmentDate", value)}
        />
        <IntakePatientProfileDropdown
          isEditable={props.isEditMode}
          isRequired={false}
          options={NYIAStatusOptions}
          label={"NYIA #2 Status"}
          currValue={{
            value: props.NYIA2Status ? "Completed" : "Not Completed",
            label: props.NYIA2Status ? "Completed" : "Not Completed",
          }}
          onChange={(value) =>
            props.onChangeField("NYIA2Completed", value === "Completed" ? true : false)
          }
        />
      </Flex>
      <Flex direction="row" gap={4}>
        <IntakePatientProfileDropdown
          isEditable={props.isEditMode}
          isRequired={false}
          options={NYIAResultsOptions}
          label={"NYIA Results"}
          currValue={{
            value: props.NYIAResults,
            label: props.NYIAResults !== null ? props.NYIAResults : "Unknown",
          }}
          onChange={(value) =>
            props.onChangeField(
              "NYIAResults",
              value === null ? value : validateOneOf(value, ["Approved", "Denied"])
            )
          }
        />
      </Flex>
    </Flex>
  );
};

export default IntakeProfileNYIAInfo;
