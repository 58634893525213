import { Input, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { match } from "ts-pattern";
import { EntitySelectItemShimmer } from "./EntitySelect";
import { useDebounce } from "../hooks/useDebounce";
import { loadable } from "../utils/loadable";
import { useSearchPatientsQuery } from "../../modules/patient/hooks/useSearchPatientsQuery";
import EntityPreview from "./DialerEntityPreview";

interface Props {
  onClickDial: (E164number: string) => void;
}

const DialerPatientsSearch = (props: Props) => {
  const [patientSearchText, setPatientSearchText] = useState("");
  const debouncedPatientSearchText = useDebounce(patientSearchText, 200);
  const patients = useSearchPatientsQuery({ term: debouncedPatientSearchText });
  return (
    <>
      <Input
        type="text"
        value={patientSearchText}
        onChange={(e) => setPatientSearchText(e.currentTarget.value)}
        placeholder={"Search patients..."}
      />
      {debouncedPatientSearchText &&
        match(loadable.fromQuery(patients))
          .with({ type: "Loading" }, () => <EntitySelectItemShimmer />)
          .with({ type: "Resolved" }, ({ value }) =>
            value.length === 0 ? (
              <Text>Not Found</Text>
            ) : (
              value.map((patient) => (
                <EntityPreview
                  key={`${patient.id}`}
                  entity={{
                    type: "Patient",
                    ...patient,
                  }}
                  onClickDial={props.onClickDial}
                />
              ))
            )
          )
          .with({ type: "Rejected" }, () => <Text>Error loading</Text>)
          .exhaustive()}
    </>
  );
};

export default DialerPatientsSearch;
