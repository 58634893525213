import {
  Button,
  Flex,
  Input,
  Heading,
  Textarea,
  FormLabel,
  RadioGroup,
  Radio,
  Spacer,
} from "@chakra-ui/react";
import React from "react";
import { z } from "zod";
import SendIcon from "../../../shared/icons/SendIcon";
import { CaregiverId, PatientId } from "../../../shared/schema/schema";
import CaregiverSelect from "../../caregiver/components/CaregiverSelect";
import PatientSelect from "../../patient/components/PatientSelect";
import SelectFile from "./SelectFile";

export interface SendFaxRequest {
  faxNumber: string;
  recipientName: string;
  subject: string;
  comment: string;
  file: File;
  relatedEntity:
    | {
        type: "Caregiver";
        caregiverId: CaregiverId;
      }
    | {
        type: "Patient";
        patientId: PatientId;
      };
}

interface SendFaxForm {
  faxNumber: string;
  recipientName: string;
  subject: string;
  comment: string;
  files: File[];
  entityType: "Caregiver" | "Patient";
  caregiverId: CaregiverId | null;
  patientId: PatientId | null;
}

interface Props {
  onSendFax: (request: SendFaxRequest) => void;
  relatedEntity?:
    | {
        type: "Caregiver";
        caregiverId: CaregiverId;
      }
    | {
        type: "Patient";
        patientId: PatientId;
      };
}

const formDataSchema = z
  .object({
    faxNumber: z.string().min(1, { message: "Fax number is missing" }),
    recipientName: z
      .string()
      .min(1, { message: "Recipient name is missing" })
      .max(40, { message: "Recepient name is too long" }),
    subject: z
      .string()
      .min(1, { message: "Subject is missing" })
      .max(55, { message: "Subject is too long" }),
    comment: z.string().max(4000, { message: "Comment is too long" }),
    files: z.array(z.instanceof(File)).nonempty({ message: "Please upload a file" }),
  })
  .and(
    z.union([
      z.object({
        entityType: z.literal("Caregiver"),
        caregiverId: z
          .number({ required_error: "Caregiver is missing" })
          .transform(CaregiverId.wrap),
        patientId: z.number().transform(PatientId.wrap).nullable(),
      }),
      z.object({
        entityType: z.literal("Patient"),
        patientId: z.number({ required_error: "Patient is missing" }).transform(PatientId.wrap),
        caregiverId: z.number().transform(CaregiverId.wrap).nullable(),
      }),
    ])
  );

const SendFaxForm = (props: Props) => {
  const [sendFaxForm, setSendFaxForm] = React.useState<SendFaxForm>({
    faxNumber: "",
    recipientName: "",
    subject: "",
    comment: "",
    files: [],
    entityType: props.relatedEntity?.type ?? "Caregiver",
    caregiverId:
      props.relatedEntity?.type === "Caregiver" ? props.relatedEntity?.caregiverId : null,
    patientId: props.relatedEntity?.type === "Patient" ? props.relatedEntity?.patientId : null,
  });

  const [errors, setErrors] = React.useState<{
    faxNumber?: string[];
    recipientName?: string[];
    subject?: string[];
    caregiverId?: string[];
    patientId?: string[];
    file?: string[];
  } | null>({
    faxNumber: ["Fax number is missing"],
    recipientName: ["Recipient name is missing"],
    subject: ["Subject name is missing"],
  });

  const handleChangeFormDetails = (field: Partial<SendFaxForm>) => {
    const newDetails = {
      ...sendFaxForm,
      ...field,
    };

    setSendFaxForm(newDetails);

    const result = formDataSchema.safeParse(newDetails);
    setErrors(!result.success ? result.error.flatten().fieldErrors : null);
  };

  const handleSendFax = () => {
    const result = formDataSchema.safeParse(sendFaxForm);

    if (!result.success) {
      setErrors(result.error.flatten().fieldErrors);
      return;
    }

    props.onSendFax({
      comment: result.data.comment,
      faxNumber: result.data.faxNumber,
      subject: result.data.subject,
      recipientName: result.data.recipientName,
      file: result.data.files[0],
      relatedEntity:
        result.data.entityType === "Caregiver"
          ? {
              type: "Caregiver",
              caregiverId: result.data.caregiverId,
            }
          : {
              type: "Patient",
              patientId: result.data.patientId,
            },
    });
  };

  return (
    <Flex direction="column" gap={10}>
      <Heading alignSelf="flex-start" as="h4" size="md">
        {" "}
        Tag Caregiver/Patient{" "}
      </Heading>
      <Flex direction="row">
        <RadioGroup
          value={sendFaxForm.entityType}
          onChange={(value: "Caregiver" | "Patient") =>
            handleChangeFormDetails({ entityType: value })
          }
        >
          <Flex direction="row" gap={30}>
            <Flex direction="row" gap={4}>
              <Radio value="Caregiver" checked={sendFaxForm.entityType === "Caregiver"} />
              <CaregiverSelect
                value={sendFaxForm.caregiverId}
                isDisabled={sendFaxForm.entityType !== "Caregiver"}
                onChange={(value) => handleChangeFormDetails({ caregiverId: value?.id ?? null })}
              />
            </Flex>
            <Flex direction="row" gap={4}>
              <Radio value="Patient" checked={sendFaxForm.entityType === "Patient"} />
              <PatientSelect
                value={sendFaxForm.patientId}
                isDisabled={sendFaxForm.entityType !== "Patient"}
                onChange={(value) => handleChangeFormDetails({ patientId: value?.id ?? null })}
              />
            </Flex>
          </Flex>
        </RadioGroup>
      </Flex>
      <Spacer></Spacer>
      <Spacer></Spacer>
      <Heading alignSelf="flex-start" as="h4" size="md">
        {" "}
        Fax Details{" "}
      </Heading>
      <Flex direction="row" gap={10}>
        <Flex direction="column">
          <FormLabel>Fax Number</FormLabel>
          <Input
            value={sendFaxForm.faxNumber}
            width="200px"
            onChange={(event) => handleChangeFormDetails({ faxNumber: event.target.value })}
          />
        </Flex>
        <Flex direction="column">
          <FormLabel>Recipient Name</FormLabel>
          <Input
            width="200px"
            value={sendFaxForm.recipientName}
            onChange={(event) => handleChangeFormDetails({ recipientName: event.target.value })}
          />
        </Flex>
      </Flex>
      <Flex direction="row" gap={10}>
        <Flex direction="column">
          <FormLabel>Subject</FormLabel>
          <Input
            value={sendFaxForm.subject}
            width="200px"
            onChange={(event) => handleChangeFormDetails({ subject: event.target.value })}
          />
        </Flex>
        <SelectFile onSelectFile={(value) => handleChangeFormDetails({ files: [...value] })} />
      </Flex>
      <Flex direction="column">
        <FormLabel>Comments</FormLabel>
        <Textarea
          value={sendFaxForm.comment}
          onChange={(event) => handleChangeFormDetails({ comment: event.target.value })}
        />
      </Flex>
      <Button
        alignSelf="center"
        width="100px"
        colorScheme="blue"
        onClick={handleSendFax}
        leftIcon={<SendIcon />}
        disabled={errors !== null}
      >
        Send
      </Button>
    </Flex>
  );
};

export default SendFaxForm;
