import {
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import React from "react";
import { BodyOf, Messages, QueryParamsOf, ResponseOf } from "../../../../core/api";
import RangeDatePicker from "../../../../shared/components/DatePicker/RangeDatePicker";
import FreeTextSearch from "../../../../shared/components/FreeTextSearch";
import MultiSelectMenu from "../../../../shared/components/MultiSelectMenu";
import Page from "../../../../shared/components/Page";
import { createFilters } from "../../../../shared/hooks/useFilters";
import AddRoundedIcon from "../../../../shared/icons/AddRoundedIcon";
import { NoteId, PatientId } from "../../../../shared/schema/schema";
import { FilterProps } from "../../../../shared/utils/filter-props";
import IntakeDashboardTable from "../../components/IntakeDashboardTable";
import IntakePatientProfile from "../../components/IntakePatientProfile";
import NewIntakePatient from "../../components/NewIntakePatient";

type IntakeDashboardQueryParams = QueryParamsOf<"get", "./patient_intake/dashboard">;

interface Props extends FilterProps<"./patient_intake/dashboard"> {
  createIntakePatientDisclosure: UseDisclosureReturn;
  intakePatientProfileDisclosure: UseDisclosureReturn;
  intakePatients: Messages["IntakePatientDashboardDetails"][];
  medicaidStatuses: Messages["MedicaidStatus"][];
  intakeStatuses: ResponseOf<"get", "./patient_intake/intake_status">["intakeStatuses"];
  intakePlans: Messages["IntakePlan"][];
  intakeProfileData: Messages["IntakePatientProfile"] | null;
  eligibilityCheckResults: Messages["EligibilityCheck"][];
  onClickDashboardRow: (patientId: PatientId) => void;
  onCreateNewIntakePatient: (val: BodyOf<"post", "./patient_intake">) => void;
  onCloseIntakeProfile: () => void;
  onClickMarkNoteDone: (noteId: NoteId) => void;
  onClickEligibilityCheck: (patientId: PatientId) => void;
  onEditPatientIntakeProfile: (details: BodyOf<"put", "./patients/:patientId/intake">) => void;
  onAddNewNote: (patientId: PatientId) => void;
}

const IntakeDashboardPage = (props: Props) => {
  const { createMultiSelectFilter, createRangeDatePickerFilter, createFreeTextFilter } =
    createFilters<IntakeDashboardQueryParams>();

  const fromToFilter = createRangeDatePickerFilter({
    label: "Filter By Last Call Range",
    startDate: {
      name: "lastCallFrom",
      value: props.filters.getValueOrNull("lastCallFrom"),
    },
    endDate: {
      name: "lastCallTo",
      value: props.filters.getValueOrNull("lastCallTo"),
    },
    onChange: props.onChangeFilter,
  });

  const medicaidStatusFilter = createMultiSelectFilter({
    name: "medicaidStatus",
    label: "Medicaid Status",
    onChange: props.onChangeFilter,
    options: props.medicaidStatuses.map((x) => ({
      label: x.name,
      value: x.id,
    })),
    value: props.filters.getValueOrNull("medicaidStatus"),
  });

  const intakeStatusFilter = createMultiSelectFilter({
    name: "intakeStatus",
    label: "Intake Status",
    onChange: props.onChangeFilter,
    options: props.intakeStatuses.map((status) => ({
      label: status,
      value: status,
    })),
    value: props.filters.getValueOrNull("intakeStatus"),
  });

  const intakePlansFilter = createMultiSelectFilter({
    name: "plan",
    label: "Plan",
    onChange: props.onChangeFilter,
    options: props.intakePlans.map((plan) => ({
      label: plan.name,
      value: plan.id,
    })),
    value: props.filters.getValueOrNull("plan"),
  });

  const freeTextSearchFilter = createFreeTextFilter({
    placeholder: "Search by patient name, id, etc.",
    name: "freeTextSearch",
    value: props.filters.getValueOrNull("freeTextSearch"),
    onChange: props.onChangeFilter,
    debounce: 250,
  });

  const handleCloseIntakeProfileDrawer = () => {
    props.onCloseIntakeProfile();
  };
  const handleClickDashboardRow = (patientId: PatientId) => {
    props.onClickDashboardRow(patientId);
  };

  const handleClickAddNewNote = (patientId: PatientId) => {
    props.intakePatientProfileDisclosure.onClose();
    window.dispatchEvent(
      new CustomEvent("from-webapp-react", {
        detail: {
          type: "navigate",
          payload: {
            entity: "Note",
            id: patientId,
            callback: () => {
              props.onAddNewNote(patientId);
            },
          },
        },
      })
    );
  };

  return (
    <Page>
      <Page.Header>
        <Heading size={"lg"}>Intake Patient</Heading>
      </Page.Header>
      <Page.Filters>
        <Flex direction="column" gap={3}>
          <Heading size={"sm"}>Filters</Heading>
          <Flex gap={3}>
            <FreeTextSearch {...freeTextSearchFilter} />
            <RangeDatePicker {...fromToFilter} />
            <MultiSelectMenu {...medicaidStatusFilter} />
            <MultiSelectMenu {...intakeStatusFilter} />
            <MultiSelectMenu {...intakePlansFilter} />
            <Button
              left={"0px"}
              ms="auto"
              colorScheme="blue"
              leftIcon={<AddRoundedIcon />}
              onClick={props.createIntakePatientDisclosure.onOpen}
            >
              New Intake patient
            </Button>
          </Flex>
        </Flex>
      </Page.Filters>
      <Divider />
      <Page.Content>
        <IntakeDashboardTable
          patients={props.intakePatients}
          onClickDashboardRow={handleClickDashboardRow}
        />
        <Drawer
          {...props.intakePatientProfileDisclosure}
          onClose={handleCloseIntakeProfileDrawer}
          size="xl"
        >
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerBody>
              {props.intakeProfileData === null ? (
                <div> Loading...</div>
              ) : (
                <IntakePatientProfile
                  patientInfo={props.intakeProfileData}
                  intakePlans={props.intakePlans}
                  intakeStatuses={props.intakeStatuses}
                  eligibilityCheckResults={props.eligibilityCheckResults}
                  onClickPatientLink={props.intakePatientProfileDisclosure.onClose}
                  onClickAddNewNote={handleClickAddNewNote}
                  onClickMarkNoteAsDone={props.onClickMarkNoteDone}
                  onClickEligibilityCheck={props.onClickEligibilityCheck}
                  onEditPatientIntakeProfile={props.onEditPatientIntakeProfile}
                />
              )}
            </DrawerBody>
          </DrawerContent>
          <DrawerOverlay />
        </Drawer>
        {/** Start New Intake Patient Modal */}
        <Drawer {...props.createIntakePatientDisclosure} size="xl">
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Add New Intake Patient</DrawerHeader>
            <DrawerBody>
              <NewIntakePatient onCreateNewIntakePatient={props.onCreateNewIntakePatient} />
            </DrawerBody>
          </DrawerContent>
          <DrawerOverlay />
        </Drawer>
        {/** End New Intake Patient Modal */}
      </Page.Content>
    </Page>
  );
};

export default IntakeDashboardPage;
