import { CallSessionId, CallSessionParticipantId } from "webapp-react/src/shared/schema/schema";
import { Api } from "../../../scripts/services/Api";
import { Endpoint } from "../../../scripts/services/endpoint.service";
import {
  AgentSubscriberResponse,
  CallCenterSubscriberCanAnswerCall,
  CallSessionParticipantEventParams,
  InitiateCallResponse,
} from "../components/call-center.types";

//! @ngInject
export class CallCenterService {
  static readonly $name = "callCenterService";

  constructor(
    private $rootScope: ng.IRootScopeService,
    private endpoint: Endpoint,
    private api: Api
  ) {}

  initiateCallRequest = (subscriberMsisd: string, destination: number) => {
    const url = this.endpoint({
      path: "agencies/:agencyId/agency_members/:agencyMemberId/initiate_call_request",
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
      },
    });

    return this.api.post<InitiateCallResponse>(url, { subscriberMsisd, destination });
  };

  getAgencyMemberCallCenterSubscriber = () => {
    const url = this.endpoint({
      path: "agencies/:agencyId/agency_members/:agencyMemberId/call_center_subscriber",
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
      },
    });

    return this.api.get<AgentSubscriberResponse>(url);
  };

  createParticipantEvent = (callSessionId: string, params: CallSessionParticipantEventParams) => {
    const url = this.endpoint({
      path: "agencies/:agencyId/agency_members/:agencyMemberId/call_center/:callSessionId/event",
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
        callSessionId,
      },
    });

    return this.api.post<InitiateCallResponse>(url, { params });
  };

  connectCustomer = (sessionToken: string, callSessionParticipantId: CallSessionParticipantId) => {
    const url = this.endpoint({
      path: "voice_application/connect_customer",
      params: [],
      queries: {
        medflytCallSession: sessionToken,
      },
    });

    return this.api.post(url, { callSessionParticipantId });
  };

  agencyMemberCanAnswerCall = (callSessionId: CallSessionId) => {
    const url = this.endpoint({
      path: "agencies/:agencyId/agency_members/:agencyMemberId/call_center/:callSessionId/can_answer_call",
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
        callSessionId: callSessionId,
      },
    });

    return this.api.post<CallCenterSubscriberCanAnswerCall>(url);
  };
}
