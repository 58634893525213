import { LocalDate } from "@js-joda/core";
import React from "react";
import { SingleDatePickerProps } from "./SingleDatePicker";

interface Props {
  initialDate?: LocalDate | null;
}

const useSingleDatePicker = (props?: Props) => {
  const [date, setDate] = React.useState<LocalDate | null>(props?.initialDate ?? null);

  const pickerProps: Partial<SingleDatePickerProps> = {
    selected: date,
    onChange: setDate,
  };

  return {
    pickerProps,
    actions: { setDate },
  };
};

export default useSingleDatePicker;
