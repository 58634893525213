import $ from "jquery";
import _ from "lodash";

export const teamMemberTable = {
  templateUrl: "../../admin/views/team-member-table.html",
  controller: teamMemberTableCtrl,
  bindings: {
    tabIndex: "<",
    data: "<",
    offices: "<",
    updateParentSeniors: "&"
  }
};

//! @ngInject
function teamMemberTableCtrl(
  $scope,
  $rootScope,
  DatabaseApi,
  toaster,
  NgTableParams,
  SweetAlert,
  $filter,
  wildcard,
  $uibModal,
  agencyPermissionsRolesService,
  officesService,
  Storage
) {

  $scope.$ctrl.$onInit = () => {
    init(true);
  };

  $scope.searchObj = {};
  $scope.roles = ["Coordinator", "SeniorCoordinator", "HR", "Admin"];
  $scope.roleFilter = "allRoles";
  $scope.settings = {
    email: {
      sendEmailOnCaregiverJoinRequest: 'Send Email on Caregiver Join Request',
      sendEmailOnCaregiverHRPending: 'Send Email on Caregiver HR Pending',
      sendEmailUnreadChatReminder: 'Send Email Unread Chat Reminder',
      sendEmailVisitAwaitingAssignment: 'Send Email Visit Awaiting Assignment',
      sendEmailFileUploaded: 'Send Email File Uploaded',
      sendEmailRNFiles: 'Send Email RN Files',
      sendEmailRNGeneralUploads: 'Send Email RN General Uploads',
      sendEmailDutySheets: 'Send Email Duty Sheets',
      sendEmailPlanOfCare: 'Send Email Plan of Care',
      sendEmailHRFiles: 'Send Email HR Files',
      sendEmailInService: 'Send Email in Service',
      sendEmailNewCommCenterMessage: 'Send New Chat Message Email',
      sendPushNewCommCenterMessage: 'Send New Chat Message Push',
      sendEmailNewAssignedTicket: 'Send New Assigned Ticket Email',
      sendPushNewAssignedTicket: 'Send New Assigned Ticket Push',
      sendEmailNoteAssigned: 'Send Assigned Note Email',
      sendPushNoteAssigned: 'Send Assigned Note Push',
    }
  }
  $scope.securtiySettingTypes = {
    Open: 'Open',
    UserNoRestriction: 'Default',
    WhitelistOnly: 'Restricted'
  }
  // $scope.agencyIpAddressesCount = $rootScope.visitSettings.agencyIpSecuritySetting.ipAddressesWhitelist ?
  // $rootScope.visitSettings.agencyIpSecuritySetting.ipAddressesWhitelist.length : 0;
  $scope.agencyIpAddressesCount = 0;

  var seniorsMap = {};

  $rootScope.$on('got_data', function (event) {
    init(true);
  });

  var membersCache = [];

  async function init(isInitTable){

    $scope.offices = $scope.$ctrl.offices.map(office => ({
      id: office.id,
      label: office.name
    }));
    $scope.allowedChangeRoles = $rootScope.isPermittedByKey('change_user_roles');
    $scope.agencyRoles = await agencyPermissionsRolesService.getAgencyRolesPermissions();
    $scope.agencyRolesOptions = $scope.agencyRoles.map(role => ({ id: role.name, label: role.name }));

    seniorsMap = {};
    var seniors = DatabaseApi.getAgencyMembersByRole().coordinatorsSeniors;
    if (seniors) {
      $scope.seniors = [{ displayName: 'No Senior' }].concat(
        seniors.filter(function (senior) {
          return senior.status !== 'Inactive';
        }
        ));
    }
    $scope.members = $scope.$ctrl.data;
    $scope.seniors && $scope.seniors.forEach(function(s) {
      seniorsMap[s.id] = s;
    });
    $scope.members.forEach(function(m) {
      m.offices = m.officeIds.map(function (officeId) {
        return $scope.offices.find(function(office) {
          return office.id === officeId;
      });
    });

    m.agencyRolesObj = m.agencyRoles.map(roleName => {
      return $scope.agencyRolesOptions.find((role) => {
        return role.id === roleName
      })
    })

      // remove supervisor if not exist in senoirsMap
      if (m.supervisor && !seniorsMap[m.supervisor]) {
        m.supervisor = null;
        m.seniorName = ""
      }
      else if (m.supervisor && seniorsMap[m.supervisor]) {
        m.seniorName = seniorsMap[m.supervisor].displayName || "";
      } else m.seniorName = "";
    });
    membersCache = _.cloneDeep($scope.members);

    if(isInitTable) {
      initTable();
    }
  }

  $scope.getSeniorName = function(id) {
    return seniorsMap[id] ? seniorsMap[id].displayName : "";
  };

  $scope.$ctrl.$onChanges = function(changes) {
    if (_areElementsResolved(changes)) {
      init(true);
    }
  };

  function _areElementsResolved(changes) {
    return Object.keys(changes).length > 0
  }

  $scope.reloadTable = function() {
    $scope.table.filter({ $: $scope.searchObj.filterByName });
  };

  $scope.changeSetting = function(agencyMember, key, event) {
    var checked = event.currentTarget.checked;
    var badge = $('button[data-agency-member-id="' + agencyMember.id + '"] > .badge').get(0);
    var countEnabled = parseInt(badge.innerText === 'All' ? Object.keys($scope.settings.email).length : badge.innerText, 10);

    // Since ng-table doesn't automatically update its dataset in the current version,
    // I have manually changed the DOM. This issue has been fixed in later versions of ng-table
    badge.innerText = checked
      ? (countEnabled + 1) === Object.keys($scope.settings.email).length // If all of the setting are enabled
        ? 'All' // Set "All"
        : countEnabled + 1 // Otherwise, incremenet the current value by 1
      : countEnabled - 1; // Decrement if the agent has unchecked

    var url = wildcard('agencies/:agencyId/agency_member/:agencyMemberId/settings', $rootScope.agencyId, agencyMember.id);

    var body = {};
    body[key] = checked;

    DatabaseApi.patch(url, body)
      .then(function (res) {
        agencyMember.settings.email[key] = checked;
        toaster.pop('success', "Success", "Agency Email Settings has been updated successfully");
      })
      .catch(function (err) {
          toaster.pop('error', "Something went wrong", "could not edit agent member");
      });
  }

  $scope.removeMember = function(i, id) {
    SweetAlert.swal(
      {
        title: "Remove This Member?",
        text:
          "Note!! This user will not be able to log in to your agency anymore and will not have any access to your agency, are you sure?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes, remove member",
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function(isConfirm) {
        if (isConfirm) {
          //$scope.members[i].removed = true;
          //$scope.members[i].verified = false;
          DatabaseApi.delete(
            "agencies/" +
              $rootScope.agencyId +
              "/coordinators/" +
              id +
              "/active"
          ).then(
            function(res) {
              $scope.$ctrl.data.forEach(function(member) {
                if (member.id === id) {
                  member.status = 'Inactive';
                }
              });
              init(true);
            }).catch(function(err) {
              if (err.status === 409) {
                setTimeout(() => {
                  return SweetAlert.swal({
                      title: "Member is assigned to patients",
                      text: "You need to assign patients to a different coordinator",
                      type: "error",
                      showCancelButton: true,
                      confirmButtonColor: "#DD6B55",
                      confirmButtonText: "Ok, choose coordinator",
                      closeOnConfirm: true,
                      closeOnCancel: true,
                    }, (confirmed) => {
                      if (!confirmed) return;

                      const newScope = $scope.$new();
                      newScope.patients = err.data.patients;
                      newScope.originCoordinatorId = id;
                  
                      const modalInstance = $uibModal.open({
                          templateUrl: 'admin/views/assign-patient-coordinator-modal.html',
                          size: 'md',
                          controller: 'assignPatientCoordinatorModalCtrl',
                          scope: newScope,
                          windowClass: "assign-patient-container"
                      });

                      modalInstance.result.then(function (res) {
                          $scope.$ctrl.data.forEach(function(member) {
                            if (member.id === id) {
                              member.status = 'Inactive';
                            }
                          });
                          init(true);
                        }, function () {/*Dismissed*/});
                    });
                  }, 100);
              } else {
                let errorString = "Please try again";
                if (err.data && err.data.error) {
                  errorString = err.data.error || errorString;
                }
  
                toaster.pop("error", "Something went wrong", errorString);
              }
            }
          );
        }
      }
    );
  };

  $scope.reinviteMember = function (row) {
    SweetAlert.swal(
      {
        title: "Reinvite This Member?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#23c6c8",
        confirmButtonText: "Yes",
        closeOnConfirm: true,
        closeOnCancel: true,
      },
      function (confirmed) {
        if (!confirmed) return;

        DatabaseApi.put(
          `agencies/${$rootScope.agencyId}/agency_members/${row.id}/reinvite`
        )
          .then(() =>
            toaster.pop(
              "success",
              "Success",
              "New team member has been invited"
            )
          )
          .catch((err) =>
            toaster.pop("error", "Something went wrong", err.data.error)
          );
      }
    );
  };

  $scope.activateMember = function(i, id) {
    SweetAlert.swal(
      {
        title: "Activate This Member?",
        text:
          "Note!! This user will be able to log in to your agency and will have access to your agency, are you sure?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes, activate member",
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function(isConfirm) {
        if (isConfirm) {
          //$scope.members[i].removed = true;
          //$scope.members[i].verified = false;
          DatabaseApi.put(
            "agencies/" +
              $rootScope.agencyId +
              "/coordinators/" +
              id +
              "/active"
          ).then(
            function(res) {
              $scope.$ctrl.data.forEach(function(member) {
                if (member.id === id) {
                  member.status = 'Active';
                }
              });
              init(true);
              // $state.reload();
              // DatabaseApi.updateAgencyMembersByRole($scope.$ctrl.data);
              //$scope.members[i] = res.data;
            },
            function(err) {
              toaster.pop("error", "Something went wrong", "Please try again");
            }
          );
        }
      }
    );
  };

  function initTable () {
    var tabIndex = $scope.$ctrl.tabIndex;
    var data = $scope.$ctrl.data.map(attachExtraData);
    var filteredData = data.filter(function(item) {
      if ((tabIndex == 0 && item.status === "Inactive") || (tabIndex == 1 && item.status !== "Inactive")) {
        return false;
      }
      if ($scope.roleFilter === "allRoles") {
        return true;
      }
      return item.jobTitle === $scope.roleFilter;
    });

    var sorting = { firstName: "asc" };
    if ($scope.table) {
      sorting = $scope.table.sorting();
    }

    $scope.table = new NgTableParams(
      {
        count: 25,
        sorting: sorting
      },
      {
        counts: [],
        dataset: filteredData
      }
    );
  }

  function attachExtraData(item) {
    item.extra = {};

    // Counts the amount of email settings that are turned on (null[unset] or true)
    item.extra.countEnabledEmailSettings = Object.keys(item.settings.email).filter(function (emailSetting) {
      return item.settings.email[emailSetting] !== false;
    }).length,

    // Indicates if all of the settings are set to true
    item.extra.hasAllEmailSettings = item.extra.countEnabledEmailSettings === Object.keys(item.settings.email).length

    return item;
  }

  $scope.changeRole = function(i, id, role) {
    let hasActiveSenior = false;
    var idx;

    membersCache.forEach(function(m, mIndex) {
        if (m.id === id) idx = mIndex;
    });

    if (role !== "SeniorCoordinator") {
      for (var i = 0; i < $scope.members.length; i++) {
        if ($scope.members[i].status === "Active" && $scope.members[i].supervisor === id) {
          hasActiveSenior = true;
          break;
        }
      }
    }

    console.log(hasActiveSenior);

    SweetAlert.swal(
      {
        title: "Change role?",
        text: hasActiveSenior ? $scope.members[idx].firstName + " is a senior coordinator for active members. Are you sure you want to continue?" : undefined,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes, change role",
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function(isConfirm) {
        if (isConfirm) {
          //$scope.members[i].removed = true;
          //$scope.members[i].verified = false;
          var body = {
            jobTitle: role
          };
          DatabaseApi.post(
            "agencies/" +
              $rootScope.agencyId +
              "/coordinators/" +
              id +
              "/change_role",
            body
          ).then(
            function(res) {
              DatabaseApi.updateAgencyMembersByRole($scope.$ctrl.data);
              membersCache = _.cloneDeep($scope.members);
              $scope.$ctrl.updateParentSeniors();
              init();
              toaster.pop('success', "Success", "Agency member Role has been updated successfully");
            },
            function(err) {
              toaster.pop("error", "Something went wrong", "Please try again");
            }
          );
        } else {
          $scope.members[idx].jobTitle = membersCache[idx].jobTitle;
        }
      }
    );
  };

  $scope.promoteToSenior = function (memberId) {
    var idx;

    membersCache.forEach(function(m, mIndex) {
        if (m.id === memberId) idx = mIndex;
    });

    var body = {
      jobTitle: 'SeniorCoordinator'
    };

    DatabaseApi.post("agencies/" + $rootScope.agencyId + "/coordinators/" + memberId + "/change_role", body).then(
      function (res) {
        DatabaseApi.updateAgencyMembersByRole($scope.$ctrl.data);
        $scope.members[idx].jobTitle = 'SeniorCoordinator';
        membersCache = _.cloneDeep($scope.members);
        init(true);
        toaster.pop('success', "Success", "Agency member promoted to Senior Coordinator");
      },
      function (err) {
        toaster.pop("error", "Something went wrong", "Please try again");
      }
    );
  }

  $scope.updateSenior = function (row) {
    if (row.supervisor) {
      DatabaseApi.put("agencies/" + $rootScope.agencyId + "/coordinators/" + row.id + "/supervisor/" + row.supervisor).then(
        function(res) {
          // DatabaseApi.updateAgencyMembersByRole($scope.$ctrl.data);
          toaster.pop('success', "Success", "Agency member Senior has been updated successfully");
        },
        function(err) {
          toaster.pop("error", "Something went wrong", "Please try again");
        }
      );
    } else {
      DatabaseApi.delete("agencies/" + $rootScope.agencyId + "/coordinators/" + row.id + "/supervisor").then(
        function(res) {
          toaster.pop('success', "Success", "Agency member Senior has been removed successfully");
        },
        function(err) {
          toaster.pop("error", "Something went wrong", "Please try again");
        }
      );
    }
  };

  $scope.unblockCaregiverLogin = function(agencyMember) {
    DatabaseApi.get(
      "agencies/" +
        $rootScope.agencyId +
        "/agency_member/" +
        agencyMember.id +
        "/unblock_login"
    ).then(
      function(res) {
        var realIndex = $scope.members
          .map(function(e) {
            return e.id;
          })
          .indexOf(agencyMember.id);
        $scope.members[realIndex].isUserLoginBlocked = false;
        toaster.pop("success", "Agency member unblocked", "");
      },
      function(err) {
        toaster.pop("error", "Something went wrong", "Please try again");
      }
    );
  };

  $scope.filterChange = function () {
    initTable();
  }

  $scope.exportTable = function () {
    var rows = [];
    var titles = [
      'First Name',
      'Last Name',
      'Role',
      'Senior Coordinator',
      'Email',
      'Agency Roles',
      'Offices',
      'Invitation Email sent',
      'IP Security Settings',
      'Status'
    ];

    rows.push(titles);

    // Filter table data by global filter
    const tableData = $filter('filter')($scope.$ctrl.data, $scope.searchObj.filterByName);
    tableData.forEach(function (teamMember) {
      let row = [];

      const officesNames = teamMember.offices = teamMember.officeIds.map(officeId => {
        return $scope.offices.find(office => {
          return (office.id === officeId);
        }).label;
      });

      const ipSecurityType = teamMember.agencyMemberIpSecuritySettings.type;
      const ipSecuritySettings = ipSecurityType === 'WhitelistOnly' ?
        `"WhitelistOnly: ${teamMember.agencyMemberIpSecuritySettings.ipAddressesWhitelist}"` : ipSecurityType;

      titles.forEach(function (title) {
        if (title === 'First Name') row.push(`"${teamMember.firstName}"`);
        else if (title === 'Last Name') row.push(`"${teamMember.lastName}"`);
        else if (title === 'Role') row.push(teamMember.jobTitle || '');
        else if (title === 'Senior Coordinator') row.push($scope.getSeniorName(teamMember.supervisor) || '');
        else if (title === 'Email') row.push(teamMember.email || '');
        else if (title === 'Agency Roles') row.push(`"${teamMember.agencyRoles}"`);
        else if (title === 'Offices') row.push(`"${officesNames}"`);
        else if (title === 'Invitation Email sent') row.push($filter("mfShortDate")(teamMember.createdAt) || '');
        else if (title === 'IP Security Settings') row.push(ipSecuritySettings);
        else if (title === 'Status') row.push(teamMember.status || '');
      });
      rows.push(row);
    });

    let csvContent = "data:text/csv;charset=utf-8,";
    rows.forEach(function(rowArray){
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "medflyt-team-members-export.csv");
    document.body.appendChild(link); // Required for FF

    link.click();
  }

  $scope.openSecuritySettingModal = function (member) {
    var newScope = $scope.$new();
    newScope.cachedMember = member;
    newScope.member = angular.copy(member);

    $uibModal.open({
      templateUrl: 'admin/views/agency-member-security-settings-modal.html',
      size: 'md',
      controller: 'agencyMemberSecuritySettingsModalCtrl',
      scope: newScope
    });
  }

  $scope.setSelectedOfficesAgencyMember = function(agencyMemberId) {
    if($scope.editableOfficesAgencyMember === agencyMemberId ) {
      return;
    }
    $scope.editableOfficesAgencyMember = agencyMemberId;
  }

  $scope.agencyMemberOfficesEvents = {
    onItemSelect: function (item) {
      updateAgencyMembersOffices([item.id], "ASSOCIATE");
    },

    onItemDeselect(item) {
      updateAgencyMembersOffices([item.id], "DISSOCIATE");
    },

    onSelectAll() {
      var officeIds  = $scope.offices.map(office => office.id)
      updateAgencyMembersOffices(officeIds, "ASSOCIATE");
    },

    onDeselectAll() {
      var officeIds  = $scope.offices.map(office => office.id)
      updateAgencyMembersOffices(officeIds, "DISSOCIATE");
    }
  }

  function updateAgencyMembersOffices(officeIds, associateType) {
    var reqObject = {
      associations: [],
    };

    reqObject.associations = officeIds.map((officeId) => ({
      agencyMemberId: $scope.editableOfficesAgencyMember,
      officeId: officeId,
      type: associateType,
    }));

    officesService
      .updateAgencyMembersOffices(
        $rootScope.agencyId,
        $rootScope.agencyMemberId,
        reqObject
      )
      .then(() =>
        toaster.pop(
          "success",
          "Success",
          "Agency members offices updated successfully"
        )
      )
      .catch((err) => {
        switch (err.status) {
          case 403:
            toaster.pop("error", "Only an admin can change member's offices");
            break;
          default:
            toaster.pop("error", "Failed to update agency members offices");
        }
      });
  }

  const editAgencyMemberRole = (AgencyMember, roles, type) => {
    agencyPermissionsRolesService.editAgencyMemberRole(AgencyMember, roles, type).then(() => 
      toaster.pop(
        "success",
        "Success",
        "Agency members role(s) updated successfully"
      )
    ).catch((err) => {
      switch (err.status) {
        case 403:
          toaster.pop("error", "Only an admin can change member's role");
          break;
        default:
          toaster.pop("error", "Failed to update agency members role");
      }
    });
  }

  $scope.setSelectedRoleAgencyMember = function(agencyMemberId) {
    if($scope.editableRoleAgencyMember === agencyMemberId ) {
      return;
    }
    $scope.editableRoleAgencyMember = agencyMemberId;
  }

  $scope.agencyRoleEvents = {
    onItemSelect: function (item) {
      editAgencyMemberRole($scope.editableRoleAgencyMember, [item.id], "ASSOCIATE");
    },

    onItemDeselect(item) {
      editAgencyMemberRole($scope.editableRoleAgencyMember, [item.id], "DISSOCIATE");
    },

    onSelectAll() {
      const roles  = $scope.agencyRolesOptions.map(role => role.id)
      editAgencyMemberRole($scope.editableRoleAgencyMember, roles, "ASSOCIATE");
    },

    onDeselectAll() {
      const roles  = $scope.agencyRolesOptions.map(role => role.id)
      editAgencyMemberRole($scope.editableRoleAgencyMember, roles, "DISSOCIATE");
    }
  }

  $scope.openTeamMemberChangePasswordModal = (row) => {
    const newScope = $scope.$new();
    newScope.agencyMember = row;

    $uibModal.open({
      templateUrl: 'admin/views/change-team-member-password-modal.html',
      size: 'md',
      controller: 'changeTeamMemberPasswordModalCtrl',
      scope: newScope
    });
  };

  $scope.openHistoryRowIndex = undefined;
  $scope.toggleHistory = (rowIndex) => {
    $scope.openHistoryRowIndex = rowIndex === $scope.openHistoryRowIndex ? undefined : rowIndex;
  }
}
