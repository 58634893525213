import { Button, Flex, FormControl, FormErrorMessage, Text, Textarea } from "@chakra-ui/react";
import React from "react";
import { Controller } from "react-hook-form";
import SelectMenu from "../../../../shared/components/SelectMenu";
import { CommCenterTicketId } from "../../../../shared/schema/schema";
import useCallCenterNoteCache from "../../hooks/useCallCenterNoteCache";
import useCallTicketNotes from "./hooks/useCallTicketNotes";

type Props = ReturnType<typeof useCallTicketNotes> & { ticketId: CommCenterTicketId };

const CallNotesSection = ({
  formState,
  control,
  subjectIdSelectFilter,
  ticketId,
  onSubmit,
  clearForm,
}: Props) => {
  const { setTicketNoteCache } = useCallCenterNoteCache();
  return (
    <Flex w={"full"} direction={"column"} gap={4} fontSize={"md"} alignItems={"center"}>
      <FormControl isInvalid={formState.errors.text !== undefined}>
        <Controller
          control={control}
          name="text"
          render={({ field }) => (
            <>
              <Textarea
                w={"100%"}
                placeholder={"Write your note..."}
                {...field}
                onChange={(e) => {
                  setTicketNoteCache(ticketId, { text: e.currentTarget.value });
                  field.onChange(e.currentTarget.value);
                }}
              ></Textarea>
              <FormErrorMessage>{formState.errors.text?.message}</FormErrorMessage>
            </>
          )}
        />
      </FormControl>
      <FormControl>
        <SelectMenu
          closeOnSelect={true}
          buttonProps={{ w: "full" }}
          {...subjectIdSelectFilter}
          onChange={(e) => {
            setTicketNoteCache(ticketId, {
              noteSubjectId: e ?? null,
            });
            subjectIdSelectFilter.onChange(e);
          }}
        />
        <Text color={"red"}>{formState.errors.subjectId?.message}</Text>
      </FormControl>
      <FormControl as={Flex} gap={4}>
        <Button flex={1} colorScheme={"blue"} type={"submit"} onClick={onSubmit}>
          Add Note
        </Button>
        <Button flex={1} onClick={clearForm}>
          Clear
        </Button>
      </FormControl>
    </Flex>
  );
};

export default CallNotesSection;
