import angular from "angular";
import { CallCenterCallWidgetCtrl } from "./admin/modules/call-center/controllers/callCenterCallWidgetCtrl";
import { switchEnv } from "./admin/scripts/components/switchEnv";
import { _8850ModalCtrl } from "./admin/scripts/controllers/8850ModalCtrl";
import { acceptBulkVisitIInstancesChangesCtrl } from "./admin/scripts/controllers/acceptBulkVisitIInstancesChangesCtrl";
import { addCaregiverCtrl } from "./admin/scripts/controllers/addCaregiverCtrl";
import { addFileTypeModalCtrl } from "./admin/scripts/controllers/addFileTypeModalCtrl";
import { addHrDocumentModalCtrl } from "./admin/scripts/controllers/addHrDocumentModalCtrl";
import { agencyBranchSettingModalCtrl } from "./admin/scripts/controllers/agencyBranchSettingModalCtrl";
import { agencyCompleteFormModalCtrl } from "./admin/scripts/controllers/agencyCompleteFormModalCtrl";
import { agencyHolidayModalCtrl } from "./admin/scripts/controllers/agencyHolidayModalCtrl";
import { agencyMemberMentionsDoneCtrl } from "./admin/scripts/controllers/agencyMemberMentionsDoneCtrl";
import { agencyMemberMentionsInboxCtrl } from "./admin/scripts/controllers/agencyMemberMentionsInboxCtrl";
import { agencyMemberSecuritySettingsModalCtrl } from "./admin/scripts/controllers/agencyMemberSecuritySettingsModalCtrl";
import { agencyPermissionRoleModalCtrl } from "./admin/scripts/controllers/agencyPermissionRoleModalCtrl";
import { agencyPhysicianModalCtrl } from "./admin/scripts/controllers/agencyPhysicianModal";
import { agencyProductModalCtrl } from "./admin/scripts/controllers/agencyProductModalCtrl";
import { agencyPTOSettingModalCtrl } from "./admin/scripts/controllers/agencyPTOSettingModalCtrl";
import { agencySettingsModal } from "./admin/scripts/controllers/agencySettingsModal";
import { agencyTeamModalCtrl } from "./admin/scripts/controllers/agencyTeamModalCtrl";
import { anomaliesCtrl } from "./admin/scripts/controllers/anomaliesCtrl";
import { applicantDownloadModalCtrl } from "./admin/scripts/controllers/applicantDownloadModalCtrl";
import { applicationFullProfileSubsectionsSelectionModalCtrl } from "./admin/scripts/controllers/applicationFullProfileSubsectionsSelectionModalCtrl";
import { approveCaregiverModalCtrl } from "./admin/scripts/controllers/approveCaregiverModalCtrl";
import { approveDutySheetsClockTimesModalCtrl } from "./admin/scripts/controllers/approveDutySheetsClockTimesModalCtrl";
import { approveVisitInstanceDeleteModalCtrl } from "./admin/scripts/controllers/approveVisitInstanceDeleteModalCtrl";
import { arClaimStatusSetupModalCtrl } from "./admin/scripts/controllers/arClaimStatusSetupModalCtrl";
import { arCollectionStatusSetupModalCtrl } from "./admin/scripts/controllers/arCollectionStatusSetupModalCtrl";
import { arReasonSetupModalCtrl } from "./admin/scripts/controllers/arReasonSetupModalCtrl";
import { assignModalCtrl } from "./admin/scripts/controllers/assignModal";
import { assignPatientCoordinatorModalCtrl } from "./admin/scripts/controllers/assignPatientCoordinatorModalCtrl";
import { authorizationSetupModalCtrl } from "./admin/scripts/controllers/authorizationSetupModalCtrl";
import { authorizationUtilizationModalCtrl } from "./admin/scripts/controllers/authorizationUtilizationModalCtrl";
import { BackOfficeCtrl } from "./admin/scripts/controllers/BackOfficeCtrl";
import { billingAccountsReceivableCtrl } from "./admin/scripts/controllers/billingAccountsReceivableCtrl";
import { billingAttachCheckVisitsModalCtrl } from "./admin/scripts/controllers/billingAttachCheckVisitsModalCtrl";
import { billingCheckDetailsCtrl } from "./admin/scripts/controllers/billingCheckDetailsCtrl";
import { billingChecksCtrl } from "./admin/scripts/controllers/billingChecksCtrl";
import { billingExportDetailsCtrl } from "./admin/scripts/controllers/billingExportDetailsCtrl";
import { billingExportedInvoicesModalCtrl } from "./admin/scripts/controllers/billingExportedInvoicesModalCtrl";
import { billingExportsCtrl } from "./admin/scripts/controllers/billingExportsCtrl";
import { billingInvoiceDetailsCtrl } from "./admin/scripts/controllers/billingInvoiceDetailsCtrl";
import { billingInvoicesCtrl } from "./admin/scripts/controllers/billingInvoicesCtrl";
import { billingIssuesTemplateSetupModalCtrl } from "./admin/scripts/controllers/billingIssuesTemplateSetupModalCtrl";
import { billingNewInvoiceBatchCtrl } from "./admin/scripts/controllers/billingNewInvoiceBatchCtrl";
import { billingNewManualCheckModalCtrl } from "./admin/scripts/controllers/billingNewManualCheckModalCtrl";
import { billingNewPayrollBatchModalCtrl } from "./admin/scripts/controllers/billingNewPayrollBatchModalCtrl";
import { billingPayrollDetailsCtrl } from "./admin/scripts/controllers/billingPayrollDetailsCtrl";
import { billingPayrollsCtrl } from "./admin/scripts/controllers/billingPayrollsCtrl";
import { billingRateSetupModalCtrl } from "./admin/scripts/controllers/billingRateSetupModalCtrl";
import { billingVisitAdjustmentApprovalsCtrl } from "./admin/scripts/controllers/billingVisitAdjustmentApprovalsCtrl";
import { billingVisitsToExportCtrl } from "./admin/scripts/controllers/billingVisitsToExportCtrl";
import { billingVisitsWithIssuesCtrl } from "./admin/scripts/controllers/billingVisitsWithIssuesCtrl";
import { boostModalCtrl } from "./admin/scripts/controllers/boostModalCtrl";
import { buyCaregiverModalCtrl } from "./admin/scripts/controllers/buyCaregiverModalCtrl";
import { calendarNewVisitModalCtrl } from "./admin/scripts/controllers/calendarNewVisitModalCtrl";
import { callsCtrl } from "./admin/scripts/controllers/callsCtrl";
import { callsMaintenanceCtrl } from "./admin/scripts/controllers/callsMaintenanceCtrl";
import { callsMissedBothCtrl } from "./admin/scripts/controllers/callsMissedBothCtrl";
import { callsMissedInCtrl } from "./admin/scripts/controllers/callsMissedInCtrl";
import { callsMissedOutCtrl } from "./admin/scripts/controllers/callsMissedOutCtrl";
import { caregiverDocumentSendPdfModalCtrl } from "./admin/scripts/controllers/caregiverDocumentSendPdfModalCtrl";
import { caregiverPayRateModalCtrl } from "./admin/scripts/controllers/caregiverPayRateModalCtrl";
import { caregiverPtoLabelSetupModalCtrl } from "./admin/scripts/controllers/caregiverPtoLabelSetupModalCtrl";
import { caregiverRegenerateCertificateModalCtrl } from "./admin/scripts/controllers/caregiverRegenerateCertificateModalCtrl";
import { caregiversPendingApplicationCtrl } from "./admin/scripts/controllers/caregiversPendingApplicationCtrl";
import { CaregiversTrainingCenterOverview } from "./admin/scripts/controllers/caregiversTrainingCenterOverview";
import { caregiverTrainingCenterAddBundleModalCtrl } from "./admin/scripts/controllers/caregiverTrainingCenterAddBundleModalCtrl";
import { careManagementCtrl } from "./admin/scripts/controllers/careManagementCtrl";
import { certificationPeriodModal } from "./admin/scripts/controllers/certificationPeriodModal";
import { certificationPeriodsCtrl } from "./admin/scripts/controllers/certificationPeriodsCtrl";
import { changeTeamMemberPasswordModalCtrl } from "./admin/scripts/controllers/changeTeamMemberPasswordModalCtrl";
import { cityTaxCodeCtrl } from "./admin/scripts/controllers/cityTaxCodeCtrl";
import { claimsConversionCtrl } from "./admin/scripts/controllers/claimsConversionCtrl";
import { clientSurveyModalCtrl } from "./admin/scripts/controllers/clientSurveyModalCtrl";
import { clockedVisitsCtrl } from "./admin/scripts/controllers/clockedVisitsCtrl";
import { clockinClockoutBulkLinkModalCtrl } from "./admin/scripts/controllers/clockinClockoutBulkLinkModalCtrl";
import { clockinClockoutLinkCallModalCtrl } from "./admin/scripts/controllers/clockinClockoutLinkCallModalCtrl";
import { clockLocationBoundCtrl } from "./admin/scripts/controllers/clockLocationBoundCtrl";
import {
  CaregiverExtraInfoModalCtrl,
  communicationCtrl,
} from "./admin/scripts/controllers/communicationCtrl";
import {
  algoModalCtrl,
  confirmationsCtrl,
  confirmModalCtrl,
} from "./admin/scripts/controllers/confirmationsCtrl";
import { contractSetupModalCtrl } from "./admin/scripts/controllers/contractSetupModalCtrl";
import { copyCertificationSettingsCtrl } from "./admin/scripts/controllers/copyCertificationSettingsCtrl";
import { coriModalCtrl } from "./admin/scripts/controllers/coriModalCtrl";
import { coronaReportStatusModalCtrl } from "./admin/scripts/controllers/coronaReportStatusModalCtrl";
import { coronaVirusReportsCtrl } from "./admin/scripts/controllers/coronaVirusReportsCtrl";
import { countiesModalCtrl } from "./admin/scripts/controllers/countiesModalCtrl";
import { countyMapController } from "./admin/scripts/controllers/countyMapDirective";
import { createNoteModalCtrl } from "./admin/scripts/controllers/createNoteModalCtrl";
import { createSimpleNoteModalCtrl } from "./admin/scripts/controllers/createSimpleNoteModalCtrl";
import { customerEngagementCtrl } from "./admin/scripts/controllers/customerEngagementCtrl";
import { customFieldSettingModalCtrl } from "./admin/scripts/controllers/customFieldSettingModalCtrl";
import { dashboardCtrl } from "./admin/scripts/controllers/dashboardCtrl";
import { diagnosisCodeSetupModalCtrl } from "./admin/scripts/controllers/diagnosisCodeSetupModalCtrl";
import { displayIdSettingsModalCtrl } from "./admin/scripts/controllers/displayIdSettingsModalCtrl";
import { docsExpirationsCtrl } from "./admin/scripts/controllers/docsExpirationsCtrl";
import { dragFileModal } from "./admin/scripts/controllers/dragFileModal";
import { dueDateForCaregiverModalCtrl } from "./admin/scripts/controllers/dueDateForCaregiverModalCtrl";
import { dutySheetModalCtrl } from "./admin/scripts/controllers/dutySheetModal";
import { dutySheetsCtrl } from "./admin/scripts/controllers/dutySheetsCtrl";
import { ediComparisonCtrl } from "./admin/scripts/controllers/ediComparisonCtrl";
import { editAddressPhonenumberModalCtrl } from "./admin/scripts/controllers/editAddressPhonenumberModalCtrl";
import { editCaregiverStatusModalCtrl } from "./admin/scripts/controllers/editCaregiverStatusModalCtrl";
import {
  editPatientDocModalBodyDrawingController,
  editPatientDocModalCtrl,
  editPatientDocModalPatientSignatureController,
  editPatientDocModalSelectController,
  editPatientDocModalSignatureController,
  editPatientDocModalTimeController,
} from "./admin/scripts/controllers/editPatientDocModalCtrl";
import { emailModalCtrl } from "./admin/scripts/controllers/emailModalCtrl";
import { engagementKPICtrl } from "./admin/scripts/controllers/engagementKPICtrl";
import { entityGeneralNotesModalCtrl } from "./admin/scripts/controllers/entityGeneralNotesModalCtrl";
import { entityNoteCommentsModalCtrl } from "./admin/scripts/controllers/entityNoteCommentsModalCtrl";
import { eTimeSheetApprovalCtrl } from "./admin/scripts/controllers/eTimeSheetApprovalCtrl";
import { ExclusionListActionsModalCtrl } from "./admin/scripts/controllers/exclusionListActionsModalCtrl";
import { exclusionListModalCtrl } from "./admin/scripts/controllers/exclusionListModal";
import { ExclusionListsCtrl } from "./admin/scripts/controllers/exclusionListsCtrl";
import { exportCaregiverProfileModal } from "./admin/scripts/controllers/exportCaregiverProfileModal";
import { exportDutySheetDataModal } from "./admin/scripts/controllers/exportDutySheetDataModal";
import { ExportDutySheetDataModalCtrl } from "./admin/scripts/controllers/exportDutySheetDataModalCtrl";
import { exportPatientProfileModal } from "./admin/scripts/controllers/exportPatientProfileModal";
import { faxModalCtrl } from "./admin/scripts/controllers/faxModalCtrl";
import { feedCtrl } from "./admin/scripts/controllers/feedCtrl";
import { fileUploaderCtrl } from "./admin/scripts/controllers/fileUploaderCtrl";
import { flyersCtrl } from "./admin/scripts/controllers/flyersCtrl";
import { globalSearchCtrl } from "./admin/scripts/controllers/globalSearchCtrl";
import { hhaIntegrationBillingCtrl } from "./admin/scripts/controllers/hhaIntegrationBillingCtrl";
import { hhaIntegrationMergePatientModal } from "./admin/scripts/controllers/hhaIntegrationMergePatientModal";
import { hhaIntegrationNonBillingCtrl } from "./admin/scripts/controllers/hhaIntegrationNonBillingCtrl";
import { hhaIntegrationUnlinkedPatientsCtrl } from "./admin/scripts/controllers/hhaIntegrationUnlinkedPatientsCtrl";
import { hhaxMergeAuthModal } from "./admin/scripts/controllers/hhaxMergeAuthModal";
import { historyTimelineModalCtrl } from "./admin/scripts/controllers/historyTimelineModalCtrl";
import { hoursUsageCtrl } from "./admin/scripts/controllers/hoursUsageCtrl";
import { hrSettingsCtrl } from "./admin/scripts/controllers/hrSettingsCtrl";
import { I9ModalCtrl } from "./admin/scripts/controllers/I9ModalCtrl";
import { imageViewerModalCtrl } from "./admin/scripts/controllers/imageViewerModalCtrl";
import { integrationFileRecordModalCtrl } from "./admin/scripts/controllers/integrationFileRecordModalCtrl";
import { inviteCtrl } from "./admin/scripts/controllers/inviteCtrl";
import { invoicedVisitNotesModalCtrl } from "./admin/scripts/controllers/invoicedVisitNotesModalCtrl";
import { invoicedVisitsAddNotesModalCtrl } from "./admin/scripts/controllers/invoicedVisitsAddNotesModalCtrl";
import { linkedPatientSetupModalCtrl } from "./admin/scripts/controllers/linkedPatientSetupModalCtrl";
import { liveEventModalCtrl } from "./admin/scripts/controllers/liveEventModalCtrl";
import { mainCtrl } from "./admin/scripts/controllers/mainCtrl";
import { mapModalCtrl } from "./admin/scripts/controllers/mapModalCtrl";
import { masterWeekCtrl } from "./admin/scripts/controllers/masterWeekCtrl";
import { masterWeekModalCtrl } from "./admin/scripts/controllers/masterWeekModalCtrl";
import { MatchingCaregiversModalCtrl } from "./admin/scripts/controllers/matchingCaregiversModalCtrl";
import { medflytPassportCtrl } from "./admin/scripts/controllers/medflytPassportCtrl";
import { mergePatientsCtrl } from "./admin/scripts/controllers/mergePatientsCtrl";
import { mySavedReportsCtrl } from "./admin/scripts/controllers/mySavedReportsCtrl";
import { newAgencyMedicineModalCtrl } from "./admin/scripts/controllers/newAgencyMedicineModalCtrl";
import { newCaregiverAbsenceModalCtrl } from "./admin/scripts/controllers/newCaregiverAbsenceModalCtrl";
import { newPatientAddressModalCtrl } from "./admin/scripts/controllers/newPatientAddressModalCtrl";
import { newPatientPhysicianModalCtrl } from "./admin/scripts/controllers/newPatientPhysicianModalCtrl";
import { newPatientTaskModalCtrl } from "./admin/scripts/controllers/newPatientTaskModalCtrl";
import { newPatientTaskTemplateModalCtrl } from "./admin/scripts/controllers/newPatientTaskTemplateModalCtrl";
import { newPatientVacationModalCtrl } from "./admin/scripts/controllers/newPatientVacationModalCtrl";
import { newPhonenumberModalCtrl } from "./admin/scripts/controllers/newPhonenumberModalCtrl";
import { newPhysicianModalCtrl } from "./admin/scripts/controllers/newPhysicianModalCtrl";
import { NewPlanOfCareModalCtrl } from "./admin/scripts/controllers/newPlanOfCareModalCtrl";
import { newSubSectionModalCtrl } from "./admin/scripts/controllers/newSubsectionModalCtrl";
import { newVisitCtrl } from "./admin/scripts/controllers/newVisitCtrl";
import { newVisitSuggestedCaregiversModalCtrl } from "./admin/scripts/controllers/newVisitSuggestedCaregiversModalCtrl";
import { NotesCtrl } from "./admin/scripts/controllers/notesCtrl";
import { notesReportCtrl } from "./admin/scripts/controllers/notesReportCtrl";
import { notesSettingsCtrl } from "./admin/scripts/controllers/notesSettingsCtrl";
import { noteSubjectSetupModalCtrl } from "./admin/scripts/controllers/noteSubjectSetupModalCtrl";
import { nursingQuestionStatisticsModalCtrl } from "./admin/scripts/controllers/nursingQuestionStatisticsModalCtrl";
import { officeSetupModalCtrl } from "./admin/scripts/controllers/officeSetupModalCtrl";
import { orientationCenterCtrl } from "./admin/scripts/controllers/orientationCenterCtrl";
import { orientationItemPreviewModalCtrl } from "./admin/scripts/controllers/orientationItemPreviewModalCtrl";
import { overtimeCalculatorModalCtrl } from "./admin/scripts/controllers/overtimeCalculatorModal";
import { passportVerifierCtrl } from "./admin/scripts/controllers/passportVerifierCtrl";
import { patientAlertsCtrl } from "./admin/scripts/controllers/patientAlertsCtrl";
import { patientCareAndTaskCtrl } from "./admin/scripts/controllers/patientCareAndTaskCtrl";
import { patientContractDiagnosisCodeSetupModalCtrl } from "./admin/scripts/controllers/patientContractDiagnosisCodeSetupModalCtrl";
import { patientContractModalCtrl } from "./admin/scripts/controllers/patientContractModal";
import { patientCtrl } from "./admin/scripts/controllers/patientCtrl";
import { patientDocDatabaseQuestionsModalCtrl } from "./admin/scripts/controllers/patientDocDatabaseQuestionsModalCtrl";
import { patientDocLinkQuestionModalCtrl } from "./admin/scripts/controllers/patientDocLinkQuestionModalCtrl";
import {
  PatientDocImageUploadModalCtrl,
  patientDocumentBuilderCtrl,
} from "./admin/scripts/controllers/patientDocumentBuilderCtrl";
import { patientDocumentSettingsCtrl } from "./admin/scripts/controllers/patientDocumentSettingsCtrl";
import { patientMainDocumentsNewDocumentModalCtrl } from "./admin/scripts/controllers/patientMainDocumentsNewDocumentModalCtrl";
import { patientMeasurementModalCtrl } from "./admin/scripts/controllers/patientMeasurementModalCtrl";
import { patientMedicalStatisticsCtrl } from "./admin/scripts/controllers/patientMedicalStatisticsCtrl";
import { patientNewQuestionTypeModalCtrl } from "./admin/scripts/controllers/patientNewQuestionTypeModalCtrl";
import { patientPayRateModalCtrl } from "./admin/scripts/controllers/patientPayRateModalCtrl";
import { PatientPlanOfCareHistoryCtrl } from "./admin/scripts/controllers/patientPocHistoryCtrl";
import { patientPocModalCtrl } from "./admin/scripts/controllers/patientPocModalCtrl";
import { patientScheduleTaskModalCtrl } from "./admin/scripts/controllers/patientScheduleTaskModalCtrl";
import { patientsCtrl } from "./admin/scripts/controllers/patientsCtrl";
import { PatientsDocumentsCtrl } from "./admin/scripts/controllers/patientsDocumentsCtrl";
import { patientsPreExistingModalCtrl } from "./admin/scripts/controllers/patientsPreExitsingModal";
import { patientStatusChangeModalCtrl } from "./admin/scripts/controllers/patientStatusChangeModalCtrl";
import { patientsUpcomingTasksCtrl } from "./admin/scripts/controllers/patientsUpcomingTasksCtrl";
import { patientTaskAssignMultipleModal } from "./admin/scripts/controllers/patientTaskAssignMultipleModal";
import { patientTreatmentModalCtrl } from "./admin/scripts/controllers/patientTreatmentModal";
import { patientUpcomigTaskCancelModalCtrl } from "./admin/scripts/controllers/patientUpcomigTaskCancelModalCtrl";
import { patientVacationsModalCtrl } from "./admin/scripts/controllers/patientVacationsModalCtrl";
import { patientVbpNewReportModalCtrl } from "./admin/scripts/controllers/patientVbpNewReportModalCtrl";
import { newPatientSurplusModalCtrl } from "./admin/scripts/controllers/newPatientSurplusModalCtrl";
import { newPatientSurplusCheckModalCtrl } from "./admin/scripts/controllers/newPatientSurplusCheckModalCtrl";
import { payerSetupModalCtrl } from "./admin/scripts/controllers/payerSetupModalCtrl";
import { paymentCtrl } from "./admin/scripts/controllers/paymentCtrl";
import { payRateSetupModalCtrl } from "./admin/scripts/controllers/payRateSetupModalCtrl";
import { payrollCodeSetupModalCtrl } from "./admin/scripts/controllers/payrollCodeSetupModalCtrl";
import { payrollCtrl } from "./admin/scripts/controllers/payrollCtrl";
import { payrollSetupsSetupModalCtrl } from "./admin/scripts/controllers/payrollSetupsSetupModalCtrl";
import { phoneModalCtrl } from "./admin/scripts/controllers/phoneModalCtrl";
import { pickVisitModalCtrl } from "./admin/scripts/controllers/pickVisitModalCtrl";
import { planOfCareModalCtrl } from "./admin/scripts/controllers/pocModalCtrl";
import { planOfCareTemplateModalCtrl } from "./admin/scripts/controllers/pocTemplateModal";
import { processorModalCtrl } from "./admin/scripts/controllers/processorModalCtrl";
import { professionalCtrl } from "./admin/scripts/controllers/professionalCtrl";
import { professionalsCtrl } from "./admin/scripts/controllers/professionalsCtrl";
import { professionalsOnboardingCtrl } from "./admin/scripts/controllers/professionalsOnboardingCtrl";
import { profileCtrl } from "./admin/scripts/controllers/profileCtrl";
import { ptoApprovalNotesModalCtrl } from "./admin/scripts/controllers/ptoApprovalNotesModalCtrl";
import { ptoApprovalsCtrl } from "./admin/scripts/controllers/ptoApprovalsCtrl";
import { recruitmentCtrl } from "./admin/scripts/controllers/recruitmentCtrl";
import { registerCtrl } from "./admin/scripts/controllers/registerCtrl";
import { remoteApplicationLinkModalCtrl } from "./admin/scripts/controllers/remoteApplicationLinkModalCtrl";
import { reportEntityPermissionsModalCtrl } from "./admin/scripts/controllers/reportEntityPermissionsModalCtrl";
import { reportsCtrl } from "./admin/scripts/controllers/reportsCtrl";
import { reportToolMain } from "./admin/scripts/controllers/reportToolMain";
import { rnVisitMapModalCtrl } from "./admin/scripts/controllers/rnVisitMapModal";
import { savedReportPermissionsModalCtrl } from "./admin/scripts/controllers/savedReportPermissionsModalCtrl";
import { savedReportsCtrl } from "./admin/scripts/controllers/savedReportsCtrl";
import { scheduledReportModalCtrl } from "./admin/scripts/controllers/scheduledReportModalCtrl";
import { scheduledReportsModalCtrl } from "./admin/scripts/controllers/scheduledReportsModalCtrl";
import { sendEncodedPdfModalCtrl } from "./admin/scripts/controllers/sendEncodedPdfModalCtrl";
import { serviceCodeSetupModalCtrl } from "./admin/scripts/controllers/serviceCodeSetupModalCtrl";
import { settingsCtrl } from "./admin/scripts/controllers/settingCtrl";
import { sharedSavedReportsCtrl } from "./admin/scripts/controllers/sharedSavedReportsCtrl";
import { signPhysicianDocumentModalCtrl } from "./admin/scripts/controllers/signPhysicianDocumentModalCtrl";
import { staffingIssuesCtrl } from "./admin/scripts/controllers/staffingIssuesCtrl";
import { standardReportPermissionsModalCtrl } from "./admin/scripts/controllers/standardReportPermissionsModalCtrl";
import { standardReportsCtrl } from "./admin/scripts/controllers/standardReportsCtrl";
import { standardReportViewCtrl } from "./admin/scripts/controllers/standardReportViewCtrl";
import { statusChangeReasonSetupModalCtrl } from "./admin/scripts/controllers/statusChangeReasonSetupModalCtrl";
import { statusChangeToSetupModalCtrl } from "./admin/scripts/controllers/statusChangeToSetupModalCtrl";
import { suggestVisitToRnModalCtrl } from "./admin/scripts/controllers/suggestVisitToRnModal";
import { sweepCtrl } from "./admin/scripts/controllers/sweepCtrl";
import { testCalendarCtrl } from "./admin/scripts/controllers/testCalendarCtrl";
import { timePeriodDescriptionModalCtrl } from "./admin/scripts/controllers/timePeriodDescriptionModalCtrl";
import { timesheetExportChooseCaregiversModalCtrl } from "./admin/scripts/controllers/timesheetExportChooseCaregiversModalCtrl";
import { trainingCenterActiveBundlesCtrl } from "./admin/scripts/controllers/trainingCenterActiveBundlesCtrl";
import { TrainingCenterBundleItemSettingsCtrl } from "./admin/scripts/controllers/trainingCenterBundleItemSettingsCtrl";
import { trainingCenterBundleItemSync } from "./admin/scripts/controllers/trainingCenterBundleItemSync";
import { TrainingCenterBundleSettingsCtrl } from "./admin/scripts/controllers/trainingCenterBundleSettingsCtrl";
import { trainingCenterClassroomEditCtrl } from "./admin/scripts/controllers/trainingCenterClassroomEditCtrl";
import { trainingCenterClassroomNewClassroomModalCtrl } from "./admin/scripts/controllers/trainingCenterClassroomNewClassroomModalCtrl";
import { trainingCenterClassroomsCtrl } from "./admin/scripts/controllers/trainingCenterClassroomsCtrl";
import { trainingCenterDueDateModal } from "./admin/scripts/controllers/trainingCenterDueDateModal";
import { trainingCenterEnrollmentsRequestsCtrl } from "./admin/scripts/controllers/trainingCenterEnrollmentsRequestsCtrl";
import { TrainingCenterExemptsCtrl } from "./admin/scripts/controllers/trainingCenterExemptsCtrl";
import { trainingCenterLiveEventRecordingModalCtrl } from "./admin/scripts/controllers/trainingCenterLiveEventRecordingModalCtrl";
import { trainingCenterLiveEventsCtrl } from "./admin/scripts/controllers/trainingCenterLiveEventsCtrl";
import { trainingCenterMediaLibraryBundleItemModal } from "./admin/scripts/controllers/trainingCenterMediaLibraryBundleItemModal";
import { trainingCenterMediaLibraryCloneBundleModal } from "./admin/scripts/controllers/trainingCenterMediaLibraryCloneBundleModal";
import { TrainingCenterMediaLibraryCtrl } from "./admin/scripts/controllers/trainingCenterMediaLibraryCtrl";
import { TrainingCenterSettingsCtrl } from "./admin/scripts/controllers/trainingCenterSettingsCtrl";
import { tutorialCtrl } from "./admin/scripts/controllers/tutorialCtrl";
import { tutorialsCtrl } from "./admin/scripts/controllers/tutorials";
import { unstaffedModalCtrl } from "./admin/scripts/controllers/unstaffedModalCtrl";
import { updateCaregiverHireDateModalCtrl } from "./admin/scripts/controllers/updateCaregiverHireDateModalCtrl";
import { updateInvoiceRatesModalCtrl } from "./admin/scripts/controllers/updateInvoiceRatesModalCtrl";
import { uploadDocumentPdfModalCtrl } from "./admin/scripts/controllers/uploadDocumentPdfModalCtrl";
import { uploadMultipleDocModalCtrl } from "./admin/scripts/controllers/uploadMultipleDocModalCtrl";
import { uploadScanModalCtrl } from "./admin/scripts/controllers/uploadScanModalCtrl";
import { uploadSubsectionModalCtrl } from "./admin/scripts/controllers/uploadSubsectionModalCtrl";
import { videoModalCtrl } from "./admin/scripts/controllers/videoModalCtrl";
import { videoSessionsModalCtrl } from "./admin/scripts/controllers/videoSessionsModalCtrl";
import { videosModalCtrl } from "./admin/scripts/controllers/videosModalCtrl";
import { videoTestModalCtrl } from "./admin/scripts/controllers/videoTestModalCtrl";
import { visitBroadcastOptionsModalCtrl } from "./admin/scripts/controllers/visitBroadcastOptionsModalCtrl";
import { visitCancelModal } from "./admin/scripts/controllers/visitCancelModal";
import { visitCancelReasonModal } from "./admin/scripts/controllers/visitCancelReasonModal";
import { visitClockTypeMapModalCtrl } from "./admin/scripts/controllers/visitClockTypeMapModalCtrl";
import { visitClusterSuggestModalCtrl } from "./admin/scripts/controllers/visitClusterSuggestModal";
import { visitDetailsCtrl } from "./admin/scripts/controllers/visitDetailsCtrl";
import {
  visitEditRequestsCtrl,
  visitRequestsModalCtrl,
} from "./admin/scripts/controllers/visitEditRequestsCtrl";
import { visitFileUploadCtrl } from "./admin/scripts/controllers/visitFileUploadCtrl";
import { visitInstanceManualClockChangesModalCtrl } from "./admin/scripts/controllers/visitInstanceManualClockChangesModalCtrl";
import { visitInstanceModalCtrl } from "./admin/scripts/controllers/visitInstanceModalCtrl";
import { visitInstancePotentialClockRecords } from "./admin/scripts/controllers/visitInstancePotentialClockRecords";
import { visitInstancePTOModalCtrl } from "./admin/scripts/controllers/visitInstancePTOModalCtrl";
import { visitInstancesCtrl } from "./admin/scripts/controllers/visitInstancesCtrl";
import { visitInstancesDeleteModalCtrl } from "./admin/scripts/controllers/visitInstancesDeleteModalCtrl";
import { visitLabelsCtrl } from "./admin/scripts/controllers/visitLabelsCtrl";
import { visitLabelSetupModalCtrl } from "./admin/scripts/controllers/visitLabelSetupModalCtrl";
import { visitModalCtrl } from "./admin/scripts/controllers/visitModalCtrl";
import { visitPatientDocumentsSetupModalCtrl } from "./admin/scripts/controllers/visitPatientDocumentsSetupModalCtrl";
import { visitsBroadcastCtrl } from "./admin/scripts/controllers/visitsBroadcastCtrl";
import { visitsCtrl } from "./admin/scripts/controllers/visitsCtrl";
import { visitSuggestModalCtrl } from "./admin/scripts/controllers/visitSuggestModalCtrl";
import { walkthroughSwiperModalCtrl } from "./admin/scripts/controllers/walkthroughSwiperModalCtrl";
import { newVisitTimeSpanCtrl } from "./admin/scripts/directives/new-visit-time-span-directive";
import { visitTimeSpanCtrl } from "./admin/scripts/directives/visit-time-span-directive";
import { caregiverTrainingCenterCtrl } from "./admin/scripts/modules/trainingCenter/caregiverTrainingCenter";
import { caregiverTrainingCenterBundleCtrl } from "./admin/scripts/modules/trainingCenter/caregiverTrainingCenterBundle";
import { caregiverTrainingCenterDueDateCtrl } from "./admin/scripts/modules/trainingCenter/caregiverTrainingCenterDueDate";
import { caregiverTrainingCenterInfoBundleDueDateHeaderCtrl } from "./admin/scripts/modules/trainingCenter/caregiverTrainingCenterInfoBundleDueDateHeader.component";
import { caregiverTrainingCenterInfoEditDatesModalCtrl } from "./admin/scripts/modules/trainingCenter/caregiverTrainingCenterInfoEditDatesModal";
import { caregiverTrainingCenterTestModalCtrl } from "./admin/scripts/modules/trainingCenter/caregiverTrainingCenterTestModal";
import { caregiverTrainingCenterTotalWatchedModalCtrl } from "./admin/scripts/modules/trainingCenter/caregiverTrainingCenterTotalWatchedModalCtrl";
import { billingPatientSurplusDashboardCtrl } from "./admin/scripts/controllers/billingPatientSurplusDashboardCtrl"
import "./global-vendors/switchery/switchery";
import "./vendors/angular-moment/angular-moment";
import "./vendors/angular-signature/angular-signature";
import "./vendors/angular-ui-select/select";
import "./vendors/ng-switchery/ng-switchery";
import "./vendors/signature_pad/signature_pad";
import { newPatientSurplusInvoiceModalCtrl } from "./admin/scripts/controllers/newPatientSurplusInvoiceModalCtrl";

export function registerAppModuleControllers() {
  angular
    .module("dashboard")
    // Controllers

    .controller("patientPocHistoryCtrl", PatientPlanOfCareHistoryCtrl)
    .controller("hhaIntegrationBillingCtrl", hhaIntegrationBillingCtrl)
    .controller("hhaIntegrationNonBillingCtrl", hhaIntegrationNonBillingCtrl)
    .controller("hhaIntegrationUnlinkedPatientsCtrl", hhaIntegrationUnlinkedPatientsCtrl)
    .controller("hhaxMergeAuthModal", hhaxMergeAuthModal)
    .controller("patientScheduleTaskModalCtrl", patientScheduleTaskModalCtrl)
    .controller("caregiverTrainingCenterTestModalCtrl", caregiverTrainingCenterTestModalCtrl)
    .controller("caregiverTrainingCenterDueDateCtrl", caregiverTrainingCenterDueDateCtrl)
    .controller(
      "caregiverTrainingCenterInfoEditDatesModalCtrl",
      caregiverTrainingCenterInfoEditDatesModalCtrl
    )
    .controller(
      "caregiverTrainingCenterInfoBundleDueDateHeaderCtrl",
      caregiverTrainingCenterInfoBundleDueDateHeaderCtrl
    )
    .controller("caregiverTrainingCenterBundleCtrl", caregiverTrainingCenterBundleCtrl)
    .controller("caregiverTrainingCenterCtrl", caregiverTrainingCenterCtrl)
    .controller("hoursUsageCtrl", hoursUsageCtrl)
    .controller("clockinClockoutBulkLinkModalCtrl", clockinClockoutBulkLinkModalCtrl)
    .controller("signPhysicianDocumentModalCtrl", signPhysicianDocumentModalCtrl)
    .controller("patientsDocumentsCtrl", PatientsDocumentsCtrl)
    .controller("newVisitTimeSpanCtrl", newVisitTimeSpanCtrl)
    .controller("8850ModalCtrl", _8850ModalCtrl)
    .controller("acceptBulkVisitIInstancesChangesCtrl", acceptBulkVisitIInstancesChangesCtrl)
    .controller("addCaregiverCtrl", addCaregiverCtrl)
    .controller("addFileTypeModalCtrl", addFileTypeModalCtrl)
    .controller("addHrDocumentModalCtrl", addHrDocumentModalCtrl)
    .controller("agencyBranchSettingModalCtrl", agencyBranchSettingModalCtrl)
    .controller("agencyCompleteFormModalCtrl", agencyCompleteFormModalCtrl)
    .controller("agencyHolidayModalCtrl", agencyHolidayModalCtrl)
    .controller("agencyMemberMentionsDoneCtrl", agencyMemberMentionsDoneCtrl)
    .controller("agencyMemberMentionsInboxCtrl", agencyMemberMentionsInboxCtrl)
    .controller("agencyMemberSecuritySettingsModalCtrl", agencyMemberSecuritySettingsModalCtrl)
    .controller("agencyPermissionRoleModalCtrl", agencyPermissionRoleModalCtrl)
    .controller("agencyPhysicianModalCtrl", agencyPhysicianModalCtrl)
    .controller("agencyProductModalCtrl", agencyProductModalCtrl)
    .controller("agencyPTOSettingModalCtrl", agencyPTOSettingModalCtrl)
    .controller("agencySettingsModal", agencySettingsModal)
    .controller("agencyTeamModalCtrl", agencyTeamModalCtrl)
    .controller("anomaliesCtrl", anomaliesCtrl)
    .controller("applicantDownloadModalCtrl", applicantDownloadModalCtrl)
    .controller(
      "applicationFullProfileSubsectionsSelectionModalCtrl",
      applicationFullProfileSubsectionsSelectionModalCtrl
    )
    .controller("approveCaregiverModalCtrl", approveCaregiverModalCtrl)
    .controller("algoModalCtrl", algoModalCtrl)
    .controller("approveDutySheetsClockTimesModalCtrl", approveDutySheetsClockTimesModalCtrl)
    .controller("approveVisitInstanceDeleteModalCtrl", approveVisitInstanceDeleteModalCtrl)
    .controller("arClaimStatusSetupModalCtrl", arClaimStatusSetupModalCtrl)
    .controller("arCollectionStatusSetupModalCtrl", arCollectionStatusSetupModalCtrl)
    .controller("arReasonSetupModalCtrl", arReasonSetupModalCtrl)
    .controller("assignModalCtrl", assignModalCtrl)
    .controller("assignPatientCoordinatorModalCtrl", assignPatientCoordinatorModalCtrl)
    .controller("authorizationSetupModalCtrl", authorizationSetupModalCtrl)
    .controller("authorizationUtilizationModalCtrl", authorizationUtilizationModalCtrl)
    .controller("BackOfficeCtrl", BackOfficeCtrl)
    .controller("billingAccountsReceivableCtrl", billingAccountsReceivableCtrl)
    .controller("billingAttachCheckVisitsModalCtrl", billingAttachCheckVisitsModalCtrl)
    .controller("billingCheckDetailsCtrl", billingCheckDetailsCtrl)
    .controller("billingChecksCtrl", billingChecksCtrl)
    .controller("billingExportDetailsCtrl", billingExportDetailsCtrl)
    .controller("billingExportedInvoicesModalCtrl", billingExportedInvoicesModalCtrl)
    .controller("billingExportsCtrl", billingExportsCtrl)
    .controller("billingInvoiceDetailsCtrl", billingInvoiceDetailsCtrl)
    .controller("billingInvoicesCtrl", billingInvoicesCtrl)
    .controller("billingIssuesTemplateSetupModalCtrl", billingIssuesTemplateSetupModalCtrl)
    .controller("billingNewInvoiceBatchCtrl", billingNewInvoiceBatchCtrl)
    .controller("billingNewManualCheckModalCtrl", billingNewManualCheckModalCtrl)
    .controller("billingNewPayrollBatchModalCtrl", billingNewPayrollBatchModalCtrl)
    .controller("billingPayrollDetailsCtrl", billingPayrollDetailsCtrl)
    .controller("billingPayrollsCtrl", billingPayrollsCtrl)
    .controller("billingRateSetupModalCtrl", billingRateSetupModalCtrl)
    .controller("billingVisitAdjustmentApprovalsCtrl", billingVisitAdjustmentApprovalsCtrl)
    .controller("billingVisitsToExportCtrl", billingVisitsToExportCtrl)
    .controller("billingVisitsWithIssuesCtrl", billingVisitsWithIssuesCtrl)
    .controller("boostModalCtrl", boostModalCtrl)
    .controller("buyCaregiverModalCtrl", buyCaregiverModalCtrl)
    .controller("calendarNewVisitModalCtrl", calendarNewVisitModalCtrl)
    .controller("callsCtrl", callsCtrl)
    .controller("callsMaintenanceCtrl", callsMaintenanceCtrl)
    .controller("callsMissedBothCtrl", callsMissedBothCtrl)
    .controller("callsMissedInCtrl", callsMissedInCtrl)
    .controller("callsMissedOutCtrl", callsMissedOutCtrl)
    .controller("caregiverDocumentSendPdfModalCtrl", caregiverDocumentSendPdfModalCtrl)
    .controller("caregiverPayRateModalCtrl", caregiverPayRateModalCtrl)
    .controller("caregiverPtoLabelSetupModalCtrl", caregiverPtoLabelSetupModalCtrl)
    .controller("caregiverRegenerateCertificateModalCtrl", caregiverRegenerateCertificateModalCtrl)
    .controller("caregiversPendingApplicationCtrl", caregiversPendingApplicationCtrl)
    .controller("CaregiversTrainingCenterOverview", CaregiversTrainingCenterOverview)
    .controller(
      "caregiverTrainingCenterAddBundleModalCtrl",
      caregiverTrainingCenterAddBundleModalCtrl
    )
    .controller("trainingCenterActiveBundlesCtrl", trainingCenterActiveBundlesCtrl)
    .controller("careManagementCtrl", careManagementCtrl)
    .controller("certificationPeriodModal", certificationPeriodModal)
    .controller("certificationPeriodsCtrl", certificationPeriodsCtrl)
    .controller("changeTeamMemberPasswordModalCtrl", changeTeamMemberPasswordModalCtrl)
    .controller("cityTaxCodeCtrl", cityTaxCodeCtrl)
    .controller("claimsConversionCtrl", claimsConversionCtrl)
    .controller("clientSurveyModalCtrl", clientSurveyModalCtrl)
    .controller("clockedVisitsCtrl", clockedVisitsCtrl)
    .controller("clockinClockoutLinkCallModalCtrl", clockinClockoutLinkCallModalCtrl)
    .controller("clockLocationBoundCtrl", clockLocationBoundCtrl)
    .controller("communicationCtrl", communicationCtrl)
    .controller("CaregiverExtraInfoModalCtrl", CaregiverExtraInfoModalCtrl)
    .controller("confirmationsCtrl", confirmationsCtrl)
    .controller("confirmModalCtrl", confirmModalCtrl)
    .controller("contractSetupModalCtrl", contractSetupModalCtrl)
    .controller("copyCertificationSettingsCtrl", copyCertificationSettingsCtrl)
    .controller("coriModalCtrl", coriModalCtrl)
    .controller("coronaReportStatusModalCtrl", coronaReportStatusModalCtrl)
    .controller("coronaVirusReportsCtrl", coronaVirusReportsCtrl)
    .controller("countiesModalCtrl", countiesModalCtrl)
    .controller("countyMapController", countyMapController)
    .controller("customFieldSettingModalCtrl", customFieldSettingModalCtrl)
    .controller("createNoteModalCtrl", createNoteModalCtrl)
    .controller("createSimpleNoteModalCtrl", createSimpleNoteModalCtrl)
    .controller("customerEngagementCtrl", customerEngagementCtrl)
    .controller("dashboardCtrl", dashboardCtrl)
    .controller("diagnosisCodeSetupModalCtrl", diagnosisCodeSetupModalCtrl)
    .controller("displayIdSettingsModalCtrl", displayIdSettingsModalCtrl)
    .controller("docsExpirationsCtrl", docsExpirationsCtrl)
    .controller("dragFileModal", dragFileModal)
    .controller("dueDateForCaregiverModalCtrl", dueDateForCaregiverModalCtrl)
    .controller("dutySheetModalCtrl", dutySheetModalCtrl)
    .controller("dutySheetsCtrl", dutySheetsCtrl)
    .controller("ediComparisonCtrl", ediComparisonCtrl)
    .controller("editAddressPhonenumberModalCtrl", editAddressPhonenumberModalCtrl)
    .controller("editCaregiverStatusModalCtrl", editCaregiverStatusModalCtrl)
    .controller("editPatientDocModalCtrl", editPatientDocModalCtrl)
    .controller("editPatientDocModalSelectController", editPatientDocModalSelectController)
    .controller(
      "editPatientDocModalPatientSignatureController",
      editPatientDocModalPatientSignatureController
    )
    .controller("editPatientDocModalTimeController", editPatientDocModalTimeController)
    .controller(
      "editPatientDocModalBodyDrawingController",
      editPatientDocModalBodyDrawingController
    )
    .controller("editPatientDocModalSignatureController", editPatientDocModalSignatureController)
    .controller("emailModalCtrl", emailModalCtrl)
    .controller("engagementKPICtrl", engagementKPICtrl)
    .controller("entityGeneralNotesModalCtrl", entityGeneralNotesModalCtrl)
    .controller("entityNoteCommentsModalCtrl", entityNoteCommentsModalCtrl)
    .controller("ExclusionListActionsModalCtrl", ExclusionListActionsModalCtrl)
    .controller("exclusionListModalCtrl", exclusionListModalCtrl)
    .controller("ExclusionListsCtrl", ExclusionListsCtrl)
    .controller("exportCaregiverProfileModal", exportCaregiverProfileModal)
    .controller("exportDutySheetDataModal", exportDutySheetDataModal)
    .controller("exportPatientProfileModal", exportPatientProfileModal)
    .controller("faxModalCtrl", faxModalCtrl)
    .controller("feedCtrl", feedCtrl)
    .controller("fileUploaderCtrl", fileUploaderCtrl)
    .controller("flyersCtrl", flyersCtrl)
    .controller("globalSearchCtrl", globalSearchCtrl)
    .controller("hhaIntegrationMergePatientModal", hhaIntegrationMergePatientModal)
    .controller("historyTimelineModalCtrl", historyTimelineModalCtrl)
    .controller("hrSettingsCtrl", hrSettingsCtrl)
    .controller("I9ModalCtrl", I9ModalCtrl)
    .controller("imageViewerModalCtrl", imageViewerModalCtrl)
    .controller("integrationFileRecordModalCtrl", integrationFileRecordModalCtrl)
    .controller("inviteCtrl", inviteCtrl)
    .controller("invoicedVisitNotesModalCtrl", invoicedVisitNotesModalCtrl)
    .controller("invoicedVisitsAddNotesModalCtrl", invoicedVisitsAddNotesModalCtrl)
    .controller("linkedPatientSetupModalCtrl", linkedPatientSetupModalCtrl)
    .controller("liveEventModalCtrl", liveEventModalCtrl)
    .controller("mainCtrl", mainCtrl)
    .controller("mapModalCtrl", mapModalCtrl)
    .controller("masterWeekCtrl", masterWeekCtrl)
    .controller("masterWeekModalCtrl", masterWeekModalCtrl)
    .controller("MatchingCaregiversModalCtrl", MatchingCaregiversModalCtrl)
    .controller("medflytPassportCtrl", medflytPassportCtrl)
    .controller("mergePatientsCtrl", mergePatientsCtrl)
    .controller("mySavedReportsCtrl", mySavedReportsCtrl)
    .controller("newAgencyMedicineModalCtrl", newAgencyMedicineModalCtrl)
    .controller("newPatientAddressModalCtrl", newPatientAddressModalCtrl)
    .controller("newPatientPhysicianModalCtrl", newPatientPhysicianModalCtrl)
    .controller("newPatientTaskModalCtrl", newPatientTaskModalCtrl)
    .controller("newPatientTaskTemplateModalCtrl", newPatientTaskTemplateModalCtrl)
    .controller("newPatientVacationModalCtrl", newPatientVacationModalCtrl)
    .controller("newPhonenumberModalCtrl", newPhonenumberModalCtrl)
    .controller("newPhysicianModalCtrl", newPhysicianModalCtrl)
    .controller("newPlanOfCareModalCtrl", NewPlanOfCareModalCtrl)
    .controller("newSubSectionModalCtrl", newSubSectionModalCtrl)
    .controller("newVisitCtrl", newVisitCtrl)
    .controller("newVisitSuggestedCaregiversModalCtrl", newVisitSuggestedCaregiversModalCtrl)
    .controller("notesCtrl", NotesCtrl)
    .controller("notesReportCtrl", notesReportCtrl)
    .controller("notesSettingsCtrl", notesSettingsCtrl)
    .controller("noteSubjectSetupModalCtrl", noteSubjectSetupModalCtrl)
    .controller("nursingQuestionStatisticsModalCtrl", nursingQuestionStatisticsModalCtrl)
    .controller("officeSetupModalCtrl", officeSetupModalCtrl)
    .controller("orientationCenterCtrl", orientationCenterCtrl)
    .controller("orientationItemPreviewModalCtrl", orientationItemPreviewModalCtrl)
    .controller("overtimeCalculatorModalCtrl", overtimeCalculatorModalCtrl)
    .controller("passportVerifierCtrl", passportVerifierCtrl)
    .controller("patientAlertsCtrl", patientAlertsCtrl)
    .controller("patientCareAndTaskCtrl", patientCareAndTaskCtrl)
    .controller(
      "patientContractDiagnosisCodeSetupModalCtrl",
      patientContractDiagnosisCodeSetupModalCtrl
    )
    .controller("patientContractModalCtrl", patientContractModalCtrl)
    .controller("patientCtrl", patientCtrl)
    .controller("patientDocDatabaseQuestionsModalCtrl", patientDocDatabaseQuestionsModalCtrl)
    .controller("patientDocLinkQuestionModalCtrl", patientDocLinkQuestionModalCtrl)
    .controller("patientDocumentBuilderCtrl", patientDocumentBuilderCtrl)
    .controller("PatientDocImageUploadModalCtrl", PatientDocImageUploadModalCtrl)
    .controller("patientDocumentSettingsCtrl", patientDocumentSettingsCtrl)
    .controller(
      "patientMainDocumentsNewDocumentModalCtrl",
      patientMainDocumentsNewDocumentModalCtrl
    )
    .controller("patientMeasurementModalCtrl", patientMeasurementModalCtrl)
    .controller("patientMedicalStatisticsCtrl", patientMedicalStatisticsCtrl)
    .controller("patientNewQuestionTypeModalCtrl", patientNewQuestionTypeModalCtrl)
    .controller("patientPayRateModalCtrl", patientPayRateModalCtrl)
    .controller("patientPocModalCtrl", patientPocModalCtrl)
    .controller("patientsCtrl", patientsCtrl)
    .controller("patientsPreExistingModalCtrl", patientsPreExistingModalCtrl)
    .controller("patientStatusChangeModalCtrl", patientStatusChangeModalCtrl)
    .controller("patientsUpcomingTasksCtrl", patientsUpcomingTasksCtrl)
    .controller("patientTaskAssignMultipleModal", patientTaskAssignMultipleModal)
    .controller("patientTreatmentModalCtrl", patientTreatmentModalCtrl)
    .controller("patientUpcomigTaskCancelModalCtrl", patientUpcomigTaskCancelModalCtrl)
    .controller("patientVacationsModalCtrl", patientVacationsModalCtrl)
    .controller("patientVbpNewReportModalCtrl", patientVbpNewReportModalCtrl)
    .controller("payerSetupModalCtrl", payerSetupModalCtrl)
    .controller("paymentCtrl", paymentCtrl)
    .controller("payRateSetupModalCtrl", payRateSetupModalCtrl)
    .controller("payrollCodeSetupModalCtrl", payrollCodeSetupModalCtrl)
    .controller("payrollCtrl", payrollCtrl)
    .controller("payrollSetupsSetupModalCtrl", payrollSetupsSetupModalCtrl)
    .controller("phoneModalCtrl", phoneModalCtrl)
    .controller("pickVisitModalCtrl", pickVisitModalCtrl)
    .controller("planOfCareModalCtrl", planOfCareModalCtrl)
    .controller("planOfCareTemplateModalCtrl", planOfCareTemplateModalCtrl)
    .controller("processorModalCtrl", processorModalCtrl)
    .controller("professionalCtrl", professionalCtrl)
    .controller("professionalsCtrl", professionalsCtrl)
    .controller("professionalsOnboardingCtrl", professionalsOnboardingCtrl)
    .controller("profileCtrl", profileCtrl)
    .controller("ptoApprovalNotesModalCtrl", ptoApprovalNotesModalCtrl)
    .controller("ptoApprovalsCtrl", ptoApprovalsCtrl)
    .controller("recruitmentCtrl", recruitmentCtrl)
    .controller("registerCtrl", registerCtrl)
    .controller("remoteApplicationLinkModalCtrl", remoteApplicationLinkModalCtrl)
    .controller("reportEntityPermissionsModalCtrl", reportEntityPermissionsModalCtrl)
    .controller("reportsCtrl", reportsCtrl)
    .controller("reportToolMain", reportToolMain)
    .controller("rnVisitMapModalCtrl", rnVisitMapModalCtrl)
    .controller("savedReportPermissionsModalCtrl", savedReportPermissionsModalCtrl)
    .controller("savedReportsCtrl", savedReportsCtrl)
    .controller("scheduledReportModalCtrl", scheduledReportModalCtrl)
    .controller("scheduledReportsModalCtrl", scheduledReportsModalCtrl)
    .controller("sendEncodedPdfModalCtrl", sendEncodedPdfModalCtrl)
    .controller("serviceCodeSetupModalCtrl", serviceCodeSetupModalCtrl)
    .controller("settingsCtrl", settingsCtrl)
    .controller("sharedSavedReportsCtrl", sharedSavedReportsCtrl)
    .controller("staffingIssuesCtrl", staffingIssuesCtrl)
    .controller("standardReportPermissionsModalCtrl", standardReportPermissionsModalCtrl)
    .controller("standardReportsCtrl", standardReportsCtrl)
    .controller("standardReportViewCtrl", standardReportViewCtrl)
    .controller("statusChangeReasonSetupModalCtrl", statusChangeReasonSetupModalCtrl)
    .controller("statusChangeToSetupModalCtrl", statusChangeToSetupModalCtrl)
    .controller("suggestVisitToRnModalCtrl", suggestVisitToRnModalCtrl)
    .controller("sweepCtrl", sweepCtrl)
    .controller("testCalendarCtrl", testCalendarCtrl)
    .controller("timePeriodDescriptionModalCtrl", timePeriodDescriptionModalCtrl)
    .controller("TrainingCenterBundleItemSettingsCtrl", TrainingCenterBundleItemSettingsCtrl)
    .controller("trainingCenterBundleItemSync", trainingCenterBundleItemSync)
    .controller("TrainingCenterBundleSettingsCtrl", TrainingCenterBundleSettingsCtrl)
    .controller("trainingCenterClassroomsCtrl", trainingCenterClassroomsCtrl)
    .controller("trainingCenterClassroomEditCtrl", trainingCenterClassroomEditCtrl)
    .controller(
      "trainingCenterClassroomNewClassroomModalCtrl",
      trainingCenterClassroomNewClassroomModalCtrl
    )
    .controller("trainingCenterDueDateModal", trainingCenterDueDateModal)
    .controller("trainingCenterEnrollmentsRequestsCtrl", trainingCenterEnrollmentsRequestsCtrl)
    .controller("TrainingCenterExemptsCtrl", TrainingCenterExemptsCtrl)
    .controller(
      "trainingCenterLiveEventRecordingModalCtrl",
      trainingCenterLiveEventRecordingModalCtrl
    )
    .controller("trainingCenterLiveEventsCtrl", trainingCenterLiveEventsCtrl)
    .controller(
      "trainingCenterMediaLibraryBundleItemModal",
      trainingCenterMediaLibraryBundleItemModal
    )
    .controller(
      "trainingCenterMediaLibraryCloneBundleModal",
      trainingCenterMediaLibraryCloneBundleModal
    )
    .controller("TrainingCenterMediaLibraryCtrl", TrainingCenterMediaLibraryCtrl)
    .controller("TrainingCenterSettingsCtrl", TrainingCenterSettingsCtrl)
    .controller("tutorialCtrl", tutorialCtrl)
    .controller("tutorialsCtrl", tutorialsCtrl)
    .controller("unstaffedModalCtrl", unstaffedModalCtrl)
    .controller("updateCaregiverHireDateModalCtrl", updateCaregiverHireDateModalCtrl)
    .controller("updateInvoiceRatesModalCtrl", updateInvoiceRatesModalCtrl)
    .controller("uploadDocumentPdfModalCtrl", uploadDocumentPdfModalCtrl)
    .controller("uploadMultipleDocModalCtrl", uploadMultipleDocModalCtrl)
    .controller("uploadScanModalCtrl", uploadScanModalCtrl)
    .controller("uploadSubsectionModalCtrl", uploadSubsectionModalCtrl)
    .controller("videoModalCtrl", videoModalCtrl)
    .controller("videoSessionsModalCtrl", videoSessionsModalCtrl)
    .controller("videosModalCtrl", videosModalCtrl)
    .controller("videoTestModalCtrl", videoTestModalCtrl)
    .controller("visitBroadcastOptionsModalCtrl", visitBroadcastOptionsModalCtrl)
    .controller("visitCancelModal", visitCancelModal)
    .controller("visitCancelReasonModal", visitCancelReasonModal)
    .controller("visitClockTypeMapModalCtrl", visitClockTypeMapModalCtrl)
    .controller("visitClusterSuggestModalCtrl", visitClusterSuggestModalCtrl)
    .controller("visitDetailsCtrl", visitDetailsCtrl)
    .controller("visitEditRequestsCtrl", visitEditRequestsCtrl)
    .controller("visitRequestsModalCtrl", visitRequestsModalCtrl)
    .controller("visitFileUploadCtrl", visitFileUploadCtrl)
    .controller(
      "visitInstanceManualClockChangesModalCtrl",
      visitInstanceManualClockChangesModalCtrl
    )
    .controller("visitInstanceModalCtrl", visitInstanceModalCtrl)
    .controller("visitInstancePotentialClockRecords", visitInstancePotentialClockRecords)
    .controller("visitInstancePTOModalCtrl", visitInstancePTOModalCtrl)
    .controller("visitInstancesCtrl", visitInstancesCtrl)
    .controller("visitInstancesDeleteModalCtrl", visitInstancesDeleteModalCtrl)
    .controller("visitLabelsCtrl", visitLabelsCtrl)
    .controller("visitLabelSetupModalCtrl", visitLabelSetupModalCtrl)
    .controller("visitModalCtrl", visitModalCtrl)
    .controller("visitPatientDocumentsSetupModalCtrl", visitPatientDocumentsSetupModalCtrl)
    .controller("visitsBroadcastCtrl", visitsBroadcastCtrl)
    .controller("visitsCtrl", visitsCtrl)
    .controller("visitSuggestModalCtrl", visitSuggestModalCtrl)
    .controller("visitTimeSpanCtrl", visitTimeSpanCtrl)
    .controller("walkthroughSwiperModalCtrl", walkthroughSwiperModalCtrl)
    .controller("switchEnv", switchEnv)
    .controller("newCaregiverAbsenceModalCtrl", newCaregiverAbsenceModalCtrl)
    .controller("eTimeSheetApprovalCtrl", eTimeSheetApprovalCtrl)
    .controller(
      "caregiverTrainingCenterTotalWatchedModalCtrl",
      caregiverTrainingCenterTotalWatchedModalCtrl
    )
    .controller("exportDutySheetDataModalCtrl", ExportDutySheetDataModalCtrl)
    .controller(
      "timesheetExportChooseCaregiversModalCtrl",
      timesheetExportChooseCaregiversModalCtrl
    )
    .controller("billingPatientSurplusDashboardCtrl", billingPatientSurplusDashboardCtrl)
    .controller("newPatientSurplusModalCtrl", newPatientSurplusModalCtrl)
    .controller("newPatientSurplusInvoiceModalCtrl",newPatientSurplusInvoiceModalCtrl)
    .controller("newPatientSurplusCheckModalCtrl", newPatientSurplusCheckModalCtrl)
    .controller("callCenterCallWidgetCtrl", CallCenterCallWidgetCtrl);
}
