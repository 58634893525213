import { LocalDate, DateTimeFormatter, nativeJs } from "js-joda";
import moment from "moment";

export const visitsListView = {
    templateUrl: "admin/views/visits-list.html",
    bindings: {
        entityType: '@',
        entityId: '<',
        onClickEditItems: '&',
        onClickDeleteItems: '&',
        onClickStopBroadcastItems: '&',
        onClickUnassignItems: '&'
    },
    //! @ngInject
    controller: function (
        $scope,
        $rootScope,
        DatabaseApi,
        toaster,
        NgTableParams,
        visitInstanceService,
        visitConsts,
        entityNewVisitModalService,
        CalendarItemType,
    ) {
        const initialize = () => {
            getSelectedVisits();
            getSelectedVacations();
        };

        this.$onInit = () => {
            initialize();
        };

        const statusTypesMapping = visitConsts.visitListStatusTypesMapping;
        $scope.initialDates = {
            from: new Date(LocalDate.now().withDayOfMonth(1).format(DateTimeFormatter.ofPattern("MM/dd/yyyy"))),
            to: new Date(LocalDate.now().plusMonths(1).withDayOfMonth(1).minusDays(1).format(DateTimeFormatter.ofPattern("MM/dd/yyyy")))
        }

        $scope.visitsListFilters = {
            statuses: [],
            certifications: []
        };

        $scope.visitStatusesOptions = Object.values(statusTypesMapping)
            .map((status, idx) => ({ id: idx, label: status.text }));

        $scope.optionsExtraSettings = {
            styleActive: true,
            scrollable: true,
            scrollableHeight: '250px',
            enableSearch: true,
            externalIdProp: ''
        };

        $scope.caregiversMap = DatabaseApi.caregivers() || {};

        const activeAgencyCertifications = DatabaseApi.activeAgencyCertifications() || [];
        $scope.certificationsOptions = activeAgencyCertifications
            .map((certificationItem, index) => ({
                id: index,
                label: certificationItem.certification
            }));

        const getVisitStatuses = (visitInstance) => {
            const statuses = [];

            if ($scope.$ctrl.entityType === "PATIENT") {
                if (!visitInstance.visit.caregiverId) {
                    if (visitInstance.visitBroadcast === null) {
                        statuses.push(statusTypesMapping['UNSTAFFED']);
                    }
                } else {
                    if (visitInstance.visit.missedVisit) {
                        statuses.push(statusTypesMapping['MISSED']);
                    } else {
                        statuses.push(statusTypesMapping['ASSIGNED']);
                    }
                }
            }
            else if ($scope.$ctrl.entityType === "CAREGIVER") {
                if (visitInstance.visit.removedAt) {
                    statuses.push(statusTypesMapping['REMOVED']);
                } else if (visitInstance.visit.missedVisit) {
                    statuses.push(statusTypesMapping['MISSED']);
                } else {
                    statuses.push(statusTypesMapping['ASSIGNED']);
                }
            }

            // AUTH STATUSES
            if (visitInstance.missingAuth) {
                statuses.push(statusTypesMapping['NO_AUTHORIZATION']);
            }

            // BROADCAST STATUSES
            const broadcastStatus = visitInstanceService.getVisitInstanceBroadcastStatus(visitInstance);

            switch (broadcastStatus) {
                case 'PENDING_REQUESTS': {
                    statuses.push(statusTypesMapping['BROADCAST']);
                    statuses.push(statusTypesMapping['PENDING_REQUESTS']);
                    break;
                }

                case 'AWAITING_ASSIGNMENT': {
                    statuses.push(statusTypesMapping['BROADCAST']);
                    statuses.push(statusTypesMapping['AWAITING_ASSIGNMENT']);
                    break;
                }

                case 'UNSTAFFED': {
                    const unstaffedStatus = statuses.find(status => status.text === "Unstaffed");
                    if (!unstaffedStatus) {
                        statuses.push(statusTypesMapping['UNSTAFFED']);
                    }
                    break;
                }

                default: break;
            }

            return statuses;
        }

        const visitListTableFilterByMethods = {
            visitHasStatuses: (visit, selectedStatuses) => (visit.statuses || [])
                .some(visitStatus => selectedStatuses.find(status => status === visitStatus.text)),
            visitHasCertifications: (visit, selectedCertifications) => selectedCertifications.includes(visit.certification)
        }

        const filterVisiListTable = () => {
            if (!$scope.daysList) return;
            const filters = [];

            const selectedStatuses = $scope.visitsListFilters.statuses.map(status => status.label);
            if (selectedStatuses.length > 0) {
                filters.push(visit => visitListTableFilterByMethods.visitHasStatuses(visit, selectedStatuses));
            }

            const selectedCertifications = $scope.visitsListFilters.certifications.map(cert => cert.label);
            if (selectedCertifications.length > 0) {
                filters.push(visit => visitListTableFilterByMethods.visitHasCertifications(visit, selectedCertifications));
            }

            let filteredVisits = $scope.daysList;
            if (filters.length > 0) {
                filteredVisits = filteredVisits.filter(function (visit) {
                    let isFiltered = true;
                    for (let idx = 0; isFiltered && idx < filters.length; idx++) {
                        isFiltered = isFiltered && filters[idx](visit);
                    }
                    return isFiltered;
                });
            }

            initTable(filteredVisits);
        }

        const initTable = (visitInstances) => {
            const oldTotal = $scope.visitsListTable?.total?.() || 0;
            let sorting = { itemDate: "asc" };
            let page = undefined;

            const options = {
                count: 15,
                sorting: sorting
            };

            if ($scope.visitsListTable) {
                sorting = $scope.visitsListTable.sorting();
                page = $scope.visitsListTable.page();
                options.count = $scope.visitsListTable.count()
            }

            $scope.visitsListTable = new NgTableParams(options, {
                dataset: visitInstances.filter(v => v.type !== 'DELETED_VISIT'),
                counts: [15, 25, 50, 100]
            });

            if (page && oldTotal === $scope.visitsListTable.total()) $scope.visitsListTable.page(page);
        }

        $scope.handleCheckItem = (item) => {
            if (!item.key) {
                return;
            }

            if (item.type === "PATIENT_VACATION") {
                let newSelectedVacations = angular.copy($scope.selectedVacations);
                const indexOfVacation = newSelectedVacations.findIndex(vacation => vacation.key === item.key);
                if (indexOfVacation === -1) {
                    newSelectedVacations.push(item);
                } else {
                    newSelectedVacations.splice(indexOfVacation, 1);
                }
                setSelectedVacations(newSelectedVacations);
            } else if (item.type === "PAID_TIME_OFF") {
                // do nothing - there will be later option to bulk delete ptos
            } else if (item.type === "CAREGIVER_ABSENCE") {
                // do nothing - list view is not showing any absences for now
            } else {
                let newSelectedVisits = angular.copy($scope.selectedVisits);
                const indexOfVisit = newSelectedVisits.findIndex(visit => visit.key === item.key);
                if (indexOfVisit === -1) {
                    newSelectedVisits.push(item);
                } else {
                    newSelectedVisits.splice(indexOfVisit, 1);
                }
                setSelectedVisits(newSelectedVisits);
            }

            $scope.updateItemsActionsDisables();
        }

        const updatePatientItemsActionsDisables = () => {
            const selectedBroadcasts = $scope.daysList.filter(
                item => item.type === CalendarItemType.BROADCASTED_VISIT && item.checked
            );
            const selectedVisitsNonBroadcasts = $scope.daysList.filter(
                item => item.type !== CalendarItemType.BROADCASTED_VISIT && item.type !== CalendarItemType.PATIENT_VACATION && item.checked
            );
            const selectedVacations = $scope.daysList.filter(
                item => item.type === CalendarItemType.PATIENT_VACATION && item.checked
            );

            $scope.visitsActions['PATIENT'].forEach(action => {
                switch (action.text) {
                    case "Edit":
                        action.isDisabled = !(
                            (
                                (selectedVisitsNonBroadcasts.length > 0 && selectedBroadcasts.length === 0) ||
                                (selectedBroadcasts.length > 0 && selectedVisitsNonBroadcasts.length === 0)
                            ) && selectedVacations.length === 0
                        ) || $scope.isAllowItemsCheck === false;
                        break;
                    case "Delete":
                        action.isDisabled = !(
                            (
                                (selectedVisitsNonBroadcasts.length > 0 && selectedVacations.length === 0) ||
                                (selectedVacations.length > 0 && selectedVisitsNonBroadcasts.length === 0)
                            ) &&
                            selectedBroadcasts.length === 0
                        ) || $scope.isAllowItemsCheck === false;
                        break;
                    case "Stop Broadcast":
                        action.isDisabled = !(
                            selectedBroadcasts.length > 0 && 
                            selectedVacations.length === 0 &&
                            selectedVisitsNonBroadcasts.length === 0
                        ) || $scope.isAllowItemsCheck === false;
                        break;
                    default:
                        break;
                }
            });
        }

        const updateCaregiverItemsActionsDisables = () => {
            $scope.visitsActions['CAREGIVER'].forEach(action => {
                switch (action.text) {
                    case "Unassign":
                        action.isDisabled = $scope.selectedVisits.length === 0;
                        break;
                    default:
                        break;
                }
            });
        }

        $scope.updateItemsActionsDisables = () => {
            if ($scope.$ctrl.entityType === 'PATIENT') {
                updatePatientItemsActionsDisables();
            } else {
                updateCaregiverItemsActionsDisables();
            }
        }

        $scope.handleEditItemsClick = () => {
            $scope.$ctrl.onClickEditItems()();
        }

        $scope.handleDeleteItemsClick = () => {
            $scope.$ctrl.onClickDeleteItems()('scroll-list-view');
        }

        $scope.handleStopBroadcastVisitsClick = () => {
            entityNewVisitModalService.setStopBroadcastVisitData({
                editShiftsParams: 'CURRENT_SHIFTS',
            });
            $scope.$ctrl.onClickStopBroadcastItems()('scroll-list-view');
        }

        $scope.handleUnassignItemsClick = () => {
            const hasVisits = $scope.selectedVisits && $scope.selectedVisits.length > 0;
            if (!hasVisits) {
                return;
            }

            $scope.$ctrl.onClickUnassignItems();
        }

        $scope.visitsActions = {
            PATIENT: [
                {
                    text: "Edit",
                    variant: "primary",
                    callback: $scope.handleEditItemsClick,
                    isDisabled: true,
                },
                {
                    text: "Delete",
                    variant: "danger",
                    callback: $scope.handleDeleteItemsClick,
                    isDisabled: true,
                },
                {
                    text: "Stop Broadcast",
                    variant: "danger",
                    callback: $scope.handleStopBroadcastVisitsClick,
                    isDisabled: true,
                    title: 'Only broadcasted visits should be selected!',
                }
            ],
            CAREGIVER: [
                {
                    text: "Unassign",
                    variant: "danger",
                    callback: $scope.handleUnassignItemsClick,
                    isDisabled: true
                }
            ]
        }

        $scope.resetItemsSelections = () => {
            $scope.daysList.forEach(item => item.checked = false);
            $scope.updateItemsActionsDisables();
        }

        $scope.handleClickRow = (visitInstanceId) => {
            if (!visitInstanceId) {
                return;
            }

            $rootScope.openVisitInstanceModal(visitInstanceId);
        }

        $scope.handleClickCaregiver = ($event, caregiver) => {
            $event.stopPropagation();

            $rootScope.openCaregiverModal(caregiver.id);
        }

        const mapVisitInstanceToMfCalendarItem = (visitInstance) => {
            const type = getMfCalendarItemTypeByVisitInstance({
                caregiverId: visitInstance.visit.caregiverId,
                missedVisit: visitInstance.visit.missedVisit,
                removedAt: visitInstance.visit.removedAt,
                visitBroadcast: visitInstance.visitBroadcast,
            });

            const isVisitChecked = ({ visit }) => {
                const selecetedVisitsKeys = $scope.selectedVisits.map(x => x.key);
                return selecetedVisitsKeys.includes(`visit-${visit.id}`);
            };

            const visitStartDate = moment(visitInstance.visit.startTime);

            const caregiver = $scope.caregiversMap[visitInstance.visit.caregiverId];

            let mappedVisit = {
                key: `visit-${visitInstance.visit.id}`,
                visitInstanceId: visitInstance.visit.id,
                day: visitStartDate.format("dddd"),
                date: visitStartDate.format("MM.DD.YYYY"),
                itemDate: visitStartDate.format("MM/DD/YYYY"),
                startTime: visitStartDate,
                endTime: moment(visitInstance.visit.endTime),
                start: visitInstance.visit.startTime,
                end: visitInstance.visit.endTime,
                type: type,
                visitBatchId: visitInstance.visit.visitBatchId,
                visitBatchType: visitInstance.visitBatchType,
                visitBroadcast: visitInstance.visitBroadcast,
                visitBatchMinStartDate: visitInstance.visitBatchMinStartDate,
                visitBatchMaxEndDate: visitInstance.visitBatchMaxEndDate,
                caregiver: visitInstance.visit.caregiverId === null ? null : caregiver,
                certification: visitInstance.certification,
                statuses: getVisitStatuses(visitInstance),
                billedSeconds: visitInstance.billedSeconds,
                paidSeconds: visitInstance.paidSeconds,
                checked: isVisitChecked(visitInstance)
            }

            if ($scope.$ctrl.entityType === 'CAREGIVER') {
                mappedVisit.patientId = visitInstance.visit.patientId;
                mappedVisit.patientName = visitInstance.visit.patientName;
                mappedVisit.address = visitInstance.visit.address.address;
            }
    
            return mappedVisit;
        };

        const mapVacationToMfCalendarItem = (vacation) => {
            vacation.key = `vacation-${vacation.id}`;
            vacation.checked = $scope.selectedVacations.includes(vacation.key);
            vacation.type = "PATIENT_VACATION";
            vacation.statuses = [];
            vacation.itemDate = moment(vacation.date).format("MM/DD/YYYY")
            vacation.statuses.push(statusTypesMapping['PATIENT_VACATION']);
            return vacation;
        };

        const mapPtoInstanceToMfCalendarItem = (caregiverPto) => {
            return {
                ...caregiverPto,
                key: `pto-${caregiverPto.id}`,
                checked: false,
                type: "PAID_TIME_OFF",
                statuses: [
                    statusTypesMapping['PTO']
                ],
                itemDate: moment(caregiverPto.date).format("MM/DD/YYYY")
            };
        };

        $scope.onVisitsListTableDateRangeChanged = (startDate, endDate) => {
            $scope.daysList = [];
            $scope.startDate = startDate;
            $scope.endDate = endDate;

            let entityUrlPart = $scope.$ctrl.entityType === 'PATIENT' ? 'patients' : 'caregivers';
            let url = `agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/${entityUrlPart}/${$scope.$ctrl.entityId}/visit_instances_billing`;

            const fromParam = LocalDate.from(nativeJs(startDate));
            const toParam = LocalDate.from(nativeJs(endDate));
            url = `${url}?from=${fromParam}&to=${toParam}`;

            $scope.isLoadingDaysList = true;
            DatabaseApi.get(url)
                .then((res) => {
                    $scope.daysList = [
                        ...res.data.visitInstances.map(mapVisitInstanceToMfCalendarItem)
                    ];

                    if ($scope.$ctrl.entityType === "PATIENT") {
                        $scope.daysList = [
                            ...$scope.daysList,
                            ...res.data.patientVacations.map(mapVacationToMfCalendarItem)
                        ];
                    } else {
                        $scope.daysList = [
                            ...$scope.daysList,
                            ...res.data.ptoInstances.caregiverPtos.map(mapPtoInstanceToMfCalendarItem)
                        ];
                    }

                    initTable($scope.daysList);
                    $scope.updateItemsActionsDisables();
                }, (err) => toaster.pop('error', 'Error loading list visits'))
                .finally(() => $scope.isLoadingDaysList = false);
        }

        const getSelectedVisits = () => {
            $scope.selectedVisits = entityNewVisitModalService.selectedItems.visits;
        };
    
        const setSelectedVisits = (newSelectedVisits) => {
            entityNewVisitModalService.setSelectedItems({ visits: newSelectedVisits });
        };

        const getSelectedVacations = () => {
            $scope.selectedVacations = entityNewVisitModalService.selectedItems.vacations;
        };
    
        const setSelectedVacations = (newSelectedVacations) => {
            entityNewVisitModalService.setSelectedItems({ vacations: newSelectedVacations });
        };

        const updateSelectedItems = () => {
            if ($scope.daysList === undefined) {
                return;
            }

            const selectedItemsKeys = [
                ...$scope.selectedVisits.map(x => x.key),
                ...$scope.selectedVacations.map(x => x.key)
            ];
            $scope.daysList.forEach(item => {
                if (item.key) {
                    item.checked = selectedItemsKeys.includes(item.key);
                }
            });
            $scope.updateItemsActionsDisables();
        };

        const getMfCalendarItemTypeByVisitInstance =({
          caregiverId,
          missedVisit,
          removedAt,
          visitBroadcast,
        }) => {
          // DELETED_VISIT
          if (removedAt) {
            return CalendarItemType.DELETED_VISIT;
          }
    
          // MISSED_VISIT
          if (missedVisit) {
            return CalendarItemType.MISSED_VISIT;
          }
    
          // BROADCASTED_VISIT
          if (visitBroadcast !== null) {
            return CalendarItemType.BROADCASTED_VISIT;
          }
    
          // UNSTAFFED_VISIT
          if (caregiverId === null) {
            return CalendarItemType.UNSTAFFED_VISIT;
          }
    
          // ASSIGNED_VISIT
          return CalendarItemType.ASSIGNED_VISIT;
        };

        $scope.isAllowItemsCheck = !$rootScope.isNewVisitSideModalOpen;

        $rootScope.$on("calendar_new_visit", () => {
            $scope.resetItemsSelections();
            $scope.isAllowItemsCheck = false;
            $scope.updateItemsActionsDisables();
        });

        $rootScope.$on("calendar_edit_visit", () => {
            $scope.isAllowItemsCheck = false;
            $scope.updateItemsActionsDisables();
        });
    
        $rootScope.$on("close_new_visit_modal", () => {
            $scope.isAllowItemsCheck = true;
            $scope.updateItemsActionsDisables();
        });
    
        getSelectedVisits();
        getSelectedVacations();

        entityNewVisitModalService.registerObserverCallback("visits", "visitsListView", () => {
            getSelectedVisits();
            updateSelectedItems();
        });
    
        entityNewVisitModalService.registerObserverCallback("vacations", "visitsListView", () => {
            getSelectedVacations();
            updateSelectedItems();
        });

        $scope.$watch('visitsListFilters', function () {
            filterVisiListTable();
        }, true);

        $rootScope.$on("got_caregivers_data", function (event) {
            $scope.caregiversMap = DatabaseApi.caregivers();
        });

        $rootScope.$on("refresh_visits", () => {
            $scope.onVisitsListTableDateRangeChanged($scope.startDate, $scope.endDate);
            $scope.resetItemsSelections();
        });
    }
};
