import { CloseIcon, PhoneIcon } from "@chakra-ui/icons";
import { Box, Portal } from "@chakra-ui/react";
import React from "react";
import usePhoneNumberDialer from "../hooks/usePhoneNumberDialer";
import useUnstableAngularizeDigestWorkaround from "../hooks/useUnstableAngularizeDigestWorkaround";
import Dialpad from "./DialPad";

interface Props {
  predefinedPhoneNumber?: string;
  onClose: () => void;
}

const PhoneNumberDialer = (props: Props) => {
  useUnstableAngularizeDigestWorkaround();
  const { dialNumber, isCalling } = usePhoneNumberDialer(props.onClose);
  const handleDialPhoneNumber = (number: string) => {
    dialNumber(number);
  };
  return (
    <Portal>
      <Box
        id="call-center-dialer"
        borderTopRadius="2xl"
        position="fixed"
        bottom={39}
        gap={2}
        display={"flex"}
        right={55}
        zIndex={10000}
        bg="white"
        boxShadow="0 0 16px -2px rgba(0,0,0,0.25)"
        transition="all 250ms ease"
        w={"400px"}
        padding={10}
        flexDirection={"column"}
      >
        <CloseIcon cursor={"pointer"} onClick={props.onClose} />
        <Dialpad
          state={{
            type: "PhoneNumberDialer",
            dialButtonConent: "Call",
            dialButtonLeftIcon: <PhoneIcon />,
            predefinedPhoneNumber: props.predefinedPhoneNumber,
            onDialNumber: handleDialPhoneNumber,
            isCalling,
          }}
          flexProps={{ w: "full" }}
        />
      </Box>
    </Portal>
  );
};

export default PhoneNumberDialer;
