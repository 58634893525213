import { CheckIcon, CloseIcon, InfoIcon } from "@chakra-ui/icons";
import { Button, Flex, Heading, Text } from "@chakra-ui/react";
import { Instant } from "@js-joda/core";
import React from "react";
import { Messages, ResponseOf } from "../../../core/api";
import { PatientId } from "../../../shared/schema/schema";
import IntakePatientProfileInput from "./IntakePatientProfileInput";

interface Props {
  isEditMode: boolean;
  patientId: PatientId;
  medicareId: string | null;
  medicaidId: string | null;
  medicaidStatus: Messages["IntakePatientProfile"]["medicaidStatus"];
  eligibilityCheckResults: ResponseOf<"get", "./eligibility_checks">["records"];
  onChangeIds: (field: "medicareId" | "medicaidId", value: string | null) => void;
  onClickCheckEligibility: (patientId: PatientId) => void;
}

const IntakeProfileMedInfoSection = (props: Props) => {
  const [isEligibilitySectionOpen, setIsEligibilitySectionOpen] = React.useState<boolean>(false);

  const sortByTime = (first: Instant, second: Instant) => {
    if (first.isAfter(second)) {
      return 1;
    }

    if (first.isBefore(second)) {
      return -1;
    }

    return 0;
  };

  const getPatientEligibilityRes = () => {
    const res = props.eligibilityCheckResults
      .filter((res) => res.patientId === props.patientId)
      .sort((res1, res2) => sortByTime(res1.createdAt, res2.createdAt));
    return res === undefined ? null : (res.at(0)?.vendorResponse as Record<string, string>) ?? null;
  };

  return (
    <Flex direction="column" gap={4}>
      <Flex direction="row" justifyContent="space-between" alignItems="baseline">
        <Heading variant="profileHeaderUnderlined" as="h2" size="md">
          Medicare & Medicaid Information{" "}
        </Heading>
        <Button
          colorScheme="blue"
          marginRight="15px"
          onClick={() => props.onClickCheckEligibility(props.patientId)}
        >
          {" "}
          Check Eligibility{" "}
        </Button>
      </Flex>
      <Flex direction="row" justifyContent="space-between">
        <Flex direction="row" gap={4}>
          <Flex direction="column" gap={4}>
            <Flex direction="row" gap={2} alignItems="center" width="150px">
              {props.medicaidId !== null ? (
                <CheckIcon fontSize="xl" color="green.300" />
              ) : (
                <CloseIcon fontSize="xl" color="red.300" />
              )}
              <Text size="xl">Medicaid ID</Text>
            </Flex>
            <IntakePatientProfileInput
              isEditable={props.isEditMode}
              isRequired={false}
              label=""
              value={props.medicaidId ?? ""}
              styleProps={{ width: "200px" }}
              onChange={(value) => props.onChangeIds("medicaidId", value)}
            />
          </Flex>
          <Flex direction="column" gap={4}>
            <Flex direction="row" gap={2} alignItems="center">
              {props.medicareId !== null ? (
                <CheckIcon fontSize="xl" color="green.300" />
              ) : (
                <CloseIcon fontSize="xl" color="red.300" />
              )}
              <Text size="xl"> Medicare ID </Text>
            </Flex>
            <IntakePatientProfileInput
              isEditable={props.isEditMode}
              isRequired={false}
              label=""
              value={props.medicareId ?? ""}
              styleProps={{ width: "200px" }}
              onChange={(value) => props.onChangeIds("medicareId", value)}
            />
          </Flex>
        </Flex>
        <Button
          alignSelf="baseline"
          variant="ghost"
          leftIcon={<InfoIcon />}
          onClick={() => setIsEligibilitySectionOpen(!isEligibilitySectionOpen)}
        >
          {" "}
          See Check Results{" "}
        </Button>
      </Flex>
      {!isEligibilitySectionOpen ? (
        <></>
      ) : (
        <EligibilityResultsSection results={getPatientEligibilityRes()} />
      )}
    </Flex>
  );
};

export default IntakeProfileMedInfoSection;

interface EligibilitySectionProps {
  results: Record<string, string> | null;
}

const EligibilityResultsSection = (props: EligibilitySectionProps) => {
  const formatResults = () => {
    return Object.entries(props.results ?? []).map(([key, value]) => {
      return (
        <Flex direction="row" gap={4}>
          <Text>
            <u>{key}:</u>
          </Text>
          <Text>{value}</Text>
        </Flex>
      );
    });
  };

  return (
    <Flex direction="column" gap={6} maxHeight="280px" overflowY="scroll">
      <Heading as="h4" size="sm">
        {" "}
        Eligibility Check Results
      </Heading>
      {props.results === null ? (
        <Text> No eligibility check was made for this patient. Run one now! </Text>
      ) : (
        <Flex direction="column" gap={4}>
          {formatResults()}
        </Flex>
      )}
    </Flex>
  );
};
