import { useCurrentStateAndParams } from "@uirouter/react";
import { CaregiverEntity, PatientEntity } from "../components/EntityCard";

export function useEntityLink() {
  const route = useCurrentStateAndParams();

  const open = (params: {
    event: React.MouseEvent<HTMLDivElement>;
    entity: Pick<CaregiverEntity | PatientEntity, "id" | "type">;
  }) => {
    if (params.event.metaKey || params.event.ctrlKey) {
      const currentUrl = Object.entries(route.params).reduce(
        (acc, [key, value]) => acc.replace(`:${key}`, value),
        route.state.url ?? ""
      );

      const queryParam = params.entity.type === "Caregiver" ? "caregiver" : "patient";

      const entityUrl = `app${currentUrl}?${queryParam}=${params.entity.id}`;
      window.open(entityUrl, "_blank");
      return;
    }

    window.dispatchEvent(
      new CustomEvent("from-webapp-react", {
        detail: { type: "navigate", payload: { id: params.entity.id, entity: params.entity.type } },
      })
    );
  };

  return { open };
}
