import { PatientId } from "../../shared/schema/schema";
import { createQueryKeys } from "../../shared/utils/create-query-keys";

export const patientIntakeKeys = createQueryKeys("patient_intake", {
  search: (params: Record<string, unknown>) => params,
  get: (id: PatientId) => id,
  medicaidStatus: null,
  intakeStatus: null,
  intakePlan: null,
});
