import { ChangeEventHandler, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { GOOGLE_MAPS_API_KEY } from "../consts";

export default function useGoogleMapsAddress() {
  const [input, setInput] = useState("");
  const [showPredictions, setShowPredictions] = useState(false);

  const { placesService, placePredictions, isPlacePredictionsLoading, getPlacePredictions } =
    usePlacesService({
      apiKey: GOOGLE_MAPS_API_KEY,
      debounce: 250,
      language: "en",
    });

  const getDetails = (
    prediction: google.maps.places.AutocompletePrediction
  ): Promise<google.maps.places.PlaceResult | null> => {
    return new Promise((resolve, reject) => {
      placesService?.getDetails({ placeId: prediction.place_id }, (placeResult, status) => {
        if (
          status === google.maps.places.PlacesServiceStatus.OK ||
          status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS
        ) {
          resolve(placeResult);
        } else {
          reject(status);
        }
      });
    });
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setInput(event.target.value);
    setShowPredictions(event.target.value !== "");
    getPlacePredictions({ input });
  };

  return {
    input,
    placePredictions,
    isPlacePredictionsLoading,
    handleChange,
    getDetails,
    showPredictions,
    setShowPredictions,
  };
}
