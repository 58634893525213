import { useState } from "react";
import { SingleDigitDialPadState } from "../components/DialPad";

interface Actions {
  onClickDigit?: (digit: string, acc: string) => void;
}

const useSingleDigitDialPad = (
  state?: Actions
): { state: SingleDigitDialPadState; accInput: string } => {
  const [accInput, setAccInput] = useState("");
  const handleClickDigit = (digit: string | number, acc: string) => {
    setAccInput(acc);
    if (state?.onClickDigit !== undefined) {
      state.onClickDigit(digit.toString(), acc);
    }
  };
  return {
    state: {
      type: "SingleDigitDialer",
      currentState: accInput,
      onClickDigit: handleClickDigit,
    },
    accInput,
  };
};

export default useSingleDigitDialPad;
