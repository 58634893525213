import { PhoneIcon } from "@chakra-ui/icons";
import { Flex, FlexProps, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React from "react";
import Dialpad from "../../../shared/components/DialPad";
import { useSearchCaregiversQuery } from "../../caregiver/hooks/useSearchCaregiversQuery";
import { useSearchPatientsQuery } from "../../patient/hooks/useSearchPatientsQuery";
import DialerCaregiversSearch from "../../../shared/components/DialerCaregiversSearch";
import DialerPatientsSearch from "../../../shared/components/DialerPatientsSearch";
import { AgencyMemberId } from "../../../shared/schema/schema";
import DialerAgencyMembersSearch from "../../../shared/components/DialerAgencyMembersSearch";

export type SearchEntity =
  | (NonNullable<ReturnType<typeof useSearchCaregiversQuery>["data"]>[number] & {
      type: "Caregiver";
    })
  | (NonNullable<ReturnType<typeof useSearchPatientsQuery>["data"]>[number] & {
      type: "Patient";
    });

export interface DialerTabsContext {
  hidePatients: boolean;
  hideCaregivers: boolean;
  hidePhoneNumber: boolean;
  hideAgencyMembers: boolean;
}

interface Props {
  isCalling: boolean;
  context: DialerTabsContext;
  flexProps?: FlexProps;
  onClickDial: (E164number: string) => void;
  onClickAgencyMember: (agencyMemberId: AgencyMemberId) => void;
}

const DialerTabs = (props: Props) => {
  const {
    context: { hideAgencyMembers, hideCaregivers, hidePatients, hidePhoneNumber },
  } = props;
  return (
    <Flex {...props.flexProps}>
      <Tabs align={"center"} variant={"enclosed"} w={"full"}>
        <TabList gap={4}>
          {!hidePhoneNumber && <Tab>Number</Tab>}
          {!hideAgencyMembers && <Tab>Agency Members</Tab>}
          {!hidePatients && <Tab>Patients</Tab>}
          {!hideCaregivers && <Tab>Caregivers</Tab>}
        </TabList>
        <TabPanels>
          {!hidePhoneNumber && (
            <TabPanel>
              <Dialpad
                flexProps={{ w: "60%" }}
                state={{
                  type: "PhoneNumberDialer",
                  dialButtonConent: "Call",
                  dialButtonLeftIcon: <PhoneIcon />,
                  isCalling: props.isCalling,
                  onDialNumber: props.onClickDial,
                }}
              />
            </TabPanel>
          )}
          {!hideAgencyMembers && (
            <TabPanel>
              <DialerAgencyMembersSearch onClickAgencyMember={props.onClickAgencyMember} />
            </TabPanel>
          )}
          {!hidePatients && (
            <TabPanel
              hidden={props.context.hidePatients}
              as={Flex}
              textAlign={"start"}
              gap={4}
              flexDirection={"column"}
            >
              <DialerPatientsSearch onClickDial={props.onClickDial} />
            </TabPanel>
          )}
          {!hideCaregivers && (
            <TabPanel
              hidden={props.context.hideCaregivers}
              as={Flex}
              textAlign={"start"}
              gap={4}
              flexDirection={"column"}
            >
              <DialerCaregiversSearch onClickDial={props.onClickDial} />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default DialerTabs;
