import { CloseIcon } from "@chakra-ui/icons";
import { Box, Center, Divider, Flex, IconButton, Portal, useToast } from "@chakra-ui/react";
import { noop } from "@chakra-ui/utils";
import { Instant } from "@js-joda/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { match, P } from "ts-pattern";
import { Messages } from "../../../core/api";
import * as callCenterUtils from "../../../shared/utils/call-center";
import EntityCard, {
  CaregiverEntity,
  Entity,
  EntityWithStatus,
  NotIdentifiedPhoneNumberEntity,
  PatientEntity,
} from "../../../shared/components/EntityCard";
import LoadingPage from "../../../shared/components/LoadingPage";
import useApi from "../../../shared/hooks/useApi";
import useLiveCallTicketIds from "../../../shared/hooks/useLiveCallTicketIds";
import useAuthData from "../../../shared/hooks/useAuthInfo";
import useSocketEvent from "../../../shared/hooks/useSocketEvent";
import useUnstableAngularizeDigestWorkaround from "../../../shared/hooks/useUnstableAngularizeDigestWorkaround";
import MaximizeIcon from "../../../shared/icons/MaximizeIcon";
import MinimizeIcon from "../../../shared/icons/MinimizeIcon";
import { queryKeys } from "../../../shared/query-keys";
import {
  CaregiverId,
  CommCenterMessageId,
  CommCenterTeamId,
  CommCenterTeamMemberId,
  CommCenterTicketId,
  NoteSubjectId,
  PatientId,
} from "../../../shared/schema/schema";
import { formatErrorResponse } from "../../../shared/utils/format-response-error";
import { getFullName } from "../../../shared/utils/get-full-name";
import { loadable } from "../../../shared/utils/loadable";
import { optimisticUpdate } from "../../../shared/utils/optimistic-update";
import useTicketViewMutation from "../hooks/useTicketViewMutation";
import { CreateMessageRequest } from "../pages/CommunicationCenterTicket/CommunicationCenterTicketRoute";
import {
  getCommCenterTeamMemberIdByAgencyMemberId,
  getLatestCommCenterMessageByCaregiver,
  NewTicketRequestBody,
  sortTicketsByLastMessage,
} from "../utils/communication-utils";
import TicketsBox from "./TicketsBox";
import useActiveCall from "../../../shared/hooks/useActiveCall";

type BaseProps = { onClose: () => void; defaultTicketId?: CommCenterTicketId };
type WithCaregiverId = {
  caregiverId: CaregiverId;
  patientId: undefined;
  notIdentifiedPhoneNumber: undefined;
};
type WithPatientId = {
  caregiverId: undefined;
  patientId: PatientId;
  notIdentifiedPhoneNumber: undefined;
};
type WithNotIdentifiedPhoneNumber = {
  caregiverId: undefined;
  patientId: undefined;
  notIdentifiedPhoneNumber: string;
};
type Props = BaseProps & (WithCaregiverId | WithPatientId | WithNotIdentifiedPhoneNumber);

const getEntityFromQueriesData = (
  caregiverEntitiyData: Entity | undefined,
  patientEntityData: Entity | undefined,
  notIdentifiedPhoneNumberEntity: Entity | null
) => {
  const entity = caregiverEntitiyData ?? patientEntityData ?? notIdentifiedPhoneNumberEntity;
  if (entity === null) {
    throw new Error("No related entity found! no patient or caregiver with these ids.");
  }

  return entity;
};

const CommCenterChatWrapper = (props: Props) => {
  useUnstableAngularizeDigestWorkaround();

  const { api } = useApi();
  const queryClient = useQueryClient();
  const toast = useToast();
  const { agencyMember } = useAuthData();
  const { activeCall, setActiveCall } = useActiveCall();

  const [isMinimized, setIsMinimized] = useState(false);
  const [activeTicketId, setActiveTicketId] = useState<CommCenterTicketId | null>(
    props.defaultTicketId ?? null
  );

  const teams = useQuery({
    queryKey: queryKeys.commCenter.teams(),
    queryFn: () => api.get("./comm_center/teams", {}),
    keepPreviousData: true,
    onError: (error) => {
      toast({
        title: "Error in fetching communication center teams",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const labels = useQuery({
    queryKey: queryKeys.commCenter.labels(),
    queryFn: () => api.get("./comm_center/labels", {}),
    select: (response) => response.labels,
    keepPreviousData: true,
    onError: (error) => {
      toast({
        title: "Error in fetching communication center labels",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const caregiverEntity = useQuery({
    queryKey: queryKeys.caregiver.get(props.caregiverId!),
    enabled: props.caregiverId !== undefined,
    queryFn: () => {
      return api.get("/agencies/:agencyId/caregivers/:caregiverId", {
        path: { caregiverId: props.caregiverId! },
      });
    },
    select: (caregiver): EntityWithStatus<CaregiverEntity> => ({
      type: "Caregiver",
      id: caregiver.id,
      displayId: caregiver.displayId,
      status: caregiver.status,
      photoUrl: caregiver.photoUrl,
      fullName: getFullName(caregiver),
    }),
    onError: (error) => {
      toast({
        title: `Couldn't get caregiver details for caregiver ${props.caregiverId}`,
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const patientEntity = useQuery({
    queryKey: queryKeys.patient.get(props.patientId!),
    enabled: props.patientId !== undefined,
    queryFn: () => {
      return api.get("/agencies/:agencyId/patients/:patientId", {
        path: { patientId: props.patientId! },
      });
    },
    select: (patient): EntityWithStatus<PatientEntity> => ({
      type: "Patient",
      id: patient.id,
      displayId: patient.displayId,
      status: patient.status,
      fullName: getFullName(patient),
      gender: patient.gender,
    }),
    onError: (error) => {
      toast({
        title: `Couldn't get patient details for patient ${props.patientId}`,
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const notIdentifiedPhoneNumberEntity: EntityWithStatus<NotIdentifiedPhoneNumberEntity> | null =
    props.notIdentifiedPhoneNumber === undefined
      ? null
      : {
          type: "NotIdentifiedPhoneNumber",
          phoneNumber: props.notIdentifiedPhoneNumber,
          status: null,
        };

  const relatedTicketsQueryParams = match(props)
    .with({ patientId: P.not(undefined) }, (x) => ({ patientId: [x.patientId] }))
    .with({ caregiverId: P.not(undefined) }, (x) => ({ caregiverId: [x.caregiverId] }))
    .with({ notIdentifiedPhoneNumber: P.not(undefined) }, (x) => ({
      relatedNotIdentifiedPhoneNumber: [x.notIdentifiedPhoneNumber],
    }))
    .exhaustive();

  const relatedTickets = useQuery({
    queryKey: queryKeys.commCenter.search(relatedTicketsQueryParams),
    enabled: (props.caregiverId ?? props.patientId ?? props.notIdentifiedPhoneNumber) !== undefined,
    queryFn: () => {
      return api.get("./comm_center/tickets", {
        query: relatedTicketsQueryParams,
      });
    },
    keepPreviousData: true,
    onError: (error) => {
      toast({
        title: "Could not get related tickets",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
    select: (response) => sortTicketsByLastMessage(response.tickets),
  });

  const activeTicket = useQuery({
    queryKey: queryKeys.commCenter.get(activeTicketId!),
    enabled: activeTicketId !== null,
    keepPreviousData: true,
    select: (data) => data.ticket,
    queryFn: () => {
      return api.get("./comm_center/tickets/:ticketId", {
        path: {
          ticketId: activeTicketId!,
        },
      });
    },
    onSuccess: ({ id, messages }) => {
      const latestReceivedMessage = getLatestCommCenterMessageByCaregiver(messages);

      if (latestReceivedMessage?.readAt === null) {
        markAsRead.mutate({
          ticketId: id,
          latestReceivedMessageId: latestReceivedMessage.id,
        });
      }
    },
    onError: (error) => {
      toast({
        title: `Error in fetching ticket ${activeTicketId}`,
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const createTicket = useMutation({
    mutationFn: (newTicketRequest: NewTicketRequestBody) => {
      return api.post(
        "/agencies/:agencyId/comm_center_team_members/:commCenterTeamMemberId/comm_center/tickets",
        {
          path: {
            commCenterTeamMemberId:
              getCommCenterTeamMemberIdByAgencyMemberId(teams.data?.teams ?? [], agencyMember.id) ??
              CommCenterTeamMemberId.wrap(-1),
          },
          body: newTicketRequest,
        }
      );
    },
    onSuccess: (response) => {
      toast({
        title: "Ticket created successfuly",
        status: "success",
        position: "top-right",
      });

      setActiveTicketId(response.ticketId);
      relatedTickets.refetch();
    },
    onError: (error) => {
      toast({
        title: "Could not create new ticket",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const markAllAsUnread = useMutation({
    mutationFn: (ticketId: CommCenterTicketId) => {
      return api.post("./comm_center/tickets/:ticketId/unread", {
        path: { ticketId },
      });
    },
    onMutate: () => {
      return optimisticUpdate<{ tickets: Messages["CommCenterTicket"][] }>({
        queryClient,
        queryKey: queryKeys.commCenter.search.K,
        update: (draft) => {
          draft.tickets
            .find((ticket) => ticket.id === activeTicketId)
            ?.messages.forEach((message) => (message.readAt = null));
        },
      });
    },
    onSuccess: () => {
      toast({
        title: "All messages marked unread.",
        status: "success",
        position: "top-right",
      });

      if (activeTicketId !== null) {
        setTimeout(
          () => queryClient.invalidateQueries(queryKeys.commCenter.get(activeTicketId)),
          500
        );
      }

      setActiveTicketId(null);
    },
    onError: (error) => {
      toast({
        title: "Error marking ticket as unread",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const submitMessage = useMutation({
    mutationFn: (createParams: CreateMessageRequest) => {
      return api.post("./comm_center/tickets/:commCenterTicketId/message", {
        path: {
          commCenterTicketId: createParams.ticketId,
        },
        body: {
          message: [
            {
              type: "TEXT",
              message: createParams.message,
            },
          ],
        },
      });
    },
    onMutate: async (createParams) => {
      return optimisticUpdate<{ tickets: Messages["CommCenterTicket"][] }>({
        queryClient,
        queryKey: queryKeys.commCenter.search.K,
        update: (draft) => {
          draft.tickets
            .find((ticket) => ticket.id === createParams.ticketId)
            ?.messages.push({
              id: CommCenterMessageId.wrap(Instant.now().toEpochMilli() * -1),
              createdBy: {
                type: "Agency Member",
                id: agencyMember.id,
                name: getFullName(agencyMember),
                photoUrl: agencyMember.photoUrl,
              },
              payload: [{ type: "TEXT", message: createParams.message }],
              labelId: null,
              readAt: null,
              messageActionId: null,
              ticketId: createParams.ticketId,
              createdAt: Instant.now(),
            });
        },
      });
    },
    onError: (error, _newChatMessage, context) => {
      queryClient.setQueryData(["tickets"], context?.previousValue);

      toast({
        title: "Could not send message.",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
    onSuccess: () => {
      toast({
        title: "Message sent successfully",
        status: "success",
        position: "top-right",
      });

      relatedTickets.refetch();
      activeTicket.refetch();
    },
  });

  const editTicket = useMutation({
    mutationFn: (params: {
      ticketId: CommCenterTicketId;
      body: Messages["Partial<EditCommCenterTicketParams>"];
    }) => {
      return api.patch("./comm_center/tickets/:ticketId/edit", {
        path: { ticketId: params.ticketId },
        body: { params: params.body },
      });
    },
    onMutate: async (params) => {
      const ticket = optimisticUpdate<{ ticket: Messages["CommCenterTicket"] }>({
        queryClient,
        queryKey: queryKeys.commCenter.get(activeTicketId!),
        update: (draft) => {
          if (params.body.teamId !== undefined) {
            draft.ticket.relatedTeam.id = params.body.teamId;
            draft.ticket.assignedTo = null;
          }

          if (params.body.assignedToId !== undefined) {
            draft.ticket.assignedTo = {
              id: params.body.assignedToId,
              name: "Loading...",
            };
          }

          if (params.body.status !== undefined) {
            draft.ticket.status = params.body.status;
          }

          if (params.body.patientId !== undefined) {
            draft.ticket.relatedPatient =
              params.body.patientId === null
                ? null
                : {
                    id: params.body.patientId,
                    displayId: null,
                    gender: null,
                    name: "Loading...",
                    status: "ACTIVE",
                  };
          }

          if (params.body.caregiverId !== undefined) {
            draft.ticket.relatedCaregiver =
              params.body.caregiverId === null
                ? null
                : {
                    id: params.body.caregiverId,
                    displayId: null,
                    onboardingStageDetails: null,
                    photoUrl: null,
                    name: "Loading...",
                    status: "ACTIVE",
                  };
          }
        },
      });

      const tickets = optimisticUpdate<{ tickets: Messages["CommCenterTicket"][] }>({
        queryClient,
        queryKey: queryKeys.commCenter.search.K,
        update: (draft) => {
          const ticket = draft.tickets.find((ticket) => ticket.id === activeTicketId);

          if (ticket === undefined) {
            return;
          }

          if (params.body.teamId !== undefined) {
            ticket.relatedTeam.id = params.body.teamId;
            ticket.assignedTo = null;
          }

          if (params.body.assignedToId !== undefined) {
            ticket.assignedTo = {
              id: params.body.assignedToId,
              name: "Loading...",
            };
          }

          if (params.body.status !== undefined) {
            ticket.status = params.body.status;
          }
        },
      });

      return { ticket, tickets };
    },
    onSuccess: () => {
      toast({ title: "Ticket updated", status: "success", position: "top-right", duration: 2000 });
    },
    onError: (error, _, context) => {
      toast({
        title: `Could not update ticket ${activeTicketId}`,
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });

      if (activeTicketId !== null) {
        queryClient.setQueryData(
          queryKeys.commCenter.get(activeTicketId),
          context?.ticket.previousValue
        );
      }

      queryClient.setQueryData(queryKeys.commCenter.search.K, context?.tickets.previousValue);
    },
  });

  const noteSettings = useQuery({
    queryKey: ["notes_settings"],
    queryFn: () => api.get("./notes_settings", {}),
  });

  const liveCallTickets = useLiveCallTicketIds();

  const noteSubjects: { label: string; value: NoteSubjectId }[] =
    noteSettings.data?.agencyNoteSubjects?.map((x) => ({
      label: x.text,
      value: x.id,
    })) ?? [];

  useSocketEvent({
    key: "CommCenterTicketUpdated",
    onEvent: (event) => {
      relatedTickets.refetch();

      if (event.ticketId === activeTicketId) {
        activeTicket.refetch();
      }
    },
  });

  useSocketEvent({
    key: "CallCenterCallParticipantEvent",
    onEvent: (event) => {
      if (event.ticketId === activeTicketId) {
        activeTicket.refetch();
      }
    },
  });

  useSocketEvent({
    key: "CallCenterCallEnd",
    onEvent: (event) => {
      if (event.ticketId === activeTicketId) {
        activeTicket.refetch();
      }
    },
  });

  useSocketEvent({
    key: "CallCenterCallStart",
    onEvent: (event) => {
      if (event.ticketId === activeTicketId) {
        activeTicket.refetch();
      }
    },
  });

  const markAsRead = useTicketViewMutation();

  const handleEditTicket = (
    ticketId: CommCenterTicketId,
    body: Messages["Partial<EditCommCenterTicketParams>"]
  ) => {
    editTicket.mutate({ ticketId, body });
  };

  const handleClickTicket = (newTicketId: CommCenterTicketId) => {
    setActiveTicketId(newTicketId);
  };

  const handleMarkAsUnread = (ticketId: CommCenterTicketId) => {
    markAllAsUnread.mutate(ticketId);
  };

  const handleSubmitNewMessage = (ticketId: CommCenterTicketId, message: string) => {
    submitMessage.mutate({ ticketId, message });
  };

  const handleCreateNewTicket = (newTicketRequest: NewTicketRequestBody) => {
    createTicket.mutate(newTicketRequest);
  };

  const areActionsDisabled = activeTicket.data?.status === "RESOLVED" || activeTicketId === null;

  const handleClickHangup = () => {
    callCenterUtils.hangupCall(() => {
      if (activeCall !== null) {
        setActiveCall({ ...activeCall, status: "Completed" });
      }
      console.log("hang up success");
    });
  };

  const primaryEntity =
    caregiverEntity.data ?? patientEntity.data ?? notIdentifiedPhoneNumberEntity;

  return (
    <Portal>
      <Box
        id="comm-center-chat-window"
        borderTopRadius="2xl"
        position="fixed"
        bottom={39}
        right={55}
        zIndex={10000}
        bg="white"
        boxShadow="0 0 16px -2px rgba(0,0,0,0.25)"
        transition="all 250ms ease"
        w={isMinimized ? 300 : 1200}
        transform={isMinimized ? `translateY(calc(100% - 49px)) ` : undefined}
        sx={{
          "--max-chat-height": "60vh",
        }}
      >
        <Flex p={2}>
          <IconButton
            aria-label="close"
            variant="ghost"
            icon={<CloseIcon fontSize={12} />}
            onClick={props.onClose}
            borderRadius="xl"
          />
          <IconButton
            aria-label="close"
            variant="ghost"
            icon={<MinimizeIcon fontSize={18} />}
            onClick={() => setIsMinimized(true)}
            borderRadius="xl"
            hidden={isMinimized}
          />
          <IconButton
            aria-label="close"
            variant="ghost"
            icon={<MaximizeIcon fontSize={18} />}
            onClick={() => setIsMinimized(false)}
            borderRadius="xl"
            hidden={!isMinimized}
          />
        </Flex>

        <Divider />

        {relatedTickets.isLoading ||
        noteSettings.isLoading ||
        liveCallTickets.isLoading ||
        primaryEntity === null ? (
          <Center maxH="65vh">
            <LoadingPage />
          </Center>
        ) : (
          <TicketsBox
            liveCallTicketIds={liveCallTickets.data ?? []}
            noteSubjects={noteSubjects}
            activeTicket={activeTicket.data ?? null}
            entity={loadable.resolve(
              getEntityFromQueriesData(
                caregiverEntity.data,
                patientEntity.data,
                notIdentifiedPhoneNumberEntity
              )
            )}
            label={activeTicket.data?.label?.name ?? null}
            settings={{
              assignedToId: activeTicket.data?.assignedTo?.id ?? null,
              status: activeTicket.data?.status ?? "NEW",
              teamId: activeTicket.data?.relatedTeam.id ?? CommCenterTeamId.wrap(0),
            }}
            onboardingStageName={
              activeTicket.data?.relatedCaregiver?.onboardingStageDetails?.name ?? null
            }
            entityCardAs={EntityCard}
            labels={labels.data?.filter((label) => label.active) ?? []}
            initialLabelId={
              labels.data?.find((label) => label.parent === null && !label.active)?.id ?? null
            }
            teams={teams.data?.teams ?? []}
            tickets={loadable.fromUndefined(relatedTickets.data)}
            isNewTicketOpen={activeTicketId === null}
            areActionsDisabled={areActionsDisabled}
            onRequestCloseNewTicket={noop}
            onChangeSettings={handleEditTicket}
            onClickTicket={handleClickTicket}
            onClickMarkAsUnread={handleMarkAsUnread}
            onSubmitNewMessage={handleSubmitNewMessage}
            onCreateNewTicket={handleCreateNewTicket}
            onClickNewTicket={() => setActiveTicketId(null)}
            onClickHangup={handleClickHangup}
          />
        )}
      </Box>
    </Portal>
  );
};

export default CommCenterChatWrapper;
