import { FormControl, FormErrorMessage, FormLabel, StyleProps } from "@chakra-ui/react";
import { LocalDate, LocalDateTime } from "@js-joda/core";
import React from "react";
import SingleDatePicker from "../../../shared/components/DatePicker/SingleDatePicker";
import SingleDateTimePicker from "../../../shared/components/DatePicker/SingleDateTimePicker";
import { fmap } from "../../../shared/utils";
import { dateFormatter } from "../../../shared/utils/date-formatter";

interface IntakeProfileSingleDatepickerProps {
  isEditable: boolean;
  isRequired: boolean;
  label: string;
  value: LocalDate | LocalDateTime | null;
  withTime?: boolean;
  validateSchema?: Zod.Schema;
  styleProps?: StyleProps;
  placement?:
    | "top"
    | "top-end"
    | "top-start"
    | "bottom"
    | "bottom-end"
    | "bottom-start"
    | "right"
    | "right-start"
    | "right-end"
    | "left"
    | "left-end"
    | "left-start";
  pickerYearsRange?: [number, number];
  onChange: (value: LocalDate | LocalDateTime | null) => void;
}

const IntakePatientProfileDatepicker = (props: IntakeProfileSingleDatepickerProps) => {
  const [error, setError] = React.useState<string | null>(null);

  const validate = (value: LocalDate | LocalDateTime | null) => {
    if (props.validateSchema === undefined || value === null) {
      setError(null);
    } else {
      const result = props.validateSchema.safeParse(value);
      setError(!result.success ? result.error.flatten().formErrors.at(0) ?? null : null);
    }
  };

  return (
    <FormControl
      isDisabled={!props.isEditable}
      isRequired={props.isEditable ? props.isRequired : false}
    >
      <FormLabel _disabled={{ color: "black" }}> {props.label} </FormLabel>
      {!props.withTime ? (
        <SingleDatePicker
          disabled={!props.isEditable}
          pickerYears={props.pickerYearsRange ?? [LocalDate.now().year(), 2100]}
          autoComplete="new-pasword"
          selected={props.value}
          popperPlacement={props.placement}
          value={fmap(props.value, dateFormatter.toDate) ?? undefined}
          onBlur={() => validate(props.value)}
          onChange={props.onChange}
        />
      ) : (
        <SingleDateTimePicker
          disabled={!props.isEditable}
          pickerYears={props.pickerYearsRange ?? [LocalDate.now().year(), 2100]}
          autoComplete="new-pasword"
          selected={props.value}
          popperPlacement={props.placement}
          value={
            !(props.value instanceof LocalDateTime)
              ? ""
              : fmap(props.value, dateFormatter.toDateTime) ?? undefined
          }
          onBlur={() => validate(props.value)}
          onChange={props.onChange}
        />
      )}

      <FormErrorMessage>{error ?? ""}</FormErrorMessage>
    </FormControl>
  );
};

export default IntakePatientProfileDatepicker;
