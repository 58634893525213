import { Text, Flex } from "@chakra-ui/react";
import React from "react";
import { Messages } from "../../../../core/api";
import { dateFormatter } from "../../../../shared/utils/date-formatter";

interface Props {
  note: Messages["Note"];
}

const CallNoteCard = ({ note }: Props) => {
  return (
    <Flex direction={"column"}>
      <Text>
        <b>Subject:</b> {note.subject?.name ?? ""}
      </Text>
      <Text>
        <b>Content:</b> {note.noteRichText}
      </Text>
      <Flex color={"gray.500"} gap={2}>
        <Text>{dateFormatter.toDate(note.createdAt)}</Text>
        <Text>
          {note.createdBy.firstName} {note.createdBy.lastName}
        </Text>
      </Flex>
    </Flex>
  );
};

export default CallNoteCard;
