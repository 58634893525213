import React from "react";

import { ArrowDownIcon, ArrowUpIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Header,
  Row,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { Messages } from "../../../core/api";
import EntityCard, { PatientEntity } from "../../../shared/components/EntityCard";
import { fmap } from "../../../shared/utils";
import { dateFormatter } from "../../../shared/utils/date-formatter";
import { sortingFns } from "../../../shared/utils/tanstack-table";
import { PatientId } from "../../../shared/schema/schema";

type IntakeDataRow = Messages["IntakePatientDashboardDetails"] & {
  entity: PatientEntity;
};

function toDataRow(row: Messages["IntakePatientDashboardDetails"]): IntakeDataRow {
  return {
    ...row,
    entity: {
      displayId: row.displayId,
      fullName: row.firstName + " " + row.lastName,
      gender: row.gender,
      id: row.id,
      status: "DRAFT",
      type: "Patient",
    },
  };
}

const paginationPageSizes = [10, 20, 30, 40, 50];

const IntakeDashboardTable = (props: {
  patients: Messages["IntakePatientDashboardDetails"][];
  onClickDashboardRow: (patientId: PatientId) => void;
}) => {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const data = React.useMemo(() => props.patients.map(toDataRow), [props.patients]);

  const columnHelper = createColumnHelper<IntakeDataRow>();
  const columns = [
    columnHelper.accessor("entity", {
      cell: (info) => <EntityCard entity={info.getValue()} />,
      header: () => <span>Entity</span>,
    }),
    columnHelper.accessor("intakeStatus", {
      cell: (info) => info.getValue() ?? "Unknown",
      header: () => <span>Intake Status</span>,
    }),
    columnHelper.accessor("plan", {
      cell: (info) => info.getValue()?.name ?? "Unknown",
      header: () => <span>Plan</span>,
    }),
    columnHelper.accessor("medicaidId", {
      cell: (info) => info.getValue() ?? "Unknown",
      header: () => <span>Medicaid ID</span>,
    }),
    columnHelper.accessor("lastCall", {
      cell: (info) => (
        <Text>
          {fmap(info.getValue(), (v) => dateFormatter.toDateTime(v)) ?? "No calls were made"}
        </Text>
      ),
      sortingFn: sortingFns.localDateTime,
      header: () => <span>Last Call Date</span>,
    }),
    columnHelper.accessor("eligibilityFlow", {
      cell: (info) => <span>{info.getValue() ?? "Unknown"}</span>,
      header: () => <span>Eligibility Flow</span>,
    }),
    columnHelper.accessor("leadSource", {
      cell: (info) => info.getValue() ?? "Unknown",
      header: () => <span>Lead Source</span>,
    }),
    columnHelper.accessor("medicaidStatus", {
      cell: (info) => info.getValue()?.name ?? "Unknown",
      header: () => <span>Medicaid Status</span>,
    }),
    columnHelper.accessor("medicareStatus", {
      cell: (info) => info.getValue() ?? "Unknown",
      header: () => <span>Medicare Status</span>,
    }),
    columnHelper.accessor("NYIA1Status", {
      cell: (info) => (
        <Text>
          {fmap(info.getValue()?.date, (v) => dateFormatter.toDateTime(v))} -{" "}
          {info.getValue()?.completed === true ? "Completed" : "Not Completed"}
        </Text>
      ),
      header: () => <span>NYIA #1</span>,
    }),
    columnHelper.accessor("NYIA2Status", {
      cell: (info) => (
        <Text>
          {fmap(info.getValue()?.date, (v) => dateFormatter.toDateTime(v))} -{" "}
          {info.getValue()?.completed === true ? "Completed" : "Not Completed"}
        </Text>
      ),
      header: () => <span>NYIA #2</span>,
    }),
    columnHelper.accessor("UASStatus", {
      cell: (info) => (
        <Text>
          {fmap(info.getValue()?.date, (v) => dateFormatter.toDateTime(v))} -{" "}
          {info.getValue()?.completed === true ? "Completed" : "Not Completed"}
        </Text>
      ),
      header: () => <span>UAS</span>,
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const createHeaderFromColumn = (header: Header<IntakeDataRow, any>) => {
    return (
      <Th
        key={header.id}
        colSpan={header.colSpan}
        onClick={header.column.getToggleSortingHandler()}
        cursor="pointer"
        transition="100ms ease-in-out"
        _hover={{ bg: "gray.100" }}
        borderTopRadius="md"
      >
        {header.isPlaceholder ? null : (
          <div className={header.column.getCanSort() ? "cursor-pointer select-none" : ""}>
            <Flex justifyContent="space-between" alignItems="center">
              {flexRender(header.column.columnDef.header, header.getContext())}
              {{
                asc: <ArrowUpIcon />,
                desc: <ArrowDownIcon />,
              }[header.column.getIsSorted() as string] ?? null}
            </Flex>
          </div>
        )}
      </Th>
    );
  };

  const createTableRow = (row: Row<IntakeDataRow>) => {
    return (
      <Tr
        key={row.id}
        cursor="pointer"
        borderBottomColor={"blue.100"}
        fontWeight={600}
        _hover={{ bg: "gray.50" }}
        onClick={() => props.onClickDashboardRow(row.original.id)}
      >
        {row.getVisibleCells().map((cell) => {
          return <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>;
        })}
      </Tr>
    );
  };

  return (
    <Stack spacing={1}>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>{headerGroup.headers.map(createHeaderFromColumn)}</Tr>
            ))}
          </Thead>
          <Tbody>{table.getRowModel().rows.map(createTableRow)}</Tbody>
        </Table>
      </TableContainer>
      <Flex direction="row" dir="ltr" alignItems="center" gap={8}>
        <Stack direction="row" dir="ltr" alignItems="center" spacing={1}>
          <Button onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}>
            {"«"}
          </Button>
          <Button onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
            {"‹"}
          </Button>
          <Button onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
            {"›"}
          </Button>
          <Button
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {"»"}
          </Button>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Text fontSize="md">Page</Text>
          <Stack direction="row" alignItems="center" w="150px">
            <Text as="b" fontSize="md">
              {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
            </Text>
            <Text>({table.getRowModel().rows.length} Rows)</Text>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Text fontSize="md" w="100px">
            Go to page:
          </Text>
          <Input
            w="60px"
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
          />
        </Stack>
        <Menu>
          <MenuButton as={Button}>Show {table.getState().pagination.pageSize}</MenuButton>
          <MenuList>
            {paginationPageSizes.map((pageSize) => (
              <MenuItem key={pageSize} value={pageSize} onClick={() => table.setPageSize(pageSize)}>
                Show {pageSize}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Flex>
    </Stack>
  );
};

export default IntakeDashboardTable;
