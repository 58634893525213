import { Flex, Heading } from "@chakra-ui/react";
import { LocalDate, LocalDateTime } from "@js-joda/core";
import React from "react";
import { z } from "zod";
import { Messages } from "../../../core/api";
import { IntakePlanId } from "../../../shared/schema/schema";
import { validateOneOf } from "../../../shared/utils";
import IntakePatientProfileDatepicker from "./IntakePatientProfileDatepicker";
import IntakePatientProfileDropdown from "./IntakePatientProfileDropdown";
import IntakePatientProfileInput from "./IntakePatientProfileInput";

const enrollmentStatuses = [
  "Pending UAS",
  "Plan chosen - in network",
  "No plan",
  "Pending Enrollment agreement",
  "Pending first contact",
  "Plan chosen - not in network",
] as const;

type EnrollmentStatus = typeof enrollmentStatuses[number];

export interface IntakePlan {
  id: IntakePlanId;
  name: string;
  code: string;
  counties: string[];
  type: string;
}

interface Props {
  isEditMode: boolean;
  currPlanName: string | null;
  plans: IntakePlan[];
  planStartDate: LocalDate | null;
  planEnrollmentStatus: Messages["IntakePatientProfile"]["planEnrollmentStatus"];
  UASStatus: boolean;
  UASDate: LocalDateTime | null;
  UASHoursAmount: number | null;
  onChangePlan: (plan: IntakePlan | null) => void;
  onChangeField: (
    field:
      | "planEnrollmentStatus"
      | "planStartDate"
      | "UASAssessmentDate"
      | "UASCompleted"
      | "UASHoursAmount",
    value: string | EnrollmentStatus | number | null | boolean | LocalDate | LocalDateTime
  ) => void;
}

const IntakeProfilePlanInfo = (props: Props) => {
  const handleHoursAmountChange = (value: string) => {
    const valueAsNumber = parseInt(value, 10);
    if (value === "" || isNaN(valueAsNumber)) {
      props.onChangeField("UASHoursAmount", null);
    } else {
      props.onChangeField("UASHoursAmount", valueAsNumber);
    }
  };

  return (
    <Flex direction="column" gap={4}>
      <Heading as="h1" size="md">
        {" "}
        Plan Information
      </Heading>
      <Flex direction="row" gap={4}>
        <IntakePatientProfileDropdown
          isEditable={props.isEditMode}
          currValue={{
            value: props.currPlanName,
            label: props.currPlanName ?? "Unknown",
          }}
          isRequired={false}
          label="Plan"
          options={props.plans.map((plan) => ({
            value: plan?.id.toString(),
            label: plan?.name ?? "Unknown",
          }))}
          onChange={(value) =>
            props.onChangePlan(
              value === null
                ? null
                : props.plans.find(
                    (plan) => parseInt(value, 10) === IntakePlanId.unwrap(plan.id)
                  ) ?? null
            )
          }
        />
        <IntakePatientProfileDropdown
          isEditable={props.isEditMode}
          currValue={{
            value: props.planEnrollmentStatus,
            label: props.planEnrollmentStatus ?? "Unknown",
          }}
          isRequired={false}
          label="Enrollment Status"
          options={[
            ...enrollmentStatuses.map((status) => ({
              value: status,
              label: status,
            })),
            {
              value: "Unknown",
              label: "Unknown",
            },
          ]}
          onChange={(value) =>
            props.onChangeField("planEnrollmentStatus", validateOneOf(value, enrollmentStatuses))
          }
        />
      </Flex>
      <Flex direction="row" gap={4}>
        <IntakePatientProfileDatepicker
          isEditable={props.isEditMode}
          isRequired={false}
          label={"Start Date"}
          value={props.planStartDate}
          onChange={(value) => props.onChangeField("planStartDate", value)}
        />
        <IntakePatientProfileDatepicker
          isEditable={props.isEditMode}
          isRequired={false}
          withTime={true}
          label={"UAS date"}
          value={props.UASDate}
          onChange={(value) => props.onChangeField("UASAssessmentDate", value)}
        />
      </Flex>
      <Flex direction="row" gap={4}>
        <IntakePatientProfileDropdown
          isEditable={props.isEditMode}
          currValue={{
            value: props.UASStatus ? "Completed" : "Not completed",
            label: props.UASStatus ? "Completed" : "Not completed",
          }}
          isRequired={false}
          label="UAS Status"
          options={[
            {
              value: "Completed",
              label: "Completed",
            },
            {
              value: "Not completed",
              label: "Not completed",
            },
          ]}
          onChange={(value) =>
            props.onChangeField("UASCompleted", value === "Completed" ? true : false)
          }
        />
        <IntakePatientProfileInput
          isEditable={props.isEditMode}
          isRequired={false}
          label="UAS hours amount"
          value={props.UASHoursAmount !== null ? props.UASHoursAmount.toString() : ""}
          validateSchema={z.string().regex(new RegExp(/^\d+$/), { message: "Must be a number" })}
          onChange={(value) => handleHoursAmountChange(value)}
        />
      </Flex>
    </Flex>
  );
};

export default IntakeProfilePlanInfo;
