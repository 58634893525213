import { UseMutationOptions } from "@tanstack/react-query";
import { CaregiverId } from "../../shared/schema/schema";
import { BodyOf, createApi } from "./api-utils";

function createMutation<TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
  options: UseMutationOptions<TData, TError, TVariables, TContext>
) {
  return options;
}

export function createMutations(params: { api: ReturnType<typeof createApi> }) {
  const { api } = params;
  return {
    example: createMutation({
      mutationKey: ["example"],
      mutationFn: (params: {
        caregiverId: CaregiverId;
        body: BodyOf<"post", "./caregivers/:caregiverId/absence">;
      }) =>
        api.post("./caregivers/:caregiverId/absence", {
          body: params.body,
          path: { caregiverId: params.caregiverId },
        }),
    }),
  };
}
