import { agencyMemberKeys } from "../modules/agencyMembers/agencyMembers.query-keys";
import { caregiverKeys } from "../modules/caregiver/caregiver.query-keys";
import { commCenterKeys } from "../modules/communication/communication.query-keys";
import { FaxKeys } from "../modules/fax/fax.query-keys";
import { patientKeys } from "../modules/patient/patient.query-keys";
import { patientIntakeKeys } from "../modules/patientIntake/patient-intake.query-keys";
import { createQueryKeys } from "./utils/create-query-keys";

const sharedQueryKeys = createQueryKeys("shared", {
  image: (src: string) => src,
});

export const queryKeys = {
  commCenter: commCenterKeys,
  patient: patientKeys,
  caregiver: caregiverKeys,
  shared: sharedQueryKeys,
  fax: FaxKeys,
  patientIntake: patientIntakeKeys,
  agencyMemberId: agencyMemberKeys,
};
