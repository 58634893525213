import { useQuery } from "@tanstack/react-query";
import { Messages } from "../../../core/api";
import useApi from "../../../shared/hooks/useApi";
import { CommCenterTicketId } from "../../../shared/schema/schema";

interface Params {
  callSessionDetails: Messages["CallSessionDetails"] | null;
  ticketId: CommCenterTicketId;
}

type RecordingState =
  | {
      type: "NoRecording";
    }
  | {
      type: "LoadingRecording";
    }
  | {
      type: "RecodringReady";
      recordingUrl: string;
    }
  | {
      type: "HasRecording";
      onRequestRecording: () => void;
    }
  | {
      type: "ErrorLoading";
    };

const useCommCenterCallRecording = ({ callSessionDetails, ticketId }: Params): RecordingState => {
  const { api } = useApi();

  const recordingQuery = useQuery({
    queryKey: ["call-recording", ticketId],
    enabled: false,
    queryFn: () =>
      api.get("./call_center/:ticketId/recording", {
        path: {
          ticketId: ticketId,
        },
      }),
  });

  if (callSessionDetails === null || !callSessionDetails.hasRecording) {
    return {
      type: "NoRecording",
    };
  }

  if (recordingQuery.fetchStatus === "idle" && !recordingQuery.isFetched) {
    return {
      type: "HasRecording",
      onRequestRecording: () => recordingQuery.refetch(),
    };
  }

  if (recordingQuery.fetchStatus === "fetching") {
    return {
      type: "LoadingRecording",
    };
  }

  if (
    recordingQuery.isError ||
    !recordingQuery.isSuccess ||
    recordingQuery.data.signedUrl === null
  ) {
    return {
      type: "ErrorLoading",
    };
  }

  return {
    type: "RecodringReady",
    recordingUrl: recordingQuery.data.signedUrl,
  };
};

export default useCommCenterCallRecording;
