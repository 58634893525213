import React from "react";
import { FaPause, FaPlay } from "react-icons/fa";
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Flex,
  Spinner,
  Text,
} from "@chakra-ui/react";
import useAudio from "./useAudio";
import Backward15SecondsIcon from "../../icons/Backward15SecondsIcon";
import Forward15SecondsIcon from "../../icons/Forward15SecondsIcon";

interface Props {
  src: string;
  ratesOptions?: number[];
}

const defaultRates = [0.25, 0.5, 1, 1.5, 2];

const AudioPlayer = ({ src, ratesOptions = defaultRates }: Props) => {
  const {
    isPlaying,
    isLoaded,
    currentTimeSeconds,
    durationSeconds,
    playbackRate,
    pause,
    play,
    setPlaybackRate,
    setPosition,
  } = useAudio(src);

  const handleClickForward15Secs = () => {
    setPosition(Math.min(currentTimeSeconds + 15, durationSeconds));
  };

  const handleClickBackward15Secs = () => {
    setPosition(Math.max(currentTimeSeconds - 15, 0));
  };

  const updateRate = () => {
    const newRate =
      ratesOptions[(ratesOptions.findIndex((x) => x === playbackRate) + 1) % ratesOptions.length];
    setPlaybackRate(newRate);
  };

  const handleSliderChange = (n: number) => {
    pause();
    setPosition(n);
  };

  const handleSliderChangeEnd = (n: number) => {
    setPosition(n);
    play();
  };

  return !isLoaded ? (
    <Spinner />
  ) : (
    <Flex gap={4} align="center">
      <Backward15SecondsIcon cursor={"pointer"} boxSize={5} onClick={handleClickBackward15Secs} />
      {isPlaying ? (
        <FaPause cursor={"pointer"} onClick={pause} />
      ) : (
        <FaPlay cursor={"pointer"} onClick={play} />
      )}
      <Slider
        step={0.1}
        focusThumbOnChange={false}
        width={"150px"}
        value={currentTimeSeconds}
        min={0}
        max={durationSeconds}
        onChange={handleSliderChange}
        onChangeEnd={handleSliderChangeEnd}
      >
        <SliderTrack>
          <SliderFilledTrack bg={"blue.500"} cursor={"pointer"} />
        </SliderTrack>
        <SliderThumb bg={"black"} cursor={"pointer"} />
      </Slider>
      <Text cursor={"pointer"} onClick={updateRate}>
        <b>x{playbackRate}</b>
      </Text>
      <Forward15SecondsIcon cursor={"pointer"} boxSize={5} onClick={handleClickForward15Secs} />
    </Flex>
  );
};

export default AudioPlayer;
