import React from "react";
import { createIcon } from "@chakra-ui/react";

const SendIcon = createIcon({
  displayName: "SendIcon",
  viewBox: "0 0 24 24",
  path: <path fill="currentColor" d="m2 21l21-9L2 3v7l15 2l-15 2v7Z" />,
});

export default SendIcon;
