import { Flex, Tag } from "@chakra-ui/react";
import React from "react";
import { Messages } from "../../../core/api";
import { fmap } from "../../../shared/utils";
import { getMessagePreview } from "../utils/communication-utils";

interface Props {
  unreadCount: number;
  lastMessage: Messages["CommCenterMessage"] | null;
}

const ChatPreviewCell = (props: Props) => {
  return (
    <Flex gap={1}>
      <Tag rounded="full" bg="blue.500" color="white" mr={2} hidden={props.unreadCount === 0}>
        {props.unreadCount}
      </Tag>
      <span>{fmap(props.lastMessage, getMessagePreview)}</span>
    </Flex>
  );
};

export default ChatPreviewCell;
