import { PhoneIcon } from "@chakra-ui/icons";
import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { dateFormatter } from "../../../../../../../shared/utils/date-formatter";
import CallTicketIndexBadge from "../../../../CallTicketIndexBadge";
import { getParticipantDescription } from "../call-ticket-event-utils";
import { CalRingingAtEvent } from "../call-ticket-events-typings";

interface Props {
  event: CalRingingAtEvent;
}

const CallTicketEventCardRingingAt = ({ event }: Props) => {
  return (
    <Flex gap={2} alignItems={"center"}>
      <CallTicketIndexBadge>
        <PhoneIcon />
      </CallTicketIndexBadge>
      <Flex direction={"column"}>
        <Text color={"gray.400"}>{dateFormatter.toDateTime(event.createdAt)}</Text>
        <Flex gap={1}>
          <Text fontWeight={"bold"}>Call is ringing at</Text>
          <Text>{getParticipantDescription(event.callSessionParticipant)}.</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CallTicketEventCardRingingAt;
