import { answerIncomingCall } from "../../modules/call-center/call-center.utils";

const Cloudonix = (window).Cloudonix;
/* @ngInject */
export function mainCtrl($scope, $rootScope, $state, Storage, DatabaseApi, toaster, $window, $location, $timeout, ClientSurvey, callCenterService, $uibModal, notificationsService) {
  $scope.appStarted = true;
  $scope.showCorona = false;

  if (localStorage.getItem("hide-corona-banner") !== null) {
    $scope.showCorona = false;
  }

  // $timeout(function() {
  //   $scope.appStarted = true;
  // }, 10000)

  var props = [
    { name: 'activeAgencyChat', type: 'b' },
    { name: 'activeChat', type: 'b' },
    { name: 'activeChatId', type: 'b' },
    { name: 'activeChatList', type: 'a' },
    { name: 'activeChatPatient', type: 'b' },
    { name: 'activeIdx', type: 'b' },
    { name: 'activeModalId', type: 'b' },
    { name: 'agencyId', type: 'b' },
    { name: 'agencyId', type: 'b' },
    { name: 'agencyMemberId', type: 'b' },
    { name: 'caregiverChatId', type: 'b' },
    { name: 'visitDetails', type: 'o' },
    { name: 'notifications', type: 'a' },
    { name: 'totalNewChats', type: 'b' },
    { name: 'pendingCaregivers', type: 'a' },
    { name: 'pendingVisitEditRequests', type: 'a' },
    { name: 'commPageChatCounterCache', type: 'o' },
    { name: 'coordinatorRefferalCode', type: 'b' },
    //{name: 'token', type: 'b'},
    { name: 'userId', type: 'b' },
    { name: 'sweepCounter', type: 'b' },
    { name: 'sweepScore', type: 'b' },
    { name: 'internalChatCounter', type: 'b' },
    { name: 'stats', type: 'o' }
  ];

  $scope.getSweep = function (score) {
    if (!score || score < 0) return 0;
    return score;
  };

  $rootScope.logout = function () {
    localStorage.removeItem('apiOverride');

    //Storage.setObject('user', {});
    $rootScope.user = {};
    $rootScope.refreshToken = undefined;

    props.forEach(function (p) {
      if (p.type === 'a') $rootScope[p.name] = [];
      if (p.type === 'b') $rootScope[p.name] = false;
      if (p.type === 'o') $rootScope[p.name] = {};
    });


    Storage.softClear();
    $window.location.reload();
  };

  $rootScope.toggleNotific = false;

  $scope.openLink = function () {
    $window.open('https://youtu.be/yCloSat8ePY');
  }
  $scope.goToProfile = function () {
    $state.go('app.profile');
  }

  $scope.goToInvite = function () {
    $state.go('app.invite');
  }

  $scope.copyToClipboard = function (text) {
    navigator.clipboard.writeText(text).then(function () {
      toaster.pop("success", "Copied to clipboard");
    }, function (err) {
      toaster.pop("error", "Error copying to clipboard");
    });
  }

  $rootScope.openNotifications = function () {
    $rootScope.toggleNotific = true;
  };

  $rootScope.activeCall = null;

  $scope.goToNotification = function (line, i) {

    if (line.type === 'changes') {
      $rootScope.openVisitBroadcastModalById(line.data.id);

    } else if (line.type === 'waiting' || line.type === 'rejected') {
      $rootScope.popupWaiting = line.data;
      $state.go('app.visits.dashboard', {}, { reload: true });

    } else if (line.type === 'message') {
      //$rootScope.popupChat = line.data;
      //$state.go('app.visits', {}, {reload: true});
      //console.log(line);
      $rootScope.notifications.splice(i, 1);
      $rootScope.openChat(line);

    } else if (line.type === 'agencyMessage') {
      //console.log(line);
      $rootScope.notifications.splice(i, 1);
      $rootScope.caregiverChatId = line.caregiverId;
      $state.go('app.communication', {}, { reload: true });

    } else if (line.type === 'newCaregiver') {
      $rootScope.openCaregiverModal(line.data.id);
    } else if (line.type === 'completed') {
      $state.go('app.confirmations');
    } else if (line.type === "patientMessage") {
      $rootScope.notifications.splice(i, 1);
      $rootScope.openChat(line);
    } else if (line.type === "noteMention") {
      $rootScope.notifications.splice(i, 1);
      $rootScope.openNoteMention(line.noteId, line.mentionId);
    }

  };


  $scope.getComChatCount = function () {
    return Object.keys($rootScope.commPageChatCounterCache).length;
  };

  $rootScope.isOnActiveCall = () => {
    return $rootScope.activeCall && ["Ringing", "Active"].includes($rootScope.activeCall.status)
  }
  
  $scope.incomingCallHandler = (data) => {
    if ($rootScope.isOnActiveCall()) {
      return;
    }
    $rootScope.activeCall = data;
    $rootScope.activeCall.status = "Ringing";  

    const modalInstance = $uibModal.open({
      size: 'lg',
      component: 'callCenterIncomingCallModal',
      windowClass: "center-center call-center-incoming-call-modal",
      resolve: {
        callDetails: () => data
      }
    });

    notificationsService.notify("Incoming Call");

    $rootScope.$on("CallCenterCallStart", async (event, eventData) => {
      const socketPayload = JSON.parse(eventData);
      console.log("socketPayload", socketPayload)
      console.log("$rootScope.activeCall", $rootScope.activeCall);
      if (
        $rootScope.activeCall &&
        $rootScope.activeCall.status === "Ringing" &&
        $rootScope.activeCall.callSessionId === socketPayload.callSessionId
      ) {
        $rootScope.activeCall = null;
        $timeout(() => modalInstance.close("Reject"));
      }
    });

    $rootScope.$on("CallCenterCallerHangupBeforeCallStart", async (event, eventData) => {
      const socketPayload = JSON.parse(eventData);
      console.log("socketPayload", socketPayload)
      console.log("$rootScope.activeCall", $rootScope.activeCall);
      if (
        $rootScope.activeCall &&
        $rootScope.activeCall.status === "Ringing" &&
        $rootScope.activeCall.callSessionId === socketPayload.callSessionId
      ) {
        $rootScope.activeCall = null;
        $timeout(() => modalInstance.close("Reject"));
      }
    });

    modalInstance.result.then(async (res) => {
      if (res === "Answer") {
        const agencyMemberCanAnswerCallResult = await callCenterService.agencyMemberCanAnswerCall($rootScope.activeCall.callSessionId);

        if (agencyMemberCanAnswerCallResult.data.canAnswerCall) {
          $rootScope.activeCall = {...$rootScope.activeCall, status: "Active", liveCallModalOpen: true};
          answerIncomingCall(
            $rootScope.activeCall.sessionToken,
            {
              onSessionConnected: (res) => {
                console.log("onConnected", res);
                callCenterService.connectCustomer($rootScope.activeCall.sessionToken,
                   $rootScope.activeCall.participantId);
              },
              onSessionTerminated: (res) => {
                console.log("terminated", res)            
                if ($rootScope.activeCall !== null) {
                  $rootScope.activeCall.status = "Completed";
                }
              }
            }
          ); 
        } else {
          $rootScope.activeCall = null;
          toaster.pop("success", "Someone else already answered the call");
        }      
      }
      else if (res === "Reject") {
        $rootScope.activeCall = null;
      }
    });
  }  

  $rootScope.closeLiveCallModal = () => {
    if ($rootScope.isOnActiveCall()) {
      $timeout(() => $rootScope.activeCall.liveCallModalOpen = false, 0);
    } else {
      $timeout(() => $rootScope.activeCall = null, 0);
    }
  }

  $rootScope.handleClosePhoneDialer = () => {
    $timeout(() => {
      $rootScope.phoneNumberDialer = null
    });
  }

  $rootScope.openPhoneDialer = (number) => {
    $rootScope.phoneNumberDialer = {
      isOpen: true,
      predefinedPhoneNumber: number
    };
  }

  $scope.initCommCenterOpenedTicketsCount = () => {
    const url = "agencies/:agencyId/agency_members/:agencyMemberId/comm_center/tickets/open"
      .replace(":agencyId", $rootScope.agencyId)
      .replace(":agencyMemberId", $rootScope.agencyMemberId);

    DatabaseApi.get(url).then((ticketsData) => {
      $scope.commCenterOpenedTicketsCount = ticketsData.data.ticketsWithNewMessageCount;
    });
  };

  $rootScope.$on('comm_updated', () => {
    $scope.initCommCenterOpenedTicketsCount();
  })
  $rootScope.$on("CommCenterNotifyAgencyMember", (event, eventData) => {
    const socketPayload = JSON.parse(eventData);
    notificationsService.notify(socketPayload.messageTitle, {
      actionOnClick: () => $state.go("app.commcenter_ticket", { ticketId: socketPayload.ticketId }),
      notificationBody: socketPayload.messageContent,
    });
  });
  const CLOUDONIX_DOMAIN = 'cs.medflyt.com';
  Cloudonix.load();
  $rootScope.$watch("agencyMemberId", () => {
    if ($rootScope.agencyMemberId) {
      callCenterService.getAgencyMemberCallCenterSubscriber().then(async (res) => {
        $rootScope.callCenterSubscriberMsisd = res.data.subscriberMsisd;
        
        // to do if id
        Cloudonix.init(CLOUDONIX_DOMAIN, $rootScope.callCenterSubscriberMsisd, "medflyt");
        Cloudonix.setCredentials(CLOUDONIX_DOMAIN, $rootScope.callCenterSubscriberMsisd, "medflyt");
        $rootScope.$on('CallCenterInboundCallRinging', async (event, eventData) => {
          const socketPayload = JSON.parse(eventData);
          $scope.incomingCallHandler(socketPayload);
        });
      });
    }
  });


  var agencyMemberMap;
  /*
  function setAgencyMemberMap(){
    var members = DatabaseApi.getAgencyMembers();
    if(members){
      console.log(members);
    }
  }*/
  $scope.getMemberNameById = function (id) {
    if (!agencyMemberMap) agencyMemberMap = DatabaseApi.getAgencyMembers();
    if (agencyMemberMap) {
      return agencyMemberMap[id] ? agencyMemberMap[id].split(' ')[0] : '';
    } else {
      return '';
    }
  };

  var callsDashboardFeature = 0;
  $rootScope.showCallsDashboardFeature = false;

  $rootScope.$on("got_agency_features", function (event) {
    const agencyFeatures = DatabaseApi.agencyFeatures() || {};
    $rootScope.showEligibilityCheckFeature = agencyFeatures.eligibilityCheck;
    $rootScope.initBillingFeature(agencyFeatures.billing);
  });

  $rootScope.initBillingFeature = function (isBillingEnabled) {
    $rootScope.showBillingFeature = billingFeatureCounter > 3 || isBillingEnabled;

    if ($rootScope.showCallsDashboardAgencies.includes($rootScope.agencyId)) {
      $rootScope.showCallsDashboardFeature = true;
    }
  }

  $scope.showRecCounter = 0;
  $scope.incRecCounter = function () {
    $scope.showRecCounter++;
    $scope.incBillingFeature();
    $scope.incCallsDashboardFeature();
    $scope.incBillingSyncBtn();
  };

  $scope.showBillingSyncBtnCounter = 0;
  $scope.incBillingSyncBtn = function () {
    $rootScope.showBillingSyncBtn = $scope.showBillingSyncBtnCounter++ > 10;
  };

  var billingFeatureCounter = 0;
  $rootScope.initBillingFeature();
  $scope.incBillingFeature = function () {
    billingFeatureCounter++;
    $rootScope.initBillingFeature();

    if ($rootScope.showBillingFeature) {
      $rootScope.$broadcast('showBillingFeature_updated');
    }
  };

  if (document.location.host.search('demo') > -1) {
    billingFeatureCounter = 10;
    $scope.incBillingFeature();
    $scope.demoCssChangesHeader = {
      background: '#f13c3c'
    }
  }


  $scope.isApiOverriden = localStorage.getItem("apiOverride") !== null;

  $scope.incCallsDashboardFeature = function () {
    callsDashboardFeature++;
    $rootScope.showCallsDashboardFeature = callsDashboardFeature > 5;
  };

  $scope.hideCorona = ($event) => {
    $event.stopPropagation();
    $scope.showCorona = false;
  };

  ///////////////////////////////
  // internal routing from url //
  ///////////////////////////////

  // http://localhost:3001?entity=agency-chat&id=58483&caregiver=3808
  // http://localhost:3001?entity=visit-chat&id=436551&visit=974733
  // http://localhost:3001?entity=visit&id=974733

  //  entity = "visit-chat" || "agency-chat" || "visit"
  var entity = $location.search().entity || undefined;
  var entityId = $location.search().id || undefined;
  var caregiverId = $location.search().caregiver || undefined;
  var visitId = $location.search().visit || undefined;
  const patientId = $location.search().patient || undefined;

  if (entity && entityId) {
    entityId = parseInt(entityId);

    if (entity === 'patient-chat' && patientId) {
      const newChat = {
        id: entityId,
        patientId: patientId,
        fromList: false,
        type: 'CaregiverPatientChat',
        agencyChat: false
      };
      $rootScope.openChat(newChat);

    } else if (entity === 'agency-chat' && caregiverId) {
      $rootScope.openAgencyChat(caregiverId);

    } else if (entity === 'visit') {
      console.log('sdv');
      $timeout(function () {
        $rootScope.popupWaitingForSingle = true;
        $rootScope.openVisitBroadcastModalById(entityId);
      }, 1000);
    }

  }

  ClientSurvey.init();
};
