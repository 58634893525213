import { Box, Heading } from "@chakra-ui/react";
import React from "react";
import { Messages } from "../../../core/api";
import { CommCenterTicketId } from "../../../shared/schema/schema";
import TicketItem from "./TicketItem";

interface Props {
  label: React.ReactNode;
  tickets: Messages["CommCenterTicket"][];
  onClickTicket: (ticketId: CommCenterTicketId) => void;
  activeTicketId?: CommCenterTicketId;
}

const TicketsGroup = (props: Props) => {
  return (
    <Box borderTop="1px solid var(--chakra-colors-gray-100)">
      <Heading size="sm" p={4} borderRadius="xl" position="sticky" top={0} bg="white">
        {props.label}
      </Heading>

      {props.tickets.map((ticket) => (
        <TicketItem
          key={`${ticket.id}`}
          ticket={ticket}
          onClick={props.onClickTicket}
          isActive={props.activeTicketId === ticket.id}
        />
      ))}
    </Box>
  );
};

export default TicketsGroup;
