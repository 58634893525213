import { Button } from "@chakra-ui/react";
import React from "react";
import { Messages } from "../../../core/api";

export type ViewFax = {
  fax: Messages["DashboardFax"];
  onSelectView: () => void;
};

interface Props {
  viewFax: ViewFax;
}

const ViewFaxButton = (props: Props) => {
  return props.viewFax.fax.status === "success" ? (
    <Button
      size="sm"
      colorScheme="blue"
      variant="solid"
      w="full"
      onClick={props.viewFax.onSelectView}
    >
      View
    </Button>
  ) : null;
};

export default ViewFaxButton;
